<template>
  <div class="login">
    <div class="container">
      <div class="login__container" v-if="loadedData">
        <img src="@/assets/logo.svg" alt="" class="logo-ruky">
        <!-- <img src="@/assets/forge.svg" alt="" class="logo-forge"> -->
        <template v-if="status == 'pending'">
          <div><h1>{{ $t("login.title") }}</h1></div>    
          <p>{{ $t("users.create.description") }}</p>
          <div>
            
            <div :class="{'has-error': v$.userModel.name.$invalid && submitted}">
              <label>{{$t('users.create.input_firstName')}}</label>
              <input type="text" autocomplete="off" v-model="userModel.name">
            </div>
            <div :class="{'has-error': v$.userModel.lastName.$invalid && submitted}">
              <label>{{$t('users.create.input_lastName')}}</label>
              <input type="text" autocomplete="off" v-model="userModel.lastName">
            </div>
            <div :class="{'has-error': v$.userModel.pass.$invalid && submitted}">
              <label>{{$t('users.create.input_password')}}</label>
              <input type="password" autocomplete="off" v-model="userModel.pass">
              <span class="input_error">{{$t('users.create.input_password_format')}}</span>
              <span class="input_info">{{$t('users.create.input_password_format')}}</span>
            </div>
            <div :class="{'has-error': v$.userModel.passRepeat.$invalid && submitted}">
              <label>{{$t('users.create.input_confirmpassword')}}</label>
              <input type="password" autocomplete="off" v-model="userModel.passRepeat">
              <span class="input_error">{{$t('users.create.input_confirmpassword_error')}}</span>
            </div>
          </div>
          <div class="new-search__actions">
            <button @click="saveUser" class="button button--blue button--register">{{ $t("users.create.save") }}</button>
          </div>     
        </template>   
        <template v-if="status == 'cancelled'">
          <p>{{ $t("users.create.cancelled_description") }}</p>
        </template>   
        <template v-if="status == 'completed'">
          <div><h1>{{ $t("login.title") }}</h1></div>    
          <p>{{ $t("users.create.completed_description") }}</p>
          <div class="new-search__actions">
            <button @click="goLogin()" class="button button--blue button--register">{{ $t("users.create.continueToLogin") }}</button>
          </div>     
        </template> 
        <div class="menu-powered-by-forge">
          <a href="https://fondationforge.org/" target="_blank">Powered By Forge</a>
        </div>  
      </div>
      <div v-else class="loader">Cargando...</div>
      <div class="modal__overlay" v-if="modalLogin">
        <div class="modal">
          <span class="modal__title">¡Ya formas parte de Ruky!</span>
          <div class="modal__description">A continuación te pediremos que te loguees por primera vez para comenzar a disfrutar de la plataforma</div>
          <button class="button button--blue button--register" @click="goLogin()">ingresar</button>
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
  import { useStore } from 'vuex';
  import { computed, onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';

  import useVuelidate from '@vuelidate/core';
  import { required , sameAs} from '@vuelidate/validators';
  import { useI18n } from 'vue-i18n';

export function validPassword(password) {
  let validPassword = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{6,32})$/); //eslint-disable-line
  if (validPassword.test(password)) return true;
  return false;
}

export default {
  components: {
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const submitted = ref(false);
    const { t } = useI18n();
    const modalLogin = ref(false);
    const loadedData = ref(false);

    const organizationName = ref("")
    const email = ref("")
    const status = ref("");
    const userModel = ref({
      name : "",
      lastName: "",
      email: "",
      pass: "",
      passRepeat: "",
      invitationCode: ""
    }); 
    onMounted(async () => {
      await store.dispatch('manageUser_disabled');
      try {
        const req = await store.dispatch('security_invitedata', route.params.inviteCode);

        email.value = req.data.email
        status.value = req.data.status
        organizationName.value = req.data.organizationName
        
        userModel.value.email = email.value
        userModel.value.invitationCode = route.params.inviteCode
        loadedData.value = true;
      } catch (error) {
        if (error.response.data == 'INVITATION_CODE_NOT_FOUND') {
          status.value = "cancelled";
          loadedData.value = true;
        }
      }
      
    });

    async function saveUser () { 
      submitted.value = true;
      if (!v$.value.userModel.$invalid) {
        try {
          const request = await store.dispatch('security_registerByInvite' , userModel.value);
          if (request.status === 200) {
            //store.dispatch('notifications_create', { text: t(`notifications.users.created`) });
            modalLogin.value = true;
            //router.push(`/login`);
          }
        } catch (e) {
         store.dispatch('notifications_create', { text: t(`notifications.requestError.${e.response.data}`) });
        }
      }
    }
    
    function goLogin(){
      router.push(`/login`);
    }

    const rules = computed(() => {
      return {
          userModel: {
            name: { required },
            lastName: { required },
            pass: { required, validPassword },
            passRepeat: { required, sameAs: sameAs(userModel.value.pass) }
          }
        }
      });
    const v$ = useVuelidate(rules, { userModel} )
    return {
      userModel,
      status,
      saveUser,
      goLogin,
      v$,
      submitted,
      modalLogin,
      loadedData
    }
  }
}
</script>

<style lang="scss" scoped>

  .container {
    padding: 0 $margin-base;

    @media (min-width: $break-sm) {
      padding: 0;
    }

    @media (min-width: $break-md) {
      padding: 0 $margin-base;
    }

    @media (min-width: $break-lg) {
      padding: 0;
    }
  }

  .new-search {

    .material-icons {
      position: absolute;
      right: $margin-milli;
      top: $margin-milli;
      font-weight: bold;
      color: $lightgray;

      &.back {
        position: absolute;
        left: 16px;
        right: auto;
        top: 16px;
        font-weight: bold;
        color: $darker;
        font-size: 32px;
      }
    }

    p {
      text-shadow: none;
      font-family: $font-opensans;
      color: $gray;
      font-weight: 400;
      @include font-settings(base,base);
      margin-bottom: $margin-base;
    }

    h3 {
      color: $darker;
      text-shadow: none;
      @include font-settings(supertera, mega);
      padding: $margin-kilo;
    }

    &__actions {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      gap: 24px;

      @media (min-width: $break-md) {
        flex-direction: row;
        justify-content: space-between;

        &.single-button {
          justify-content: center;
        }
      }

      &__group {
        display: flex;
        gap: $margin-kilo;
        border-radius: 8px;
        padding: 16px;
        background: $lightestofgrays;

        a {
          @include font-settings(base, base);
          text-transform: uppercase;
          font-family: $font-opensans-condensed;
          font-weight: 700;
        }

        &__red {
          color: $lightgreen;
        }

        &__green {
          color: $darker;
        }
      }
    }

    &__table {
      display: grid;
      grid-template-columns: 1fr;
      gap: $margin-kilo;
      margin: $margin-mega 0;
      text-align: left;

      @media (min-width: $break-md) {
        grid-template-columns: 1fr 1fr;
      }

      .two-columns {
        grid-column-start: 1;
        grid-column-end: 1;

        @media (min-width: $break-md) {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }

      label {
        font-family: $font-opensans;
        color: $litedarkgray;
        padding-bottom: $margin-milli;
        @include font-settings(base, base);
        display: block;
      }

      .checkbox {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: $margin-mega;
        font-family: $font-opensans;
        color: $gray;
        // margin-bottom: $margin-kilo;
        @include font-settings(milli, xmega);
        width: 100%;
        gap: $margin-milli;

        input {
          width: 16px;
          height: 16px;
          margin: 0;
        }

        label {
          padding-bottom: 0!important;
          font-size: 14px !important;
        }
      }

      input {
        background: none;
        text-align: left;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 2px solid $gray;
        height: $margin-mega;
        font-family: $font-opensans;
        color: $gray;
        margin-bottom: $margin-kilo;
        @include font-settings(milli, xmega);
        width: 100%;

        &::placeholder {
          color: $graybg;
        }
      }

      textarea {
        background: none;
        text-align: left;
        border: 2px solid $gray;
        height: 96px;
        padding: $margin-milli;
        font-family: $font-opensans;
        color: $gray;
        margin-bottom: $margin-kilo;
        @include font-settings(milli, base);
        width: 100%;

        &::placeholder {
          color: $graybg;
        }
      }

      select {
        background: none;
        text-align: left;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 2px solid $gray;
        height: $margin-mega;
        font-family: $font-opensans;
        color: $gray;
        margin-bottom: $margin-kilo;
        @include font-settings(milli, xmega);
        width: 100%;

        option {
          color: $litedarkgray;
        }

        &::placeholder {
          color: $graybg;
        }
      }
    }
  }
.input_error {
  display:none;
  }

.input_info {
  display: block;
  color: #333;
  font-size: 12px;
  margin-top: -20px;
  padding-bottom: 8px;
}
  .has-error {
    label, input, textarea, select {
      color: tomato;
      border-color: tomato;
    }
    .input_info { 
      display:none;
    }
    .input_error {
      display: block;
      color: tomato;
      font-size: 12px;
      margin-top: -20px;
      padding-bottom: 8px;
    }
  }

.modal{
  display: flex;
  flex-flow: column nowrap;
  gap: 20px;
  &__title{
    font-family: $font-opensans;
    color: $gray;
    font-weight: 700;
    font-size: 22px;
    color: #303030;
  }
  &__description{
    font-family: $font-opensans;
    color: $gray;
    font-weight: 700;
    font-size: 16px;
  }
  button{
    width: fit-content;
    align-self: center;
  }
}

.loader{
  width: 100%;
  text-align: center;
  font-size: 26px;
}
</style>

