<template>
  <div class="social-register">
    <!-- <span>{{ $t("login.login-with") }}</span> -->
    <button :disabled="loading || !isGoogleInit" @click="authenticateGoogle" class="button button--white">
      <img src="@/assets/google.svg" alt="">
      {{loading ? 'Ingresando' : 'Ingresar con Google'}}
    </button>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
import gAuth from 'vue3-google-auth';
import { useStore } from 'vuex';
import persistentData from '@/plugins/persistentData';
import { ref } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const loading = ref(false);
    const { t } = useI18n();
    
    let isGoogleInit = ref(false);
    const $gAuth = gAuth.useGAuth()
    const checkGAuthLoadInterval = setInterval(() => {
      isGoogleInit.value= $gAuth.isInit;
      if (isGoogleInit.value) clearInterval(checkGAuthLoadInterval);
    }, 1000);

    async function authenticateGoogle() {
      if (!isGoogleInit.value) {
        store.dispatch('notifications_create', { text: t(`notifications.cant-login-with-google`) });
        return;
      }

      loading.value = true;
      try {
        const authCode = await $gAuth.signIn();
        const gAuthResponse = authCode.getAuthResponse();
        const request = await store.dispatch('security_authenticateGoogle', { idToken: gAuthResponse.id_token });
        if (request.status == 200) {
          persistentData.set('token', request.data.token);
            persistentData.set('tokenExpiration', request.data.tokenExpiration);
            persistentData.set('user', JSON.stringify(request.data));
            store.dispatch('manageUser_active');
            router.push('/organization-selection');
        }
        loading.value = false;
      } catch (e) {
        console.log(e);
        loading.value = false;
      }
    }

    return {
      authenticateGoogle,
      loading,
      isGoogleInit
    }
  }
}
</script>
<style lang="scss" scoped>
  .social-register {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: $margin-milli;
    margin-bottom: $margin-kilo;

    span {
      font-weight: 700;
    }
    
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $margin-base;
      gap: $margin-milli;
      border: 1px solid tomato;
      height: 48px;
      font-weight: 700;
      width: 100%;

      img {
        width: 28px;
        margin: 0;
      }
    }
  }
</style>