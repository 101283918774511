<template>
  <div class="login">
    <div class="container">
      <div class="login__container">
        <img src="@/assets/logo.svg" alt="" class="logo-ruky">
        <h1>{{ $t("login.recoveryPassword.title") }}</h1>
        <p class="login__description--small">{{ step === 'begin' ? $t("login.recoveryPassword.description") : $t("login.recoveryPassword.endDescription") }}</p>
        <div class="login__grid">
          <template v-if="step === 'begin'">
            <input type="text" @input="onInputEmail" :placeholder="$t('login.input_email')" v-model="email" :class="{'input-invalid': (v$.email.$invalid && submitted) || isNonRegisteredEmail}">
            <span class="input-message" v-if="v$.email.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.email.required.$message}}</span>
            <span class="input-message" v-if="v$.email.email.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.email.email.$message}}</span>

            <button @click="beginRecoveryPassword" class="button button--blue button--register" :disabled="awaitRequest">{{ awaitRequest  ? $t("login.recoveryPassword.actionDisabled") : $t("login.recoveryPassword.action") }}</button>  
          </template>
          <template v-else>
            <input type="text" :placeholder="$t('login.input_email')" v-model="email" :class="{'input-invalid': v$.email.$invalid && submitted}">
            <span class="input-message" v-if="v$.email.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.email.required.$message}}</span>
            <span class="input-message" v-if="v$.email.email.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.email.email.$message}}</span>

            <input type="text" :placeholder="$t('login.recoveryPassword.input_code')" v-model="activationCode" :class="{'input-invalid': v$.activationCode.$invalid && submitted}">
            <span class="input-message" v-if="v$.activationCode.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.activationCode.required.$message}}</span>

            <input type="password" :placeholder="$t('login.recoveryPassword.input_password')" v-model="password" :class="{'input-invalid': v$.password.$invalid && submitted}">
            <span class="input-message" v-if="v$.password.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.password.required.$message}}</span>
            <span class="input_info">{{$t('users.create.input_password_format')}}</span>
            
            <input type="password" :placeholder="$t('login.recoveryPassword.input_repeatPassword')" v-model="passwordConfirm" :class="{'input-invalid': v$.passwordConfirm.$invalid && submitted}">
            <span class="input-message" v-if="v$.passwordConfirm.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.passwordConfirm.required.$message}}</span>
            
            <button @click="endRecoveryPassword" class="button button--blue button--register" :disabled="awaitRequest">{{ awaitRequest  ? $t("login.recoveryPassword.actionDisabled") : $t("login.recoveryPassword.action") }}</button>  
          </template>
          <button @click="backToLogin" class="button" :disabled="awaitRequest">{{ $t("login.recoveryPassword.back") }}</button> 
        </div> 
      </div>
    </div>
  </div>
</template> 
<script>
import { ref } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, email, sameAs, helpers } from '@vuelidate/validators';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

function validPassword(password) {
  let validPassword = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9]{6,32})$/); //eslint-disable-line
  if (validPassword.test(password)) return true;
  return false;
}

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const v$ = useVuelidate();
    const { t } = useI18n();
    const email = ref("");
    const activationCode = ref("");
    const password = ref("");
    const passwordConfirm = ref("");
    const submitted = ref(false);
    let awaitRequest = ref(false);
    let isNonRegisteredEmail = ref(false);
    let step = ref('begin');

    async function beginRecoveryPassword() {
      submitted.value = true;
      if (!v$.value.email.$invalid) {
        awaitRequest.value = true;
        try {
          await store.dispatch('security_beginPasswordRecovery', email.value);
          step.value = 'end';
          submitted.value = false;
          awaitRequest.value = false;
        } catch (error) {
          submitted.value = false;
          awaitRequest.value = false;
          if (error.response.data == 'invalidUser') {
            store.dispatch('notifications_create', { text: t(`notifications.invalidUserForgeMessage`, {email: 'sistemas@fondationforge.org'}) });
            isNonRegisteredEmail.value = true;          
          } else {
            store.dispatch('notifications_create', { text: t(`notifications.generic`) });
          }
        }
      }
    }

    async function endRecoveryPassword() {
      submitted.value = true;
      if (!v$.value.email.$invalid && !v$.value.activationCode.$invalid && !v$.value.password.$invalid && !v$.value.passwordConfirm.$invalid) {
        awaitRequest.value = true;
        const model = {
          activationCode: activationCode.value,
          password: password.value,
          passwordConfirm: passwordConfirm.value,
          email: email.value
        };
        try {
          await store.dispatch('security_endPasswordRecovery', model);
          awaitRequest.value = false;
          submitted.value = false;
          step.value = 'begin';
          store.dispatch('notifications_create', { text: t(`notifications.recoveryPassword`) });
          router.push('/login');
        } catch (error) {
          awaitRequest.value = false;
          submitted.value = false;
          store.dispatch('notifications_create', { text: error.response.data });
        }
      }
    }

    function backToLogin() {
      router.push('/login');
    }

    function onInputEmail() {
      if (isNonRegisteredEmail.value) {
        isNonRegisteredEmail.value = false;
      }
    }

    return {
      email,
      activationCode,
      password,
      passwordConfirm,
      submitted,
      awaitRequest,
      backToLogin,
      beginRecoveryPassword,
      endRecoveryPassword,
      v$,
      step,
      isNonRegisteredEmail,
      onInputEmail
    }
  },
  validations () {
    return {
      email: { 
        required: helpers.withMessage('Este campo es requerido', required),
        email: helpers.withMessage('El formato del mail es invalido', email)
      },
      activationCode: { 
        required: helpers.withMessage('Este campo es requerido', required)
      },
      password: { 
        required: helpers.withMessage('Este campo es requerido', required), 
        validPassword 
      },
      passwordConfirm: { 
        required: helpers.withMessage('Este campo es requerido', required), 
        sameAsPassword: sameAs(this.password) 
      } 
    }
  }
}
</script>

<style lang="scss" scoped>


.input_error {
  display:none;
  }

.input_info {
  display: block;
  color: #333;
  font-size: 12px;
  margin-top: -20px;
  padding-bottom: 8px;
}
  .has-error {
    label, input, textarea, select {
      color: tomato;
      border-color: tomato;
    }
    .input_info { 
      display:none;
    }
    .input_error {
      display: block;
      color: tomato;
      font-size: 12px;
      margin-top: -20px;
      padding-bottom: 8px;
    }
  }
</style>