const PREFIX = 'ruki_organization_';

const persistentData = {
  get: (key, json = false) => {
    if (json) {
      return JSON.parse(localStorage.getItem(`${PREFIX}${key}`));
    } else {
      return localStorage.getItem(`${PREFIX}${key}`);
    }
  },
  set: (key, value) => {
    localStorage.setItem(`${PREFIX}${key}`, value);
  },
  remove: (key) => {
    localStorage.removeItem(`${PREFIX}${key}`);
  }
};

export default persistentData;