<template>
  <div class="lx_pulse lx_pulse_blue"></div>
  <div class="login">
    <div class="container">
      <div class="login__container">
        <img src="@/assets/logo.svg" alt="" class="logo-ruky">
        <!-- <img src="@/assets/forge.svg" alt="" class="logo-forge"> -->
        <p>{{ $t("login.organization_selection.description") }}</p>
        <div class="login__grid">

          <div class="modal__organization-selector__container">
          <template v-for="org in organizations" :key="org.organizationId">
            <div class="modal__organization-selector__container__item" @click="changeOrganization(org.organizationId)" :class="organizationId == org.organizationId ? 'selected' : ''">
              <img :src="getImage(org.organizationPicture, org.isPictureFromCDN)" />
              <span>{{org.organizationName}}</span>
            </div>
          </template>
          </div>

          <button @click="continueToHome" class="button button--blue button--register" :disabled="organizationId == null">{{ $t("login.organization_selection.action") }}</button>
          <!-- <span>{{ $t("login.login-with") }}</span> -->
          <div class="menu-powered-by-forge">
            <a href="https://fondationforge.org/" target="_blank">Powered By Forge</a>
          </div>  
        </div>
      </div>
      <div class="login__image login__image--sm">
        <img src="@/assets/choose.svg" alt="">
      </div>
    </div>
  </div>
</template> 
<script>
import { useStore } from 'vuex';
import { computed } from '@vue/runtime-core';
import persistentData from '@/plugins/persistentData';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const organizationIdStored = computed(() => store.getters.manageUser_organizationId);
    const { t } = useI18n();

    if(organizationIdStored.value != null && organizationIdStored.value != 0)
    {
        router.push('/job-searches/actives');
        return;
    }
    const organizationId = ref(organizationIdStored.value);
    const organizations = ref([]); 
    const user = computed(() => store.getters.manageUser_user);
    organizations.value = user.value.organizations;
   
   

    async function continueToHome () {
      if (organizationId.value != null) {
        try {
            persistentData.set('organizationId', organizationId.value);
            store.dispatch('manageUser_active');
            router.push('/job-searches/actives');
        } catch (e) {
          store.dispatch('notifications_create', { text: t(`notifications.${e.response.data}`) });
        }
      }
    }

    if(user.value.organizations.length == 1)
    {
      organizationId.value = user.value.organizations[0].organizationId
      continueToHome();
    }

     function getImage(path, isFromCDN) {
      if (isFromCDN) {
        return path ? `${process.env.VUE_APP_CDN_URL_USER}${path}` : null;
      } else {
        return path ? `${process.env.VUE_APP_FORGE_ORGANIZATION_MEDIA_URL}${path}` : null;
      }
    }

    function changeOrganization(organizationSelected) {  organizationId.value=organizationSelected  }

    return {
      organizationId,
      organizations,
      changeOrganization,
      getImage,
      continueToHome
    }
  }
}
</script>

<style lang="scss" scoped>
.modal
  {
    &__organization-selector__container
    {
      padding: 12px 0px 20px 0px;
      &__item {
          display: inline-flex;
          width: 100%;
          border-radius: 30px;
          padding: 4px;
          margin-bottom: 8px;
          cursor: pointer;

        &.selected {
          border: 2px solid #087B9A;

          span {
            color: #333;
            font-weight: bold;
          }
        }

        img {
          object-fit: cover;
          width: 30px;
          height: 30px;
          border-radius: 100%;
          margin: 0px;
        }
        span {
          color: #333;
          line-height: 30px;
          padding-left: 10px;
        }
      }
    }   
  }
</style>