import persistentData from "@/plugins/persistentData";
import moment from "moment";
import { useRouter, useRoute } from 'vue-router';

let intervalRefreshToken = null;

export default {
  state: () => ({
    logged: false,
    user : {},
    organizationId : null
  }),
  getters: {
    manageUser_logged: state => state.logged,
    manageUser_user: state => state.user,
    manageUser_organizationId : state => state.organizationId
  },
  
  mutations: { 
    manageUser_setLogo(state,model){
      const organizationModel = state.user.organizations.find(o => o.organizationId == model.organizationId);
      organizationModel.organizationPicture = model.img;
      organizationModel.isPictureFromCDN = true;
      persistentData.set("user", JSON.stringify(state.user));
    },
    manageUser_setLogged(state, value) {
      state.logged = value;
    },
    manageUser_setUser(state, value) {
      state.user = value;
    },
    manageUser_setOrganizationId(state, value) {
      state.organizationId = value;
    }
  },
  actions: {
    manageUser_changeLogo({ commit },model){
      commit('manageUser_setLogo', model)
    },
    manageUser_active({ commit, dispatch }) {
      if (persistentData.get('token')) {
        // Validate tokenExpiration
        const validateToken = async function() {
          const expiredToken = moment(persistentData.get('tokenExpiration')).diff(moment());
          if (expiredToken < 0) {
            try {
              const request = await dispatch('security_refreshToken', {value: persistentData.get('token')});
              if (request.status === 200) {
                persistentData.set('token', request.data.token);
                persistentData.set('tokenExpiration', request.data.tokenExpiration);
              } else {
                dispatch('manageUser_disabled');
              }
            } catch (error) {
              dispatch('manageUser_disabled');
            }
          }
        }

        validateToken();
        if (intervalRefreshToken) clearInterval(intervalRefreshToken)
        
        intervalRefreshToken = setInterval(() => {
          validateToken();
        }, 10000);
        
        commit('manageUser_setLogged', true);
        commit('manageUser_setUser', JSON.parse(persistentData.get('user')));
        commit('manageUser_setOrganizationId', JSON.parse(persistentData.get('organizationId')));
      } 
      else {
        const router = useRouter();
        const route = useRoute();
        setTimeout(() => {
          if(route.name != 'USER_REGISTRATION')
            router.push('/login')
        },0)
      }
    },
    manageUser_disabled({ commit }) {
      persistentData.remove('token');
      persistentData.remove('tokenExpiration');
      persistentData.remove('organizationId');
      if (intervalRefreshToken) clearInterval(intervalRefreshToken);
      commit('manageUser_setLogged', false);
    },
    manageUser_defineOrganization(_,organizationId) {
      persistentData.set('organizationId', organizationId);
    }
  }
}