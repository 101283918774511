<template>
 <div class="modal__overlay" @click.stop="toggleModal()">
    <div class="modal" @click.stop="''">
      <span class="material-icons" @click.stop="toggleModal()">close</span>
      <h3>{{$t('user.invite.modaltitle')}}</h3>
      <div class="modal__date">
        <p>{{$t('user.invite.input_email')}}</p>
        <input type="email" autocomplete="off" v-model="email" :class="{'input-invalid': v$.email.$invalid && submitted}">
      </div>

      
      <div class="modal__buttons">
        <button class="button--gray" @click.stop="toggleModal()">cancelar</button>
        <button @click="publish">publicar</button>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';

export default {
  setup(_, {emit}) {
    const email = ref("");
    const submitted = ref(false);

    function toggleModal() {
      emit('close');
    }

    async function sendInvite() {
      submitted.value = true;
      //todo try send invite
      emit('sended')
    }

    return {
      email,
      submitted,
      sendInvite,
      toggleModal,
      v$: useVuelidate()
    }
  },
  validations () {
    return {
      email: { required, email }
    }
  }
}
</script>
<style lang="scss" scoped>
  
  .modal__date {
    margin-bottom: $margin-mega;

    input {
      background: none;
      text-align: left;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 2px solid $litedarkgray;
      height: 32px;
      width: 100%;
      font-family: $font-opensans;
      color: $gray;
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 34px;
    }

    p {
      color: $litedarkgray;
      text-align: left;
      margin-bottom: $margin-milli;
    }
  }
</style>