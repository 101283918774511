import { createApp } from 'vue'
import App from './App.vue'
import Store from './store'
import Router from './router'
import i18n from './i18n'
import gAuth from 'vue3-google-auth'; 
const $gAuth = gAuth.createGAuth({
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email openid',
  prompt: 'select_account',
});

createApp(App)
  .use(i18n)
  .use(Store)
  .use(Router)
  .use($gAuth)
  .mount('#app')
