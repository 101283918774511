<template>
  <div>
    <div class="container__table">
      <h3 v-if="loadedData"><a @click="backRoute"><i class="material-icons back">arrow_back</i></a></h3>
      <h3 v-else class="loading">Cargando...</h3>
      <div v-if="loadedData" class="new-search">
        <div class="new-search__card">
          <div class="header-bg">
          </div>
          <a :href="'/job-searches/edit/'+jobSearchId" class="button-float"><i class="material-icons back">edit</i></a>
          <div class="header-main" id="header-main">
            <div class="header-main__image">
              <div class="personal-data__photo" :style="`background-image:url(${getImage(organization.organizationPicture, organization.isPictureFromCDN, true) || require('@/assets/placeholder.png')})`">
              </div>
            </div>
            <div class="header-main__data">
              <h3 class="header-main__data__fullname">{{jobSearchData.title}}</h3>
              <span class="header-main__data__age">{{organization.organizationName}}</span>  
            </div>
          </div>
          <div class="new-search__card--padding-bottom">
            <div v-if="loadedData" class="new-search__card__details">
              <div>{{ $t("jobsearch.detail.createDate") }} <b>{{ moment(jobSearchData.createDate).format("DD/MM/YYYY") }}</b></div>
              <div>{{ $t("jobsearch.detail.endDate") }} <b>{{ moment(jobSearchData.endDate).format("DD/MM/YYYY") }}</b></div>
              <div>{{ $t("jobsearch.detail.applyLimit") }} <b>{{ jobSearchData.applyLimit }}</b></div>
              <div>{{ $t("jobsearch.detail.hiredPeople") }} <b>{{ jobSearchData.hired }}</b></div>
              <div>{{ $t("jobsearch.detail.numberOfApplies") }} <b>{{numberOfApplies}}</b> </div>
            </div>
            <div class="detail-skeleton-container" v-else>
              <Skeletor width="500" height="16"/>
              <Skeletor width="300" height="16"/>
              <Skeletor width="500" height="16"/>
              <Skeletor width="300" height="16"/>
              <Skeletor width="350" height="16"/>
              <Skeletor width="350" height="16"/>
              <Skeletor width="300" height="16"/>
              <Skeletor width="250" height="16"/>
            </div>
            <div v-if="showJobSearchData" id="tableContainer" class="new-search__card__details active">
              <div class="new-search__details-description">
                <div>
                  <span>{{ $t("jobsearch.detail.description") }} </span>
                </div>
                <strong>{{jobSearchData.description}}</strong>
              </div>
              <div>{{ $t("jobsearch.detail.position") }} <b>{{jobSearchPosition}}</b></div>
              <div>{{ $t("jobsearch.detail.schedule") }} <b>{{jobSearchSchedule}}</b></div>
              <div>{{ $t("jobsearch.detail.skills") }} <b>{{jobSearchSkills}}</b></div>
              <div>{{ $t("jobsearch.detail.address") }} <b>{{jobSearchFullAddress}}</b></div>
              <div>{{ $t("jobsearch.detail.workMode") }} 
                <b v-if="jobSearchData.workPlace == 0">Presencial</b>
                <b v-if="jobSearchData.workPlace == 1">Remoto</b>
                <b v-if="jobSearchData.workPlace == 2">Híbrido</b>
              </div>
              <div>{{ $t("jobsearch.detail.employmentContractType") }} <b>{{jobSearchEmploymentContractType}}</b></div>
              <div>{{ $t("jobsearch.detail.benefits") }} <b>{{jobSearchBenefits}}</b></div>
              <div v-if="jobSearchData.creatorName != ''">{{ $t("jobsearch.detail.creator") }} <b>{{jobSearchData.creatorName}}</b></div>
              <div v-if="jobSearchData.editorName != ''">{{ $t("jobsearch.detail.editor") }} <b>{{jobSearchData.editorName}}</b> </div>
            </div>
            <button v-if="!showJobSearchData" class="btn-confirmNoInterviewed" @click="showJobSearchMoreData()">Ver más</button>
            <button v-else class="btn-confirmNoInterviewed" @click="hideJobSearchMoreData()">Ver menos</button>
          </div>
        </div>

        <div class="new-search__card new-search__card--padding">
          <div class="applies-header">
            <h3>{{ $t("jobsearch.appliesList.title") }}</h3> 
            <a v-if="numberOfApplies > 0" @click="getExcelReport()">Descargar<i class="material-icons">file_download</i></a>
          </div>
          <div class="table-container">
            <table>
              <thead>
                <tr>
                  <th><input type="checkbox" v-model="allAppliesSelected" @click="selectAllApplies()"></th>
                  <th>{{ $t("jobsearch.tableheader.applicant") }}</th>
                  <th>{{ $t("jobsearch.tableheader.applyDate") }}</th>
                  <th>{{ $t("jobsearch.tableheader.forgeStars") }}</th>
                  <th>{{ $t("jobsearch.tableheader.averageGamesCompleted") }}</th>      
                  <th>{{ $t("jobsearch.tableheader.applyStatus") }}</th>
                </tr>
              </thead>
              <tbody v-if="loadedData">
                <template v-if="jobSearchApplies.length > 0">
                  <tr v-for="jsa in jobSearchApplies" :key="jsa.id">
                    <td><input type="checkbox" v-model="jsa.selected" @click="updateSelectedApplies()"></td>
                    <td class="info-profile picture-td">
                      <a :href="openApplyDetail(jsa.id)">
                        <img v-if="jsa.userPicture != null" :src="getImage(jsa.userPicture, jsa.isPictureFromCDN) || require('@/assets/placeholder.png')"/>  
                        <div>
                          <span>
                          {{jsa.userFirstName}} {{jsa.userLastName}}
                          </span>
                          <span>
                          {{jsa.userAge}} {{ $t("jobsearch.tablerow.years") }}
                          </span>
                        </div>  
                      </a>
                    </td>
                    <td class="info-profile">
                      <div class="info-profile__date">
                        <span>
                          {{formatDate(jsa.applyDate)}}
                        </span>
                        <span>
                          {{formatHour(jsa.applyDate)}}
                        </span>  
                      </div>                     
                    </td>
                    <td class="info-profile">
                      <div v-if="jsa.featuredSkills != null">
                        <span>{{$t(`apply.testForge.${jsa.featuredSkills[0].toLowerCase()}`)}}</span>
                        <span>{{$t(`apply.testForge.${jsa.featuredSkills[1].toLowerCase()}`)}}</span>
                      </div>
                      <span v-else>-</span>
                    </td>
                    <td>{{jsa.gamesCompletedAverage}}</td> 
                    <td>{{$t(`jobsearch.detail.${jsa.status}`)}}</td>
                    <td class="actions">
                      <a :href="openApplyDetail(jsa.id)" target="_blank"><i class="material-icons">open_in_new</i></a>
                      <a :href="openApplyDetail(jsa.id)" ><i class="material-icons">visibility</i></a>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr class="col-span-all">
                    <td>{{ $t("jobsearch.tableheader.no-applies") }}</td>
                  </tr>
                </template>
              </tbody>
              <tbody v-else>
                <tr  v-for="index in 7" :key="index">
                    <td><Skeletor width="100%" height="24"/></td>
                    <td><Skeletor width="100%" height="24"/></td>
                    <td><Skeletor width="100%" height="24"/></td>
                    <td><Skeletor width="100%" height="24"/></td>
                    <td><Skeletor width="100%" height="24"/></td>
                    <td><Skeletor width="100%" height="24"/></td>
                    <td><Skeletor width="100%" height="24"/></td>
                    <td><Skeletor width="100%" height="24"/></td>
                </tr>              
              </tbody>
            </table>
          </div>
            <button class="btn-confirmNoInterviewed" @click="activeModalConfirmNoInterviewed = true" :disabled="appliesToRemove.length==0">Registrar postulante/s como no seleccionado/s</button>
        </div>
      </div>
      <div class="modal__overlay" v-if="modalDescription" @click.stop="modalDescription = false">
        <div class="modal-job-description" @click.stop="''">
          <div class="close"><i class="material-icons" @click.stop="modalDescription = false">close</i></div>
          <p v-html="jobSearchData.description"></p>
        </div>
      </div>
    </div>
    <ModalConfirmNoInterviewed v-if="activeModalConfirmNoInterviewed" :multipleRemove="true" :searchId="jobSearchId" :applies="appliesToRemove" @close="closeModalConfirmNoInterviewed()" @confirmAll="updateApplies()"/>
  </div>
</template> 
<script>
import { useStore } from 'vuex';
import { onMounted, ref, computed } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { Skeletor } from 'vue-skeletor';
import ModalConfirmNoInterviewed from '@/components/Apply/ModalConfirmNoInterviewed';


export default {
  components:{
    Skeletor,
    ModalConfirmNoInterviewed,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const loadedData = ref(false);
    const jobSearchData = ref({}); 
    const jobSearchApplies = ref([]); 
    const jobSearchId = ref(route.params.id);
    const jobSearchFullAddress = ref("")
    const jobSearchPosition = ref("")
    const jobSearchEmploymentContractType = ref("")
    const jobSearchSchedule = ref("")
    const jobSearchSkills = ref("")
    const jobSearchBenefits = ref("")
    const numberOfApplies = ref();
    const appliesToRemove = ref([]);
    const activeModalConfirmNoInterviewed = ref(false);
    const allAppliesSelected = ref(false);
    const modalDescription = ref(false);
    const showJobSearchData = ref(false);

    const user = computed(() => store.getters.manageUser_user);
    const organization = computed(() => {
      const organizationId = store.getters.manageUser_organizationId
      var organization = user.value.organizations.find(o => o.organizationId == organizationId);
      return organization;
    })

    onMounted(async () => {
      try {
        const creationOptions = await store.dispatch('organization_creationOptions');
        jobSearchData.value = await store.dispatch('organization_jobSearch_id', {id: route.params.id});
        const response = await store.dispatch('organization_jobSearch_applies_id', {id: route.params.id});
        jobSearchApplies.value = response;

        if(jobSearchData.value.jobPositionId != 0) {
          jobSearchPosition.value = creationOptions.jobPositions.find(x=> x.id == jobSearchData.value.jobPositionId).name
        } else {
          jobSearchPosition.value = jobSearchData.value.positionOther;
        }
        jobSearchEmploymentContractType.value = creationOptions.employmentContractTypes.find(x=> x.id == jobSearchData.value.employmentContractTypeId).name
        jobSearchSchedule.value = creationOptions.jobShcedules.find(x=> x.id == jobSearchData.value.jobScheduleId).name
        
        const countryName = creationOptions.countries.find(x=> x.id == jobSearchData.value.countryId).name
        const stateName = creationOptions.states.find(x=> x.id == jobSearchData.value.stateId).name
        const cityName = creationOptions.cities.find(x=> x.id == jobSearchData.value.cityId).navbar__menu

        jobSearchFullAddress.value = jobSearchData.value.jobAddress
        if(cityName) jobSearchFullAddress.value += `, ${cityName}`
        if(stateName) jobSearchFullAddress.value += `, ${stateName}`
        if(countryName) jobSearchFullAddress.value += `, ${countryName}`

        const skills = []
        const skillsList = [];
        creationOptions.exclusiveSkills.forEach(skills => {
            skillsList.push(...skills.options);
        });

        Object.values(jobSearchData.value.exclusiveSkills).forEach(value => {
          const item = skillsList.find(item => item.id == value);
          skills.push(item.name);
        });
        jobSearchSkills.value = skills.join(', ');
        

        jobSearchBenefits.value = creationOptions.employmentBenefits
          .filter(x=> jobSearchData.value.benefitOffers
                                .filter(ns => ns == x.id).length > 0)
          .map(x => x.name)
          .join(', ');

        numberOfApplies.value = jobSearchData.value.numberOfApplies;

        loadedData.value = true;

      } catch (error) {
        console.log(error)
        store.dispatch('notifications_create', { text: t(`notifications.generic`) });
      }
    });

    function updateSelectedApplies(){
      setTimeout(()=>{
        appliesToRemove.value = [];
        jobSearchApplies.value.forEach(apply =>{
          if(apply.selected){
            appliesToRemove.value.push(apply);
          }
        })
      },100)
    }

    function selectAllApplies(){
      setTimeout(()=>{
        if(allAppliesSelected.value){
          jobSearchApplies.value.forEach(apply =>{
            apply.selected = true;
          })
        }
        else{
          jobSearchApplies.value.forEach(apply =>{
            apply.selected = false;
          })
        }
        updateSelectedApplies();
      },100)
    }

    function getDescription(description) {
      if (description.length > 50) {
        description = description.substring(0, 50);
      }
      return description;
    }

    async function getExcelReport() {
      const params = {
        searchId: route.params.id,
        fileName: "Postulantes_" + jobSearchData.value.title
      }
      await store.dispatch('jobSearch_getExcelReport', params);
    }

    async function updateApplies(){
      appliesToRemove.value = [];
      let response = await store.dispatch('organization_jobSearch_applies_id', {id: route.params.id});
      jobSearchApplies.value = response;
      allAppliesSelected.value = false;
    }
    
    function closeModalConfirmNoInterviewed(){ activeModalConfirmNoInterviewed.value = false }

    function openApplyDetail(applyId){
      //router.push(`/job-searches/${route.params.id}/applies/${applyId}`);
      return `/job-searches/${route.params.id}/applies/${applyId}`;
    }

    function formatDate(dateTime) 
    {
      return moment(dateTime).format("DD/MM/YYYY");
    }

    function formatHour(dateTime)
    {
      return moment(dateTime).format("HH:mm");
    }

    function getImage(path, isFromCDN, isForOrganization = false) {
      if (isFromCDN) {
        return path ? `${process.env.VUE_APP_CDN_URL_USER}${path}` : null;
      } else if (isForOrganization) {
        return path ? `${process.env.VUE_APP_FORGE_ORGANIZATION_MEDIA_URL}${path}` : null;
      }
      return path ? `${process.env.VUE_APP_FORGE_MEDIA_URL}${path}` : null;
    }

    function backRoute () {
      if (window.history.length > 2) {
        router.go(-1)
      } else {
        router.push('/')
      }
    }

    function showJobSearchMoreData() {
      showJobSearchData.value = true
    }

    function hideJobSearchMoreData() {
      showJobSearchData.value = false
    }

    
    
    return {
      jobSearchSkills,
      jobSearchBenefits,
      jobSearchData,
      jobSearchApplies,
      jobSearchId,
      jobSearchFullAddress,
      jobSearchPosition,
      jobSearchEmploymentContractType,
      jobSearchSchedule,
      loadedData,
      backRoute,
      formatDate,
      formatHour,
      openApplyDetail,
      moment,
      getImage,
      appliesToRemove,
      activeModalConfirmNoInterviewed,
      updateSelectedApplies,
      closeModalConfirmNoInterviewed,
      updateApplies,
      allAppliesSelected,
      selectAllApplies,
      numberOfApplies,
      getDescription,
      organization,
      modalDescription,
      showJobSearchMoreData,
      hideJobSearchMoreData,
      showJobSearchData,
      getExcelReport
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~vue-skeletor/dist/vue-skeletor.css';
  .container {
    padding: 0 $margin-base;

    @media (min-width: $break-sm) {
      padding: 0;
    }

    @media (min-width: $break-md) {
      padding: 0 $margin-base;
    }

    @media (min-width: $break-lg) {
      padding: 0;
    }
  }

  h3 {
    display: flex;
    align-items: center;

    a {
      display: inline-block;

      .back {
        font-weight: bold;
        color: $blue;
        font-size: 32px;
        padding: $margin-milli;
      }
    }
  }

  .button-float {
    position: absolute;
    right: 16px;
    bottom: 16px;
    width: 56px;
    height: 56px;
    background: white;
    border-radius: 50%;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 14;
    color: white;
    top: 16px;

    .material-icons {
      background: none;
      color: #087B9A!important;
    }
  }

  #tableContainer {
    display: none;

    &.active {
      display: grid;
      grid-template-areas: 
      "cell cell cell cell cell"
      "cell cell cell cell cell"
      "description description description description description";
      margin-top: 32px;
      grid-template-columns: repeat(5, 1fr);

      > div {

        &:first-child {
          grid-area: description;
          max-width: 100ch;
        }
      }
    }
  }

  .new-search {
    display: grid;
    gap: $margin-kilo;
    width: 1240px;
    padding: 0;
    background: none;
    box-shadow: none;
    padding: 0;
    margin: 0 auto 96px;
    position: relative;

  .header{
      @keyframes animatedBackground {
        from {
          background-size: cover;
          background-position: 0 top;
        }
        to {
          background-size: cover;
          background-position: -1240px top;
        }
      }

      &-bg {
        background: linear-gradient(to top, #403a3e, #be5869);
        background-size: cover;
        background-position: top;
        width: 100%;
        height: 175px;
      }
      &-main{
        width:100%;
        display: flex;
        flex-direction: row;
        margin-top: -190px;
        align-items: end;
        .personal-data__photo {
          margin: 40px 30px 0px 30px ;
          height: 200px;
          width: 200px;
          border: 4px solid #fff;
          flex-shrink: 0;
          flex-grow: 0;
          background: #fff;
          border-radius: 50%;
          background-size: cover;
          background-position: center center;
          cursor: pointer;
        }
        .header-main__data {
          margin-bottom: 75px;
          display: flex;
          flex-direction: column;
          color:#666;
          align-items: baseline;
          gap: 8px;

          &__fullname{
            color: #fff;
            font-size: 32px;
            padding: 0;
            border: none;
          }
          &__age {
            font-size: 20px;
            color:#fff;
          }
        }
      }

      &-personal-data {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        gap: 16px;
        padding: 0px 32px 24px;
        margin-top: -16px;

        .contact-data {
          width: 100%;
          margin-top: 40px;

          div {
            display: flex;
            align-items: center;
            padding: 6px 0px;
            i {
              margin-right: 12px;
            }
          }

          h3 {
            margin: 0px;
            display: flex;
            align-items: center;
            gap: 8px;
            color: #087B9A !important;
            font-family: "Palanquin Dark", sans-serif;
            font-size: 18px;
            line-height: 18px;
            padding-bottom: 16px;
          }

          &__container {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            div {
              display: flex;
              flex-direction: column;
            }
          }

          div &--placeholder, div &--info{
            align-items: start;
            div {
              display: flex;
              flex-direction: row;
              span {
                
                background: #ccc;
                height:20px;
              }
              &:nth-child(1) span {
                width: 200px;
              }
              &:nth-child(2) span {
                width: 100px;
              }
              &:nth-child(3) span {
                width: 120px;
              }
            }
          }

          
        }

        &__strength {
          gap: 12px;
          margin-top: 8px;
          color: white;
          border-radius: 8px;
          padding: 16px!important;
          width: fit-content;
          background: #087B9A;
          margin-bottom: 16px;

          div {
            padding: 0!important;
            gap: 4px;
            display: block!important;;
          }

          span {
            color: white;
          }
        }
      }
    }

    .detail-skeleton-container {
      .vue-skeletor {
        margin-bottom: 8px
      }
    }

    &__details-description {
      >div {
        display: flex;
        align-items: center;
        flex-flow: row nowrap !important;
      }
      &__see-more{
        color: $blue;
        font-weight: 600;
        cursor: pointer;
      }
    }

    &__card {
    width: 100%;
    display: flex;
    flex-flow: column;
    // gap: 16px;
    background: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;

    &--padding {
      padding: 32px;
    }

    &--padding-bottom {
      padding: 20px 32px 32px;

      button {
        max-width: 230px;
        margin-top: 40px;
      }
    }

    &--header {
      padding: 0px;
      
      .actions {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
      }
    }


      &--outline {
        border: none;
      }

      &__details {
        display: grid;
        grid-template-columns: 1fr;
        gap: $margin-kilo;

        div {
          display: flex;
          flex-direction: column;

          b {
            font-family: $font-palanquin;
            font-weight: 500;
            @include font-settings(kilo,base);
          }
        }

        @media (min-width: $break-md) {
          grid-template-columns: repeat(5, 1fr);
          gap: $margin-mega;
        }
      }

      h3 {
        color: $blue;
        font-family: $font-palanquin;
        font-size: 21px;
        line-height: 29px;
        grid-column-start: 1;
        grid-column-end: 1;
        padding: 0 0 12px;

        @media (min-width: $break-md) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }
    }


    &__card__details {
      text-align: left;
    }

    &__subtitle {
      text-shadow: none;
      text-align: left;
      margin-top: 32px;
    }

    .material-icons {
      right: $margin-milli;
      top: $margin-milli;
      font-weight: bold;
      color: $lightgray;
      cursor: pointer;
    }

    p {
      text-shadow: none;
      font-family: $font-opensans;
      color: $gray;
      font-weight: 400;
      @include font-settings(base,base);
      margin-bottom: $margin-base;
    }

    .table-container {
      width: 100%;
      max-height: 100%;

      thead,
      tbody {

        tr {
          text-align: left;
        }
      }
    }

    table {
      font-size: 16px;
      width: 100%;
      color: #101010;
      min-width: 830px;
      thead tr th:first-child{
        text-align: left;
      }
      .material-icons {
        color: $blue;
      }
    }

    tr {
      display: grid;
      grid-template-columns: 24px 1fr 1fr 1fr 1fr 1fr 70px;
      text-align: left;
      gap: $margin-milli;
      td.actions { 
        gap: 8px; 
        display: flex; 
      }
    }

    tr.col-span-all {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      text-align: center;
    }

    td { 
      img {
        object-fit: cover;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        border: 3px solid $blue;
      }
      span {
        text-align: center;
      }
    }

    .info-profile{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      a {
        display: flex;
        gap: 12px;
        align-items: center;
        div span:first-child {
          color:#087B9A;
        }
      }
      div {
        display: flex;
        flex-direction: column;
        text-align: left;

        span {
          font-size: 16px;
          font-weight: 700;
          color: #7d7d7d;
          text-align: left;
        }     
      }
    }

    .info-profile:first-child, .picture-td {
      justify-content: flex-start;
      cursor: pointer;
    }
  }
  .btn-confirmNoInterviewed{
    width: 100%;
    max-width: 460px;
    padding: 4px 0;
    border: 3px solid $blue;
    border-radius: 24px;
    font-size: 16px;
    text-transform: uppercase;
    font-family: "Palanquin Dark", sans-serif;
    font-weight: 500;
    text-align: center;
    background-color: $blue;
    color: #fff;
    &:disabled{
      opacity: .6;
      cursor: default;
    }
  }
.modal-job-description{
  margin: 0 16px;
  padding: 16px;
  width: 100%;
  max-width: 600px;
  background: #fff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 12px;
  .close{
    padding-bottom: 16px;
    text-align: right;
    i{
      color: $gray;
      cursor: pointer;
    }
  }
  p{
    margin: 0;
    white-space: pre-line;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
  }
}
.applies-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  a{
    display: flex;
    align-items: center;
    gap: 2px;
    color: $blue;
    font-weight: 600;
  }
}
</style>



