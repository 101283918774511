<template>
  <div>
    <div class="container__table">
      <h3>{{ $t("jobsearch.list.activeTitle") }}</h3>
      <!-- <a href="/job-searches/new" class="button-float">
        <Tooltip text="Crear" icon="add"/>
      </a> -->

      <!-- Modal select filters -->
      <div class="modal__overlay" v-if="modalSelectFilters" @click.stop="modalSelectFilters=false">
        <div class="filters" v-if="modalSelectFilters" @click.stop="''">
          <div class="filters__close">
            <span>FILTRAR RESULTADOS</span>
            <i class="material-icons" @click.stop="modalSelectFilters=false">close</i>
          </div>
          <div class="filters__status-options">
            <div class="filters__status-options-title"><span>Estado</span></div>
            <div class="filters__status-options-row1">
              <div @click.stop="modelFilters.status = ''" :class="{'active': modelFilters.status == ''}">
                Activas
              </div>
              <div @click.stop="modelFilters.status = 'Published'" :class="{'active': modelFilters.status == 'Published'}">
                {{$t("jobsearch.detail.Published")}}
              </div>
              <div @click.stop="modelFilters.status = 'UnPublished'" :class="{'active': modelFilters.status == 'UnPublished'}">
                {{$t("jobsearch.detail.UnPublished")}}
              </div>
              <div @click.stop="modelFilters.status = 'Closed'" :class="{'active': modelFilters.status == 'Closed'}">
                {{$t("jobsearch.detail.Closed")}}
              </div>
            </div>
            <!--
            <div class="filters__status-options-row2">
              <div @click.stop="modelFilters.status = 'Pending'" :class="{'active': modelFilters.status == 'Pending'}">
                {{$t("jobsearch.detail.Pending")}}
              </div>
              <div @click.stop="modelFilters.status = 'RevisionPending'" :class="{'active': modelFilters.status == 'RevisionPending'}">
                {{$t("jobsearch.detail.RevisionPending")}}
              </div>
              <div @click.stop="modelFilters.status = 'Rejected'" :class="{'active': modelFilters.status == 'Rejected'}">
                {{$t("jobsearch.detail.Rejected")}}
              </div>
            </div>
            -->
          </div>
          <div class="filters__dates">
            <div class="filters__dates__close">
              <span>Fecha de cierre</span>
              <div class="filters__dates__close-from">
                <label>Desde</label>
                <input type="date" v-model="modelFilters.endDateFrom">
              </div>
              <div class="filters__dates__close-to">
                <label>Hasta</label>
                <input type="date" :min="modelFilters.endDateFrom" v-model="modelFilters.endDateTo">
              </div>
            </div>
            <div class="filters__dates__publish">
              <span>Fecha de publicación</span>
              <div class="filters__dates__publish-from">
                <label>Desde</label>
                <input type="date" v-model="modelFilters.publishDateFrom">
              </div>
              <div class="filters__dates__publish-to">
                <label>Hasta</label>
                <input type="date" :min="modelFilters.publishDateFrom" v-model="modelFilters.publishDateTo">
              </div>
            </div>
          </div>
          <div class="filters__buttons">
            <button class="filters__buttons-clear" @click.stop="clearFilters(); modalSelectFilters=false">LIMPIAR FILTROS</button>
            <button class="filters__buttons-apply" @click.stop="applyFilters(); modalSelectFilters = false;">APLICAR</button>
          </div>
        </div>
      </div>

      <!-- Nav filters -->
      <div class="search-nav">
          <div class="search-nav__search-field">
            <span><i class="material-icons">search</i></span>
            <input type="search" v-model="modelFilters.filter" placeholder="Buscar por título" @keydown.enter="applyFilters()">
          </div>
          <div class="search-nav__filter">
            <button @click.stop="modalSelectFilters=true;">
              <span>FILTRAR</span>
              <i class="material-icons">filter_list</i> 
            </button>
          </div>
          <a href="/job-searches/new" class="button button--blue new-search-button">Nueva busqueda</a>
          
          <!-- <div class="search-nav__toggle-view">
            <div><i @click.stop="cardsViewActive=false" :class="{ 'material-icons':true, 'activeView': !cardsViewActive }">view_list</i></div>
            <div><i @click.stop="cardsViewActive=true" :class="{ 'material-icons':true, 'activeView': cardsViewActive }">view_module</i></div>
          </div> -->
      </div>

      <div class="filters-actives">
        <div v-if="filterActive!=''">{{filterActive}} <i class="material-icons" @click.stop="modelFilters.filter=''; applyFilters();">close</i></div>
        <div v-if="modelFiltersActives.status!=''">{{ $t(`jobsearch.detail.${modelFiltersActives.status}`) }} <i class="material-icons" @click.stop="modelFilters.status=''; applyFilters();">close</i></div>
        <div v-if="modelFiltersActives.publishDateFrom !='' || modelFiltersActives.publishDateTo !=''">
          {{$t(`jobsearch.detail.Published`)}} 
          <span v-if="modelFiltersActives.publishDateFrom !=''">desde: {{formatDate(modelFiltersActives.publishDateFrom)}} </span>
          <span v-if="modelFiltersActives.publishDateTo !=''">hasta: {{formatDate(modelFiltersActives.publishDateTo)}}</span>
          <i class="material-icons" @click.stop="modelFilters.publishDateFrom='';modelFilters.publishDateTo=''; applyFilters();">close</i>
        </div>
        <div v-if="modelFiltersActives.endDateFrom !='' || modelFiltersActives.endDateTo !=''">
          {{$t(`jobsearch.detail.Closed`)}} 
          <span v-if="modelFiltersActives.endDateFrom !=''">desde: {{formatDate(modelFiltersActives.endDateFrom)}} </span>
          <span v-if="modelFiltersActives.endDateTo !=''">hasta: {{formatDate(modelFiltersActives.endDateTo)}}</span>
          <i class="material-icons" @click.stop="modelFilters.endDateFrom='';modelFilters.endDateTo=''; applyFilters();">close</i>
        </div>
      </div>

      <div class="new-search">
        <div class="table-container" v-if="!cardsViewActive">
          <table>
            <thead>
              <tr>
                <th class="th__order">
                  <span>{{ $t("jobsearch.tableheader.title") }}</span> 
                  <div class="orderBy"  @click.stop="orderByTitle()">
                    <i :class="{'material-icons':true, 'orderBy--active': orderBy=='titleAsc'}">arrow_drop_up</i>
                    <i :class="{'material-icons':true, 'orderBy--active': orderBy=='titleDesc'}">arrow_drop_down</i>
                  </div> 
                </th>
                <th class="th__order">
                  <span>{{ $t("jobsearch.tableheader.publishDate") }}</span>
                  <div class="orderBy" @click.stop="orderByPublishDate()">
                    <i :class="{'material-icons':true, 'orderBy--active': orderBy=='publishDateAsc'}">arrow_drop_up</i>
                    <i :class="{'material-icons':true, 'orderBy--active': orderBy=='publishDateDesc'}">arrow_drop_down</i>
                  </div> 
                </th>
                <th class="th__order">
                  <span>{{ $t("jobsearch.tableheader.endDate") }}</span>                
                  <div class="orderBy" @click.stop="orderByEndDate()">
                    <i :class="{'material-icons':true, 'orderBy--active': orderBy=='endDateAsc'}">arrow_drop_up</i>
                    <i :class="{'material-icons':true, 'orderBy--active': orderBy=='endDateDesc'}">arrow_drop_down</i>
                  </div> 
                </th>  
                <th class="th--number">{{ $t("jobsearch.tableheader.applyLimit") }}</th>
                <th class="th--number">{{ $t("jobsearch.tableheader.applied") }}</th>
                <th class="th--number">{{ $t("jobsearch.tableheader.viewed") }}</th>
                <th class="th--number">{{ $t("jobsearch.tableheader.contacted") }}</th>
                <th class="th--number">{{ $t("jobsearch.tableheader.hired") }}</th>
                <th class="th--number">{{ $t("jobsearch.tableheader.notSelected") }}</th>
                <!-- <th>{{ $t("jobsearch.tableheader.publishUser") }}</th> -->
                <th class="table-container__actions">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="jobSearchActivesReady">
                <tr v-for="jsa in jobSearchActives" :key="jsa.id">
                  <td class="table-container__title" @click.stop="openJobSearchApplies(jsa.id)">
                    <div class="table-container__title__click" >{{ jsa.title }}</div>
                    <div class="table-container__title__status" :class="`table-container__title__status--${ jsa.publicationStatus }`">{{ $t(`jobsearch.detail.${jsa.publicationStatus}`) }}</div>
                  </td>
                  <td>{{ formatDate(jsa.publishDate) }}</td>
                  <td>{{ formatDate(jsa.endDate) }}</td>
                  <td class="td--number">{{ jsa.applyLimit }}</td>
                  <td class="td--number">{{ jsa.applied }}</td>
                  <td class="td--number">{{ jsa.viewed }}</td>
                  <td class="td--number">{{ jsa.contacted }}</td>
                  <td class="td--number">{{ jsa.hired }}</td>         
                  <td class="td--number">{{ jsa.notSelected }}</td>
                  <!-- <td class="table-container__email" :title="`${jsa.publishUser}`">{{ jsa.publishUser }}</td> -->
                  <td class="table-container__actions">
                    <div class="table-container__actions-container">
                      <Tooltip text="Editar" icon="edit" @click.stop="openJobSearch(jsa.id)"/>
                      <Tooltip text="Clonar" icon="copy_all" @click.stop="openCloneJobSearch(jsa.id)"/>
                      <Tooltip text="Ver" icon="visibility"  @click.stop="openJobSearchApplies(jsa.id)"/>
                      <Tooltip text="Borrar" icon="delete" @click.stop="openModalDelete(jsa.id)"/>             
                    </div>
                  </td>
                </tr>
              </template>
              <tr v-else v-for="index in 3" :key="index">
                  <td><Skeletor width="100%" height="24"/></td>
                  <td><Skeletor width="100%" height="24"/></td>
                  <td><Skeletor width="100%" height="24"/></td>
                  <td><Skeletor width="100%" height="24"/></td>
                  <td><Skeletor width="100%" height="24"/></td>
                  <td><Skeletor width="100%" height="24"/></td>
              </tr>
            </tbody>
          </table>
          <div id="endDiv" v-if="jobSearchActivesReady"></div>
        </div>

        <div class="cards-container" v-if="cardsViewActive">
          <div class="search-card" v-for="jsa in jobSearchActives" :key="jsa.id">
            <div class="search-card__row1">
              <div class="search-card__title">
                  <span>{{ jsa.title }}</span>
                <div class="search-card__title__status" :class="`search-card__title__status--${ jsa.publicationStatus }`">
                  {{ $t(`jobsearch.detail.${jsa.publicationStatus}`) }}
                </div>
              </div>
              <span class="search-card__actions" @click.stop="jsa.modalActionsActive=true">...</span>
            </div>
            <div class="search-card__row2">
              <div class="search-card__date">
                <span>{{ $t("jobsearch.tableheader.endDate") }}</span>
                <span>{{ formatDate(jsa.endDate) }}</span>
              </div>
              <span class="search-card__applies">
                <i class="material-icons">people_alt</i> {{ jsa.applied }} 
              </span>
            </div>
            <div v-if="jsa.modalActionsActive" class="search-card__modal-actions">
              <span class="search-card__modal-actions__close"><i class="material-icons" @click.stop="jsa.modalActionsActive=false">close</i></span>
              <a><i @click.stop="openJobSearch(jsa.id)" class="material-icons">edit</i></a>
              <a><i @click.stop="openCloneJobSearch(jsa.id)" class="material-icons">copy_all</i></a>
              <a><i @click.stop="openJobSearchApplies(jsa.id)" class="material-icons">visibility</i></a>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="modal__overlay" v-if="modalConfirmDelete" @click.stop="modalConfirmDelete=false">
      <div class="modal modal-confirmDelete" @click.stop="''">
        <p>¿Está seguro que quiere borrar esta búsqueda?</p>
        <div class="buttons">
          <button class="button cancel" @click.stop="modalConfirmDelete=false;jobSearchIDActive=null;">Cancelar</button>
          <button class="button" @click.stop="deleteJobSearch()">Aceptar</button>
        </div>
      </div>
    </div>

    <div class="modal__overlay" v-if="modalCantDelete" @click.stop="modalCantDelete=false">
      <div class="modal modal-cantDelete" @click.stop="''">
        <p>¡No es posible borrar una búsqueda con candidatos postulados!</p>
        <button class="button" @click.stop="modalCantDelete=false">Aceptar</button>
      </div>
    </div>
  </div>
</template> 

<script>
import { useStore } from 'vuex';
import { onMounted, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { Skeletor } from 'vue-skeletor';
import Tooltip from '@/components/Common/Tooltip.vue';

export default {
  components:{
    Skeletor,
    Tooltip
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const jobSearchActives = ref([]);
    const { t } = useI18n();
    const jobSearchActivesReady = ref(false);
    const cardsViewActive = ref(false);
    const modalConfirmDelete = ref(false);
    const modalCantDelete = ref(false);
    const jobSearchIDActive = ref(null);
    const remainResults = ref();
    const rows = ref(20);
    const page = ref(0);

/* FILTROS */
    const modelFilters = ref({
      filter: '',
      status: '',
      publishDateFrom: '',
      publishDateTo: '',
      endDateFrom: '',
      endDateTo: '',
      pageNumber: page.value,
      rowsPerPage: rows.value,
      orderBy: 'title',
      sortBy: 'asc'
    });
    let modelFiltersActives = ref({
      filter: '',
      status: '',
      publishDateFrom: '',
      publishDateTo: '',
      endDateFrom: '',
      endDateTo: '',
      pageNumber: page.value,
      rowsPerPage: rows.value,
      orderBy: 'title',
      sortBy: 'asc'
    });
    let filterActive = ref('');
    const modalSelectFilters = ref(false);
    const orderBy = ref('titleAsc');
    async function applyFilters(){
      jobSearchActivesReady.value=false;
      try {
        switch(orderBy.value){
          case 'publishDateDesc':
            modelFilters.value.orderBy = 'publishDate'
            modelFilters.value.sortBy = 'desc'
            break;
          case 'publishDateAsc':
            modelFilters.value.orderBy = 'publishDate'
            modelFilters.value.sortBy = 'asc'
            break;
            case 'endDateDesc':
              modelFilters.value.orderBy = 'endDate'
              modelFilters.value.sortBy = 'desc'
              break;
          case 'endDateAsc':
            modelFilters.value.orderBy = 'endDate'
            modelFilters.value.sortBy = 'asc'
            break;
          case 'titleDesc':
            modelFilters.value.orderBy = 'title'
            modelFilters.value.sortBy = 'desc'
            break;
          case 'titleAsc':
            modelFilters.value.orderBy = 'title'
            modelFilters.value.sortBy = 'asc'
            break;
        }
        /* const response =  await store.dispatch('organization_jobSearch_searchFilters',modelFilters.value);
        jobSearchActives.value = response.data; */
        page.value = 0;
        jobSearchActives.value = [];
        getJobsData();
        modelFiltersActives.value = JSON.parse(JSON.stringify(modelFilters.value));
        filterActive.value = JSON.parse(JSON.stringify(modelFilters.value.filter));
        jobSearchActivesReady.value=true;
      } catch (e) {
        store.dispatch('notifications_create', { text: t(`notifications.generic`) });
      }
    }
    function clearFilters(){
      modelFilters.value = {
        filter: '',
        status: '',
        publishDateFrom: '',
        publishDateTo: '',
        endDateFrom: '',
        endDateTo: '',
        pageNumber: page.value,
        rowsPerPage: rows.value,
        orderBy: 'title',
        sortBy: 'asc'
      };
      filterActive.value = '';
      modelFiltersActives.value = modelFilters.value;
      applyFilters()
    }
    watch(modalSelectFilters, ()=>{
      if(modalSelectFilters.value){
        modelFilters.value = JSON.parse(JSON.stringify(modelFiltersActives.value));
      }
    })
    function orderByPublishDate(){
      if(orderBy.value != 'publishDateDesc') orderBy.value = 'publishDateDesc';
      else orderBy.value = 'publishDateAsc';
      applyFilters()
    }
    function orderByEndDate(){
      if(orderBy.value != 'endDateDesc') orderBy.value = 'endDateDesc';
      else orderBy.value = 'endDateAsc';
      applyFilters()
    }
    function orderByTitle(){
      if(orderBy.value != 'titleAsc') orderBy.value = 'titleAsc';
      else orderBy.value = 'titleDesc';
      applyFilters()
    }
/* FIN FILTROS */
    let options;
    let observer;
    let target; 

    async function getJobsData(){
      //const jobSearches =  await store.dispatch('organization_jobSearch_search', { orderBy:'publishDate', sortBy:'asc', filter: '', pageNumber: page.value, rowsPerPage: rows.value });
      const jobSearches =  await store.dispatch('organization_jobSearch_searchFilters', modelFilters.value);
      remainResults.value = jobSearches.totalResults - (rows.value * (page.value+1));
      jobSearchActives.value.push(...jobSearches.data);
      jobSearchActivesReady.value = true;
    }

    onMounted(async () => {
      try {
        await getJobsData()

        options = {
          root: document.querySelector('#scrollRoot'),
          rootMargin: '0px',
          threshold: 1.0
        }
       
        setTimeout(async()=>{ 
          target = document.querySelector('#endDiv');
          observer = new IntersectionObserver((entries)=>{
            if(entries[0].isIntersecting) pagination();
          }, options);  
          observer.observe(target); 
        },0)

      } catch (e) {
        store.dispatch('notifications_create', { text: t(`notifications.generic`) });
      }
    });

    async function pagination(){
      if(remainResults.value > 0){
        page.value++;
        await getJobsData();
      }
    }

    function openModalDelete(jobSearchID){
      modalConfirmDelete.value = true;
      jobSearchIDActive.value = jobSearchID;
    }

    async function deleteJobSearch() {
      modalConfirmDelete.value = false;
      try {
        const request = await store.dispatch('jobSearch_delete', {searchId: jobSearchIDActive.value});
        if (request.status == 200) {
          store.dispatch('notifications_create', { text: t(`notifications.deletedJobSearch`) });
          jobSearchActives.value = jobSearchActives.value.filter(job => job.id != jobSearchIDActive.value);
        }
      } catch (error) {
        if (error.response.data == "JobSearch has applies") {
          //store.dispatch('notifications_create', { text: t(`notifications.invalidDeleteJobSearch`) });
          modalCantDelete.value = true;
        } else {
          store.dispatch('notifications_create', { text: t(`notifications.generic`) });
        }
      }
      jobSearchIDActive.value = null;
    }

    function openJobSearch (jobSearchID) {
      router.push(`/job-searches/edit/${jobSearchID}`);
    }

    function openCloneJobSearch (jobSearchID) {
      router.push(`/job-searches/clone/${jobSearchID}`);
    }

    function openJobSearchApplies (jobSearchID) {
      router.push(`/job-searches/${jobSearchID}/applies`);
    }

    function formatDate(dateTime) {
        return moment(dateTime).isValid() ? moment(dateTime).format("DD/MM/YYYY") : "";
    }
    
    return {
      jobSearchActives,
      jobSearchActivesReady,
      openJobSearch,
      openCloneJobSearch,
      openJobSearchApplies,
      formatDate,
      cardsViewActive,
      applyFilters,
      clearFilters,
      modelFilters,
      modelFiltersActives,
      filterActive,
      modalSelectFilters,
      orderBy,
      orderByPublishDate,
      orderByEndDate,
      orderByTitle,
      deleteJobSearch,
      modalConfirmDelete,
      modalCantDelete,
      openModalDelete,
      rows,
      page,
      remainResults
    }
  }
}
</script>

<style lang="scss" scoped>  
  @import '~vue-skeletor/dist/vue-skeletor.css';  
  .new-search {
    margin-top: 18px;
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        font-size: 32px;
        color: $lightgreen;
      }
    }
    
    p {
      text-shadow: none;
      font-family: $font-opensans;
      color: $gray;
      font-weight: 400;
      @include font-settings(base,base);
      margin-bottom: $margin-base;
    }
 
    .table-container__title {
      text-align: left;

      &__click {
        color: $lightgreen;
        
      }
      cursor:pointer;
      &:hover {
        opacity: 0.7;
      }

      &__status {
        @include font-settings(milli,base);
        color: $gray;
        display: flex;
        gap: 4px;
        margin-top: $margin-milli;

        &:before {
          content: '';
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: $gray;
        }

        &--Published {
          color: $lightgreen;

          &:before {
            background: $lightgreen;
          }
        }

        &--Closed {
          color: tomato;

          &:before {
            background: tomato;
          }
        }
      }       
    }

    .th__order{
      span{
        color: #7d7d7d;
        font-weight: 600;
      }
    }

    .th--number, .td--number {
      max-width: 120px;
    }

    tr {
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)) repeat(2, minmax(180px, 1fr)) repeat(6, minmax(120px, 1fr)) repeat(1, minmax(180px, 1fr));
    }

    /* Vista cards */
    .cards-container{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 10px;
      
      .search-card{
        position: relative;
        width: 288px;
        height: 169px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        border: 1px solid #E0E0E0;
        border-radius: 20px;
        font-family: $font-palanquin;
        color: $gray;
        background-color: $white;
        &__row1, &__row2{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .search-card__applies{
            display: flex;
            align-items: center;
            gap: 3px;
            font-size: 16px;
            color: $mediumgray;
            font-weight: 700;
          }
          .search-card__actions{
            padding: 0 10px;
            align-self: flex-start;
            line-height: 2px;
            font-size: 26px;
            font-weight: bold;
            color: $mediumgray;
            cursor: pointer;
          }
        }
        &__title, &__date {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          font-family: $font-opensans;
        }
        &__title{
          gap: 4px;
          :first-child {
            font-family: Palanquin Dark;
            font-weight: 500;
            font-size: 20px;
            line-height: 23px;
            color: $mediumgray;
            text-align: left;
          }
          &__status {
            @include font-settings(milli,base);
            font-family: Open Sans;
            font-weight: bold;
            font-size: 14px;
            display: flex;
            gap: 4px;
            &:before {
              content: '';
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background: $gray;
            }
            &--Published {
              color: $lightgreen;

              &:before {
                background: $lightgreen;
              }
            }
            &--Closed {
              color: tomato;

              &:before {
                background: tomato;
              }
            }
          }
        }
        &__date{
          span{
            color: $mediumgray;

          }
          :first-child{
            font-size: 14px;
            font-weight: 600;
          }
          :last-child{
            padding-left: 4px;
            font-size: 16px;
            font-weight: 700;
          }
        }
        &__modal-actions{
          position: absolute;
          top: 3px;
          right: 6px;
          padding: 5px;
          display: inline-flex;
          flex-direction: column;
          justify-content: space-evenly;
          background: #fff;
          border-radius: 20px;
          border: 1px solid rgba(125, 125, 125,.5);
          box-shadow: -1px 1px 10px 1px rgba(0,0,0,0.10);
          &__close{
            text-align: right;
            i{
              cursor: pointer;
            }
          }
          a{
            padding: 0 27px 0 25px;
            border-bottom: 2px solid #E0E0E0;
            margin-bottom: 10px;
            i{
              cursor: pointer;
            }
            &:last-child{
              border: none;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
.th__order{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:first-child{
    justify-content: flex-start;
  }
}
.orderBy{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  i{
    line-height: 8px;
    color: $lightgray;
    cursor: pointer;
    &.orderBy--active{
      color: $blue;
    }
  }
}

.filters{
  position: relative;
  width: 98%;
  max-width: 490px;
  height: fit-content;
  min-height: 326px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  z-index: 500;
  div{
    width: 100%;
  }
  &__close{
    padding-bottom: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span{
      color: $blue;
      font-family: $font-palanquin;
      font-size: 18px;
      font-weight: bold;
    }
    i{
      cursor: pointer;
    }
  }
  &__status-options{
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    div{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      div{
        width: fit-content;
        padding: 2px 12px;
        font-family: Palanquin Dark;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 23px;
        color: $mediumgray;
        text-transform: uppercase;
        border: 1px solid $mediumgray;
        border-radius: 16px;
        cursor: pointer;
        &.active{
          background-color: $mediumgray;
          color: #fff;
        }
      }
    }
    &-title{
      span{
        font-family: $font-opensans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 23px;
        color: $gray;
      }
    }
  }
  &__dates{
    padding: 16px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div{
      width: fit-content;
      display: inline-flex;
      flex-direction: column;
      gap:4px;
      span{
        font-family: Open Sans;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 23px;
        color: $mediumgray;
      }
      div{
        display: flex;
        flex-direction: column;
        gap: 0;
        label{
          font-size: 12px;
          font-family: Palanquin dark;
          line-height: 12px;
         
        }
        input{
          width: 200px;
          border: none;
          border-bottom: 2px solid $mediumgray;
          color: $gray;
          font-family: Palanquin Dark;
          line-height: 12px;
          color: $mediumgray;
          &:first-of-type{
            margin-bottom: 8px;
          }
        }
      }
    }
  }
  &__buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &-clear{
      font-family: Palanquin Dark;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 25px;
      color: #9C9B9B;
    }
    &-apply{
      padding-bottom: 2px;
      width: 107px;
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $blue;
      border-radius: 50px;
      font-family: $font-palanquin;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      color: #fff;
    }
  }
}

.search-nav{
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;
  display: flex;
  flex-flow: row wrap-reverse;
  gap: 8px;

  .new-search-button{
    color: #fff;
    margin: 0;  
  }

  &__search-field{
    width: 100%;
    max-width: 345px;
    display: flex;
    span{
      height: 40px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      color: #7d7d7d;
      border-color: #7D7D7D;
      border-style: solid;
      border-width: 1px 0 1px 1px;
      border-radius: 3px 0 0 3px;
    }
    input{
      width: 100%;
      height: 40px;
      border: none;
      border: 1px solid #7D7D7D;
      border-color: #7D7D7D;
      border-style: solid;
      border-width: 1px 1px 1px 0;
      border-radius: 0 3px 3px 0;
      background-color: $lightergray;
      &::placeholder {
        font-family: Open Sans;
        font-weight: bold;
        font-size: 16px;
        color: #7d7d7d;
      }
    }
  }
  &__filter{
    button{
      width: 137px;
      height: 40px;      
      padding: 0px 16px;      
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border: 1px solid #087B9A;
      border-radius: 3px;
      span{
        font-family: Palanquin Dark;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 25px;
        color:$blue;
      }
      i{
        color:$blue;
      }
    }
  }
  &__toggle-view {
    height: 40px;
    display: inline-flex;
    align-items: stretch;
    background: #FFF;
    border: 1px solid #087B9A;
    border-radius: 3px;
    div{
      width: 40px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      &:first-child {
        border-right: 1px solid #087B9A;
      }
      i{
        color: #9C9B9B;
        cursor: pointer;
      }
    }
  }
  .activeView{
    color: #087B9A;
  }
}

.filters-actives{
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  div{
    height: 32px;
    padding: 0 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border: 1px solid #087B9A;
    border-radius: 210px;
    font-family: Palanquin Dark;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #087B9A;
    span{
      font-family: Palanquin Dark;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      color: #087B9A;
    }
    i{
      font-size: 20px;
      cursor: pointer;
    }
  }
}
.modal-cantDelete,.modal-confirmDelete{
  padding-bottom: 16px;
  p{
    padding-top: 16px;
    font-size: 18px;
    font-weight: 600;
  }
  .button{
    margin: 0;
    background: $blue;
  }
  .buttons{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    .cancel{
      background: #fff;
      color: $gray;
      border: 1px solid $gray;
    }
  }
}
.table-container__actions {
  min-width: 120px;
}
</style>