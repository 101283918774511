
import { createStore } from 'vuex';
import ManageUser from '@/store/common/ManageUser';
import Home from '@/store/modules/Home';
import Notifications from '@/store/common/Notifications';
// API
import APISecurity from '@/store/modules/API/Security';
import APIOrganization from '@/store/modules/API/Organization';
import APIJobSearch from '@/store/modules/API/JobSearch';
import APIOrganizacionUser from '@/store/modules/API/OrganizationUser';

export default createStore({
  modules: {
    ManageUser,
    Notifications,
    Home,
    APISecurity,
    APIOrganization,
    APIJobSearch,
    APIOrganizacionUser
  }
});