<template>
 <div class="modal__overlay" @click.stop="toggleModal()">
    <div class="modal" @click.stop="''">
      <span class="material-icons" @click.stop="toggleModal()">close</span>
      <h3>{{message}}</h3>
      <div class="modal__buttons">
        <button class="button--gray" @click.stop="toggleModal()">cancelar</button>
        <button @click="confirm">confirmar</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    message: String
  },
  setup(_, {emit}) {

    function toggleModal() {
      emit('closeModalConfirm');
    }

    async function confirm() {
      emit('confirm');
    }

    return {
      toggleModal,
      confirm
    }
  }
}
</script>
<style lang="scss" scoped>
  
</style>