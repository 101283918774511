<template>
  <div class="navbar-sticky" v-if="openNavbar"></div>
  <div class="navbar-overlay" v-if="openNavbar" @click="setNavbar(false)"></div>
  <div class="navbar" :class="{'open': openNavbar}">
    <i class="material-icons navbar__icon" @click="setNavbar(true)" :class="{'hide': openNavbar}">menu</i>
    <i class="material-icons navbar__icon navbar__close" @click="setNavbar(false)" :class="{'show': openNavbar}">close</i>
    <div class="navbar__user-data">

      <div class="organization-logo">
        <div  v-if="user.organizations.length > 1" class="organization-logo__img" :style="`background-image: url(${getImage(organization.organizationPicture, organization.isPictureFromCDN)})`" @click="openNavbar ? popFileSelector() : openChangeOrganizationModal()"></div>
        <div v-else class="organization-logo__img" :style="`background-image: url(${getImage(organization.organizationPicture, organization.isPictureFromCDN)})`"></div>
        <input id="organizationLogoUpload" type="file" @change="uploadNewLogo($event)">
      </div>

      <div class="navbar__user-data__fullname">{{user.firstName}} {{user.lastName}}</div>
      <div class="navbar__user-data__organization-name" @click="openChangeOrganizationModal">{{organization.organizationName}}</div>
    </div>
    <ul class="navbar__menu">
      <!-- <li><a href="/"><i class="material-icons">home</i> <span>Home</span></a></li> -->
      <li :class="{'show': openNavbar}" v-if="user.organizations.length > 1"  @click="openChangeOrganizationModal"><a><span>{{$t('login.change-organization.modaltitle')}}</span><i class="material-icons">sync_alt</i></a></li>
      <li><a href="/job-searches/new"><i class="material-icons">note_add</i> <span>{{ $t("menu.newjobsearch") }}</span></a></li>
      <li><a href="/job-searches/actives"><i class="material-icons">work</i> <span>{{ $t("menu.jobsearches") }}</span></a></li>
      <li><a href="/users"><i class="material-icons">people</i> <span>{{ $t("menu.users") }}</span></a></li>
      <!-- <li><a href="/profile"><i class="material-icons">account_circle</i> <span>{{ $t("menu.profile") }}</span></a></li> -->
      <li @click="logout"><a><i class="material-icons">logout</i> <span>{{ $t("menu.logout") }}</span></a></li>
    </ul>
    <div class="menu-powered-by">
      <a href="https://fondationforge.org/" target="_blank">Powered By Forge</a>
    </div>
  </div>
  <div v-if="activeModalChangeOrganization" class="modal__overlay" @click.stop="closeChangeOrganizationModal()">
      <div class="modal" @click.stop="''">
        <span class="material-icons" @click.stop="closeChangeOrganizationModal()">close</span>
        <h3>{{$t('login.change-organization.modaltitle')}}</h3>
        <p>
          {{$t('login.change-organization.modaldescription')}}
        </p>      
        <div class="modal__organization-selector__container">
          <template  v-for="org in user.organizations" :key="org.organizationId">
            <div class="modal__organization-selector__container__item" @click="changeOrganization(org.organizationId)" :class="organizationToChange == org.organizationId ? 'selected' : ''">
              <img :src="getImage(org.organizationPicture, org.isPictureFromCDN)" />
              <span>{{org.organizationName}}</span>
            </div>
          </template>
        </div>
        <div class="modal__buttons">
          <button class="button--gray" @click.stop="closeChangeOrganizationModal()">{{$t('login.change-organization.modalaction-cancel')}}</button>
          <button @click="applyOrganizationChange()">{{$t('login.change-organization.modalaction-change')}}</button>
        </div>
      </div>
    </div>
</template>
<script>
import { computed, onMounted } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import storage from '@/plugins/azure-storage';
import { ref } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const logged = computed(() => store.getters.manageUser_logged);
    const openNavbar = ref(false);
    const user = computed(() => store.getters.manageUser_user);
    const organization = computed(() => {
      const organizationId = store.getters.manageUser_organizationId
      return user.value.organizations.find(o => o.organizationId == organizationId);
    })
    let org = ref(organization);

    async function logout() {
      await store.dispatch('manageUser_disabled');
      router.push('/login');
    }

    const organizationToChange = ref(0)
    onMounted(() => {
      organizationToChange.value = organization.value.organizationId
    })
    const activeModalChangeOrganization = ref(false)
    
    function setNavbar(value) { openNavbar.value = value }
    function openChangeOrganizationModal() { activeModalChangeOrganization.value = true }
    function closeChangeOrganizationModal() { activeModalChangeOrganization.value = false }
    function changeOrganization(organizationSelected) {  organizationToChange.value=organizationSelected  }

    async function uploadNewLogo(e){
      let imagePath = await storage.updateFile(e.target.files[0]);
      await store.dispatch('organization_updateLogo',{image:imagePath})
      await store.dispatch('manageUser_changeLogo',{organizationId:organization.value.organizationId,img:imagePath})
      store.dispatch('notifications_create', { text: t(`notifications.updateLogo`) });
    }

    function popFileSelector () {
      var el = document.getElementById("organizationLogoUpload");
      if (el) {
          el.click();  
      }
    }

    function getImage(path, isFromCDN) {
      if (isFromCDN) {
        return path ? `${process.env.VUE_APP_CDN_URL_USER}${path}` : null;
      } else {
        return path ? `${process.env.VUE_APP_FORGE_ORGANIZATION_MEDIA_URL}${path}` : null;
      }
    }

    async function applyOrganizationChange (){
      await store.dispatch('manageUser_defineOrganization', organizationToChange.value);
      //window.location.reload();
      router.push('/');
      setTimeout(() => { window.location.reload(); }, 100);
    }

    return {
      logged,
      organization,
      user,
      logout,
      activeModalChangeOrganization,
      organizationToChange,
      openChangeOrganizationModal,
      closeChangeOrganizationModal,
      changeOrganization,
      applyOrganizationChange,
      openNavbar,
      setNavbar,
      uploadNewLogo,
      getImage,
      popFileSelector,
      org
    };
  }
}
</script>

<style lang="scss" scoped>


  .menu-powered-by {
    position: absolute;
    bottom: 16px;
    display: none;

    a {
      background-image:url("~@/assets/logo-fundacion-forge-footer.png");
      background-repeat: no-repeat;
      background-position: left;
      background-size: 28px;
      font-size:14px;
      line-height: 34px;
      padding-left: 44px;
      display: block;
      color: $white;
    }
  }
  
  .navbar-sticky {
    width: 64px;
    height: 100vh;
    position: relative;
    left: 0;
    z-index: 0;
  }

  .navbar-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 80%);
    z-index: 15;
  }

  .navbar {
    background: linear-gradient(180deg, #087B9A 0%, #29A1C2 100%);
    border-radius: 0;
    padding: $margin-kilo $margin-base;
    text-align: center;
    width: 100%;
    position: fixed;
    z-index: 16;
    top: 0;
    display: flex;
    height: 100vh;
    position: sticky;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &.open {
      width: 280px;
      position: fixed;
      align-items: flex-start;

      .menu-powered-by {
        display: block;
      }

      .navbar__user-data {
        padding-bottom: 12px;
        color: $white;
        margin-bottom: $margin-mega;  
        
        img {
          width: 96px;
          height: 96px;
          margin-bottom: $margin-milli;
          
          &.no-action:hover {
            opacity:1;
          }
          &:hover {
            opacity: 0.5;
          }
        }

        &__fullname{
          display: block;
          font-weight: bold;
        }

        &__organization-name {
          display: block;
          color: $white;
          cursor: pointer;
        }
      }

      li {

        a {
          flex-direction: row;
          gap: $margin-milli;
          
          span {
            font-family: $font-palanquin;
            font-weight: 700;
            font-size: 16px;
            color: $white;
            display: block;
            font-weight: 700;
          }
        }
      }
    }

    &__close {
      display: none;
    }

    .hide {
      display: none;
    }

    .show {
      display: block;
    }

    &__user-data {
      text-align: left;
      // border-bottom: 2px solid #fff;

      &__fullname {
        display: none;
      }

      &__organization-name {
        display: none;
      }
      
      img {
        border-radius: 50%;
        width: 32px;
        height: 32px;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
        cursor: pointer;
        transition: .3s ease-in-out opacity;
        object-fit: cover;
        &.no-action {
          cursor: default;  
        }
      }
    }

    &__icon {
      color: $white;
      font-size: 32px;
      cursor: pointer;
      user-select: none;
    }

    &__menu {
      display: flex;
      width: 100%;
      flex-direction: column;
      height: 60vh;
      
    }

    li {
      font-family: $font-palanquin;
      font-weight: 700;
      // text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.25);
      color: $white;
      text-transform: uppercase;
      padding: $margin-milli 0;
      cursor: pointer;

      &:last-child {
        margin-top: auto;
        margin-bottom: $margin-mega;
      }

      &:nth-child(1) {
        border: 1px solid white;
        border-radius: 8px;
        margin-bottom: 80px;
        margin-top: -32px;
        display: none;
        &.show {
          display: block;
        }
        a {
          justify-content: center;
        }
      }

      a {
        display: flex;
        flex-direction: column;
        align-items: center;

        i {
          font-size: 32px;
        }

        span {
          display: none;
        }
      }
    }

    @media (min-width: $break-md) {
      
    }
  }

  .modal
  {
    &__organization-selector__container
    {
      &__item {
          display: inline-flex;
          width: 100%;
          border-radius: 20px;
          padding: 4px;
          margin-bottom: 4px;
          cursor: pointer;

        &.selected {
          background-color: $lightgreen;

          span {
            color: $white;
            font-weight: bold;
          }
        }

        img {
          object-fit: cover;
          width: 30px;
          height: 30px;
          border-radius: 100%;
          margin: 0px;
        }
        span {
          color: #333;
          line-height: 30px;
          padding-left: 10px;
        }
      }
    }   
  }

  .navbar{
    &__user-data{
      .organization-logo{
        position: relative;
        &__img {
          width: 60px;
          height: 60px;
          background: $white;
          border-radius: 50%;
          border: 2px solid $white;
          flex-shrink: 0;
          background-size: cover;
          background-position: center center;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
          cursor: pointer;
        }
          input[type="file"] {
            visibility: hidden;
            width: 40px;
            height: 40px;
            background: $white;
            border-radius: 50%;
            border: 3px solid $white;
            flex-shrink: 0;
            background-size: cover;
            background-position: center center;
            box-shadow: 0px 0px 10px rgba(103, 223, 17, 0.15);
            cursor: pointer;
            position: absolute;
            bottom: 0;
            left: 85px;
  
            &::before {
              font-family: "Material Icons";
              content: "\e412";
              font-size: 24px;
              display: flex;
              color: #000;
              align-items: center;
              justify-content: center;
              position: relative;
              height: 100%;
              width: 100%;
            }
            &::file-selector-button {
              opacity: 0;
            }
          }
      }
    }
    &.open{
      .navbar__user-data{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        .organization-logo{
          position: relative;
          &__img {
            width: 120px;
            height: 120px;
            border: 3px solid $white;
          }
          input[type="file"] {
            visibility: visible;    
          }
        }
      }
    }
  }
  
  
</style>