{
    "home": {
      "title": "¡El trabajo de tus sueños te está esperando!",
      "login": "INGRESAR"
    },
    "login": {
      "title": "¡Hola!",
      "description": "¡Te damos la bienvenida al portal de Ruky para empresas!",
      "input_email": "Email",
      "input_password": "Contraseña",
      "forgotPassword": "Olvidé mi contraseña",
      "login-with":"Ingresar con:",
      "action": "INGRESAR",
      "actionDisabled": "PROCESANDO ...",
      "organization_selection" : {
        "description" : "Selecciona con cual empresa queres trabajar en Ruky",
        "organization" : "Seleccionar Empresa",
        "action" : "Continuar"
      },
      "change-organization":{
        "modaltitle":"Cambiar de empresa",
        "modaldescription":"Seleccione la empresa con la cual quiere trabajar",
        "modalaction-cancel":"Cancelar",
        "modalaction-change":"Cambiar"
      },
      "recoveryPassword":{
        "title": "Recuperar contraseña",
        "description": "Ingresa el mail con el que te registraste",
        "endDescription": "Ingrese la clave de seguridad que fue enviada a su email:",
        "input_code":"Código",
        "input_password": "Contraseña",
        "input_repeatPassword": "Repetir contraseña",
        "action":"Recuperar",
        "back" : "Iniciar sesión",
        "actionDisabled":"Procesando..."
      }
    },
    "apply" : {
      "title":"Perfil de",
      "personalInfo":"Información personal",
      "workInfo":"Experiencia laboral",
      "studyAcademicsInfo":"Formación académica",
      "studyLanguageInfo":"Idiomas",
      "gamesInfo":"Habilidades",
      "requestContactData":"Solicitar datos de contacto",
      "firstName":"Nombre",
      "lastName":"Apellido",
      "age":"Edad",
      "gender":"Género",
      "indistinctGender": "Indistinto",
      "selfPerceivedGender":"Género autopercibido",
      "profileInterest" : "Intereses",
      "whatMakesMeUniq":"¿Qué me hace único?",
      "myPhotos":"Mis fotos",
      "current": "en curso",
      "setHired":"Registrar contratación",
      "setNoInterviewed":"Registrar postulante no seleccionado",
      "status" : {
        "Pre":"",
        "Ok":"Postulado",
        "Viewed":"Visto",
        "Hired":"Contratado",
        "NotHired":"No contratado",
        "NotInterviewed":"No seleccionado",
        "ContactDataRequired":"Contacto solicitado"
      },
      "hired" : {
        "modaltitle":"Registrar contratación",
        "modaldescription":"¡Excelente! ¿Confirmas que este perfil ha sido seleccionado para el puesto?",
        "modalaction-cancel":"Cancelar",
        "modalaction-sethired":"Registrar"
      },
      "noInterviewed" : {
        "modaltitle":"Registrar perfil no considerado",
        "modaltitleMultiple":"Registrar perfiles no considerados",
        "modaldescription":"¿Confirmas que decidiste no considerar el perfil para esta búsqueda?",
        "modaldescriptionMultiple":"¿Confirmas que decidiste no considerar los perfiles para esta búsqueda?",
        "modalaction-cancel":"Cancelar",
        "modalaction-setNoInterviewed":"Registrar"
      },
      "jobs":{
        "position":"Posición",
        "dateRange":"Período",
        "no-info":"Sin experiencias laborales registrada",
        "description": "Descripción"
      },
      "studyAcademics":{
        "status":"Estado",
        "dateRange":"Período",
        "statusInProgress":"En progreso",
        "statusFinished":"Finalizado",
        "no-info":"Sin formación académica registrada"
      },
      "studyLanguage":{
        "level":"Nivel",
        "level_basic":"Básico",
        "level_intermediate":"Intermedio",
        "level_advanced":"Avanzado",
        "dateRange":"Período",
        "no-info":"Sin formación de idiomas registrada"
      },
      "games":{
        "no-info":"Sin habilidades registrados",
        "status" : {
          "InProgress":"En progreso",
          "Pending":"Pendiente",
          "Completed":"Completado"
        }
      },
      "contactData":{
        "modalTitle":"Datos de contacto",
        "firstName":"Nombre",
        "lastName":"Apellido",
        "email":"Email",
        "mobilePhone":"Celular",
        "otherPhone":"Teléfono secundario",
        "modalaction_close" : "Cerrar"
      },
      "testForge": {
        "name": "Evaluación de aptitudes Forge",
        "presence": "Presencia",
        "autocritic": "Autocrítica",
        "communication": "Comunicación",
        "empathy": "Empatía",
        "autonomy": "Autonomía",
        "effort": "Esfuerzo",
        "responsability": "Responsabilidad"
      }
    },
    "users": {
      "title":"Gestión de usuarios",
      "firstname":"Nombre",
      "lastname":"Apellido",
      "email":"Email",
      "status":"Estado",
      "statusCode":{
        "Completed":"Aceptada",
        "Pending":"Pendiente"
      },
      "create" : {
        "title" : "Crear usuario",
        "description" : "Completa los siguientes datos y accederás a Ruky",
        "input_firstName" : "Nombre",
        "input_lastName" : "Apellido",
        "input_password" : "Elige la clave que quieras",
        "input_confirmpassword" : "Confirma tu clave",
        "save":"Continuar",
        "cancelled_description":"La invitación ya no se encuentra disponible.",
        "completed_description":"La registración ya se encuentra realizada. Haga click en continuar para iniciar sesión.",
        "input_password_format":"La contraseña debe tener 6 o más caracteres, incluyendo al menos una mayúscula, una minúscula y un número",
        "input_confirmpassword_error":"Las contraseñas no coinciden",
        "continueToLogin":"Continuar"
      },
      "actions":"Acciones",
      "invite" : {
        "modaltitle":"Enviar invitación",
        "input_email":"Email",
        "modalaction-cancel":"Cancelar",
        "modalaction-send":"Enviar"
      },
      "userdelete" : {
        "modaltitle":"Eliminar usuario",
        "modaldescription":"¿Confirma que desea eliminar al usuario?",
        "modalaction-cancel":"Cancelar",
        "modalaction-delete":"Eliminar"
      },
      "invitedelete" : {
        "modaltitle":"Anular invitación",
        "modaldescription":"¿Confirma que desea anular la invitación",
        "modalaction-cancel":"Cancelar",
        "modalaction-delete":"Eliminar"
      },
      "invite-resend" : {
        "modaltitle":"Reenviar invitación",
        "modaldescription":"¿Confirma que desea volver a enviar la invitación"
      }
    },
    "menu":{
      "newjobsearch":"Crear busqueda",
      "jobsearches":"Búsquedas laborales",
      "users":"Gestión de usuarios",
      "logout":"Cerrar sesión",
      "profile":"Perfil",
      "platform":"Plataforma"
    },
    "jobsearch" : {
      "title":"Búsqueda laboral",
      "list" : {
        "title" : "Búsquedas laborales",
        "activeTitle": "Búsquedas laborales"
      },
      "appliesList": {
        "title" : "Postulantes"
      },
      "action" : {
        "publish":"Publicar",
        "unpublish":"Despublicar",
        "close":"Cerrar busqueda"
      },
      "detail" : {
        "remote" : "Remoto",
        "located" : "Presencial",
        "ageRange" : "Rango de edad",
        "workMode" : "Modo de trabajo",
        "address" : "Dirección",
        "area" : "Área",
        "position" : "Posición",
        "creator": "Creado por",
        "editor": "Editado por",
        "description": "Descripción",
        "employmentContractType" : "Tipo de contratación",
        "schedule" : "Horarios",
        "skills":"Habilidades",
        "exclusiveSkills": "Habilidades excluyentes",
        "optionalSkills": "Otras habilidades buscadass",
        "benefits":"Beneficios",
        "createDate": "Fecha de publicación",
        "endDate":"Fecha de cierre",
        "hiredPeople":"Incorporaciones",
        "Closed": "Cerrada",
        "Published": "Abierta",
        "UnPublished": "No publicada",
        "Pending":"Pendiente",
        "RevisionPending":"Revisión pendiente",
        "Rejected":"Rechazada",
        "applyLimit":"Vacantes",
        "Ok": "Postulado",
        "Viewed":"Perfil Visto",
        "Hired": "Seleccionada/o",
        "NotHired": "No Seleccionada/o",
        "NotInterviewed": "No seleccionado",
        "ContactDataRequired": "Datos Solicitados",
        "numberOfApplies": "Cantidad de postulantes"
      },
      "tableheader" : {
        "title" : "Puesto",
        "createDate" : "Fecha creación",
        "publishDate" : "Fecha publicación",
        "endDate" : "Fecha cierre",
        "status" : "Estado",
        "applyLimit": "Vacantes",
        "hired": "Incorporaciones",
        "applied": "Postulantes",
        "contacted": "Datos solicitados",
        "notSelected": "Descartados",
        "publishUser": "Usuario",
        "actions" : "Acciones",
        "firstname" : "Nombre",
        "lastname" : "Apellido",
        "applicant": "Postulante",
        "applyDate":"Fecha aplicación",
        "no-applies":"Aún no hay ningún postulante registrado",
        "applyStatus":"Estado",
        "viewed":"Perfiles revisados",
        "age": "Edad",
        "forgeStars": "Competencias destacadas",
        "averageGamesCompleted": "Tests completados",
        "photo": "Foto"
      },
      "tablerow": {
        "years": "años"
      },
      "create" : {
        "title" : "Búsqueda laboral",
        "description" : "La búsqueda laboral estará disponible para jóvenes entre 18 y 25 años.",
        "input_title" : "Nombre del puesto",
        "input_description" : "Principales responsabilidades",
        "input_jobaddress" : "Dirección",
        "input_isremote" : "¿Es remoto?",
        "input_ageFrom" : "Edad desde",
        "input_ageTo" : "Edad hasta",
        "input_externalActivityUrl" : "Enlace de actividad externa",
        "input_latitude" : "Latitud",
        "input_longitude" : "Longitud",
        "input_publishedStatus" : "Estado",
        "input_jobArea" : "Área",
        "input_jobPosition" : "Tipo de puesto",
        "input_jobPositionOther" : "Nombre del puesto",
        "input_jobSenorities":"Seniority",
        "input_applyLimit":"Cantidad de vacantes",
        "input_employmentContractType" : "Tipo de contratación",
        "input_jobSchedule" : "Horarios",
        "input_country" : "País",
        "input_state" : "Región",
        "input_city" : "Provincia",
        "input_workPlace": "Modalidad",
        "workPlace": {
          "Presential": "Presencial",
          "Remote": "Remoto",
          "Hybrid": "Híbrido"
        },
        "input_educationLevel" : "Nivel educativo",
        "educationLevels": {
          "DoesNotApply": "No aplica",
          "CurrentHighSchool": "Secundario en curso",
          "FinishHighSchool": "Secundario finalizado",
          "CurrentUniversity": "Universitario en curso",
          "FinishUniversity": "Universitario graduado"
        },
        "input_otherBenefithsOffers" : "Otros beneficios",
        "input_skill":"Habilidades",
        "input_benefitOffers":"Beneficios",
        "input_endDate":"Fecha de cierre",
        "input_gender":"Género",
        "input_salaryAround": "Salario en torno a",
        "input_salaryTo": "Hasta",
        "input_salary_invalid": "Ingrese solo números, sin puntos ni comas",
        "input_currency": "Moneda",
        "cloneTitle":"Copia",
        "save" : "Publicar",
        "saveAndNew": "Publicar y crear otra búsqueda",
        "others": "Otros",
        "delete": "Borrar"
      }
    },
    "notifications": {
      "deletedJobSearch": "Búsqueda eliminada correctamente",
      "invalidDeleteJobSearch": "No es posible eliminar búsquedas con postulantes",
      "invalidEndDate": "La fecha de cierre no puede ser menor a la actual",
      "invalidUserForgeMessage": "El mail que ingresaste no coincide con los registrados. ¿Quieres intentarlo de nuevo con otro mail? En caso que no puedas ingresar, puedes comunicarte con {email} para que podamos ayudarte",
      "invalidUser": "Usuario inválido",
      "invalidUserOrPassword": "Usuario o contraseña inválido",
      "userNotAvtivated": "Usuario no activado",
      "userAlreadyExists": "El usuario ya existe",
      "invalidMail": "Email inválido",
      "invalidCode": "Codigo inválido",
      "generic": "Ocurrió un error",
      "cant-login-with-google":"No es posible iniciar con google",
      "updateLogo": "Se cambió el logo correctamente",
      "jobSearch" : {
        "saved" : "Búsqueda guardada",
        "formInvalid" : "Complete todos los campos obligatorios"
      },
      "recoveryPassword":"Contraseña recuperada",
      "user-delete":{
        "deleted":"El usuario fue eliminado",
        "generic-error":"El usuario no pudo ser eliminado"
      },
      "invite-delete" : {
        "deleted":"La invitación ha sido anulada",
        "generic-error":"La invitación no pudo ser anulada"
      },
      "invite" : {
        "sended":"La invitación ha sido enviada",
        "generic-error":"La invitación no pudo ser enviada"
      },
      "apply-hired":{
        "hired":"La contratación ha sido registrada",
        "generic-error":"No se pudo registrar la contratación"
      },
      "apply-noInterviewed" : {
        "noInterviewed":"La postulación ha sido registrada como no seleccionada",
        "generic-error":"La acción no pudo ser procesada"
      },
      "users":{
        "created":"El usuario ha sido registrado"
      },
      "requestError" : {
        "INVITATION_CODE_REDEEMED":"El enlace de la invitación ha caducado"
      }
    },
    "organization": {
      "tributaryCode": "ID Tributario"
    }
  }