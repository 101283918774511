import interceptor from '@/plugins/interceptor';
const pathController = '/Organization'

export default {
  state: () => ({
    
  }),
  mutations: { 
  },
  actions: {
    async organization_organization(){
      const ret = await interceptor.authenticate.get(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}`);
      return ret.data;
    },
    async organization_updateOrganization(_,model){
      try {
        let ret = await interceptor.authenticate.put(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}`,model);
        return ret.data;
      } catch (error) {
        throw error.response.data;
      }
    },
    async organization_locations(){
      const ret = await interceptor.authenticate.get(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/locations`);
      return ret.data;
    },
    async organization_updateLogo(_,img){
      const ret = await interceptor.authenticate.put(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/updateImage`,img);
      return ret.data;
    },
    async organization_setIsCDNPicture(){
      const ret = await interceptor.authenticate.put(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/migratedPicture`);
      return ret.data;
    }
  }
}