<template>
<div class="apply-detail__container">

  <div class="backRoute">
    <a @click="openJobSearchApplies"><i class="material-icons back">arrow_back</i></a>
  </div>
  
  <div v-if="loadedData" class="container apply-container">
    <!-- HEADER -->
    <div class="apply-detail-card apply-detail-card--header">  
      <div class="header-bg"></div>
      <div class="header-main" id="header-main">
        <div class="header-main__image">
          <div class="personal-data__photo">
            <img :src="getImage(applyData.profile.personalInformation.picture, applyData.profile.personalInformation.isPictureFromCDN) || require('@/assets/placeholder.png')">
          </div>
        </div>
        <div class="header-main__data">
          <h3 class="header-main__data__fullname">{{applyData.profile.personalInformation.firstName}} {{applyData.profile.personalInformation.lastName}}</h3>
          <span class="header-main__data__age">{{applyData.profile.personalInformation.age}} años</span>  
        </div>
      </div>
      <div class="header-personal-data" :class="hasVideoInterview ? '':'header-personal-data--no-video-interview'">
        <div class="contact-data" >
          <div class="contact-data__container">
            <div class="contact-data--placeholder" v-if="!applyData.profile.dataRequired">
              <div><i class="material-icons">mail</i><span></span></div>
              <div><i class="material-icons">phone</i><span></span></div>
              <div><i class="material-icons">place</i><span></span></div>
              </div>
            <div v-else class="contact-data--info">
              <div><i class="material-icons">mail</i>{{applyData.profile.contact.email}}</div>
              <div><i class="material-icons">phone</i>{{applyData.profile.contact.mobilePhone}}</div>
              <div><i class="material-icons">place</i>{{applyData.profile.location.country}}</div>
            </div>
          </div>
          <!-- <div class="contact-data__bests"> -->
            <div class="header-personal-data__strength" v-if="aptitudeBestScores != ''">
              <span class="material-icons">thumb_up</span>
              <div>{{applyData.profile.personalInformation.firstName}} se destaca en <b>{{aptitudeBestScores}}</b></div>
            </div>
            <div v-else></div>
            <div class="header-personal-data__strength" v-if="tripPersonalitiesBest != ''">
              <span class="material-icons">psychology</span>
              <div>Su personalidad es <b>{{tripPersonalitiesBest}}</b></div>
            </div>
          <!-- </div> -->
          <!-- <h3>Fortaleza</h3> -->
        </div>
        <div class="video-interview" v-if="hasVideoInterview" >
           <div>
            <h3><span class="material-icons">emoji_people</span> Presentación</h3>
            <template v-for="game in applyData.profile.games" :key="game.code">
              <video v-if="game.code == 'GENOMAVIDEO'" controls>
                <source :src="game.linkResult" type="video/mp4">
                Sorry, your browser doesn't support embedded videos.
              </video>
            </template>
          </div>
        </div>
      </div>
      <div class="actions single-button" v-if="applyData.status != 'NotInterviewed'">
        <button v-if="!applyData.profile.dataRequired" @click="requestContactData()"  class="button button--blue-outline">Solicitar datos</button>
        <button @click="showModalConfirmNoInterviewed()" class="button button--blue-outline">No avanza en el proceso</button>
        <button @click="showModalConfirmHired()" :disabled="applyData.status == 'Hired'" class="button button--blue-outline">{{ $t("apply.setHired") }}</button>
      </div>
    </div>

    <!-- Mi mejor version -->
    <div class="apply-detail-card my-best-version">
      <h3>Mi mejor version <span class="material-icons">star</span></h3>
      <template v-if="applyData.profile.aboutMe.whatMakesMeUnique && applyData.profile.aboutMe.whatMakesMeUnique != ''">
        <div class="my-best-version__whatMakesMeUnique">
          <div class="my-best-version__header">
            <span class="material-icons">comment</span><span class="my-best-version__section-title">¿Qué me hace una <br>persona única?</span>
          </div>
          <p>{{applyData.profile.aboutMe.whatMakesMeUnique}}</p>
        </div>
      </template>

      <!-- Fotos -->
      <template v-if="applyData.profile.aboutMe.photos.length > 0 || applyData.profile.aboutMe.videos.length > 0">
        <div>
          <!-- <span class="my-best-version__section-title">{{ $t("apply.myPhotos") }}</span> -->
          <div class="my-best-version__photos">
            <div class="my-best-version__photos__photo" v-for="(photo,index) in applyData.profile.aboutMe.photos" :key="index" @click="showModalMyBestVersionPicture(index)" >
              <img :src="getImage(photo.picture, true)">
              <span>{{photo.description}}</span>
            </div>
            <template v-for="(video,index) in applyData.profile.aboutMe.videos" :key="index">
              <div class="video" v-if="youtube_parser(video.link)">
                <div class="my-best-version__videos__youtube" >
                  <div class="video-responsive">
                    <iframe :src="youtube_parser(video.link)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                  <span>{{video.description}}</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>

      <!-- Videos -->
      <!-- <template v-if="applyData.profile.aboutMe.videos.length > 0">
        <div>
          <span class="my-best-version__section-title">Mis videos</span>
          <div class="my-best-version__videos">
            <div v-for="(video,index) in applyData.profile.aboutMe.videos" :key="index">
              <div class="video" >
                <div class="my-best-version__videos__youtube" v-if="youtube_parser(video.link)">
                  <div class="video-responsive">
                    <iframe :src="youtube_parser(video.link)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                  <span>{{video.description}}</span>
                </div>
                <a v-else :href="video.link" target="_blank">
                  <span>{{video.description}}</span>
                  <i class="material-icons">open_in_new</i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </template> -->

      <!-- Intereses -->
      <template v-if="applyData.profile.aboutMe.profileInterest.length > 0">
        <div>
          <span class="my-best-version__section-title">Intereses</span>
          <div class="my-best-version__interests">
            <span class="tag" v-for="interest in applyData.profile.aboutMe.profileInterest" :key="interest.id">{{interest.name}}</span>
          </div>
        </div>
      </template>

      <!-- Voluntariado -->
      <template v-if="applyData.profile.aboutMe.volunteerings.length > 0">
        <div>
          <span class="my-best-version__section-title">Voluntariado</span>
          <div class="my-best-version__volunteerings card-container">
            <div class="card" v-for="(volunteering,index) in applyData.profile.aboutMe.volunteerings" :key="index">
              <div class="item">
                <span class="item-title">Nombre</span>
                <span class="item-data">{{volunteering.name}}</span>
              </div>
              <div class="item">
                <span class="item-title">Descripción</span>
                <span v-if="volunteering.description == ''" class="item-data">-</span>
                <span v-else class="item-data">{{volunteering.taskDone}}</span>
              </div>
              <div class="item">                     
                <span  class="item-title">{{$t("apply.jobs.dateRange")}}</span>
                <span class="item-data">{{formatDate(volunteering.fromDate)}} - {{volunteering.toDate != null ? formatDate(volunteering.toDate) : 'Al presente'}}</span>
              </div>
              <div class="item" v-if="volunteering.institution != '' && volunteering.institution != null">
                <span class="item-title">Institución</span>
                <span class="item-data">{{volunteering.institution}}</span>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Herramientas tecnológicas -->
      <template v-if="applyData.profile.aboutMe.technologicalTools.length > 0">
        <div>
          <span class="my-best-version__section-title">Herramientas tecnológicas</span>
          <div class="my-best-version__technologicalTools">
            <span class="tag" v-for="(tool,index) in applyData.profile.aboutMe.technologicalTools" :key="index">{{tool}}</span>
          </div>
        </div>
      </template>

      <!-- Cursos externos -->
      <template v-if="applyData.profile.aboutMe.externalCourses.length > 0 && false">
        <div>
          <span class="my-best-version__section-title">Cursos realizados fuera de Ruky</span>
          <div class="my-best-version__externalCourses card-container">
            <div v-for="(course,index) in applyData.profile.aboutMe.externalCourses" :key="index" class="card">
              <div class="item">
                <span class="material-icons">book</span>
                <span class="item-data">{{course.name}}</span>
              </div>
              <div class="item" v-if="course.certificateLink != ''">
                <span class="material-icons">military_tech</span>
                <span class="item-data" v-if="course.certificateLink != ''">{{course.certificateLink}}</span>
                <span class="item-data" v-else style="text-align: 'center'">-</span>
              </div>
              <div class="item" v-if="course.institution != ''">
                <span class="material-icons">school</span>
                <span class="item-data">{{course.institution}}</span>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Arte y deporte (actividades) -->
      <template v-if="applyData.profile.aboutMe.activities.length > 0">
        <div>
          <span class="my-best-version__section-title">Arte y Deporte</span>
          <div class="my-best-version__activities card-container">
            <div v-for="(activity,index) in applyData.profile.aboutMe.activities" :key="index" class="card">
              <div class="item">
                <span class="item-title">Actividad</span>
                <span class="item-data">{{activity.name}}</span>
              </div>
              <div class="item">
                <span class="item-title">Descripción</span>
                <span class="item-data">{{activity.description}}</span>
              </div>
              <div class="item">                     
                <span  class="item-title">{{$t("apply.jobs.dateRange")}}</span>
                <span class="item-data">{{formatDate(activity.fromDate)}} - {{activity.toDate != null ? formatDate(activity.toDate) : 'Al presente'}}</span>
              </div>
              <div class="item" v-if="activity.link != '' && activity.link != null">
                <span class="item-title">Link</span>
                <span class="item-data">{{activity.link}}</span>
              </div>
              <div class="item">

              </div>
            </div>
          </div>
        </div>
      </template>

    </div>
    
    
    <!-- Competencias (juegos) -->
    
    <!-- Test Forge -->        
    <div class="apply-detail-card game-results result" v-if="applyData.profile.aptitudeScore != null && Object.keys(applyData.profile.aptitudeScore).length > 0">
      <h3>Competencias <span class="material-icons">quiz</span></h3>
      <div v-if="forgeResultsVisible">
        <div class="test-forge" v-if="applyData.profile.aptitudeScore != null && Object.keys(applyData.profile.aptitudeScore).length > 0">
          <div class="test-forge__skills">
            <template v-for="(score,skill) in applyData.profile.aptitudeScore" :key="skill">
              <div class="test-forge__skill">
                <span class="test-forge__skill__name">{{$t(`apply.testForge.${skill}`)}}</span>
                <div class="stars__container">
                  <div class="stars__not-painted">
                    <i class="material-icons" v-for="i in 5" :key="i">radio_button_unchecked</i>
                  </div>
                  <div class="stars__painted" :style="{ width: `${score}%` }">
                    <i class="material-icons" v-for="i in 5" :key="i">radio_button_checked</i>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <span v-else>No hay resultados para mostrar</span>
      </div>
    </div>

    <!-- Trip -->
    <div class="apply-detail-card game-results result" v-if="applyData.profile.tripResult != null">
      <h3>Test de personalidad <span class="material-icons">psychology</span></h3>
      <div v-if="tripResultsVisible">
        <div class="trip-results" v-if="applyData.profile.tripResult != null">
          <div class="test-results">
            <div class="test-results__title">AUTOPERCEPCIÓN</div>
            <div class="test-results__description test-results__description--big">
              <div v-html="formatAutopercepcion(applyData.profile.tripResult.autopercepcion)"></div>
            </div>
          </div>

          <div class="test-results">
            <div class="test-results__title">TENDENCIAS: ANALÍTICO - ESPONTÁNEO</div>
            <div class="grid-tests">
              <div class="score">
                <div class="score__label">
                  <div class="score__label-img">
                    <img src="@/assets/trip-results/trip-icon_analitico.svg" alt="">
                  </div>
                  <div class="score__label-text">ANALÍTICO</div>
                </div>
                <input type="range" class="slider" :value="applyData.profile.tripResult.tendenciaAnaliticoEspontaneoValor" disabled>
                <div class="score__label">
                  <div class="score__label-img" style="margin-bottom: 2px;">
                    <img src="@/assets/trip-results/trip-icon_espontaneo.svg" alt="">
                  </div>
                  <div class="score__label-text">ESPONTÁNEO</div>
                </div>
              </div>
              <div class="test-results__description">
                <div>{{applyData.profile.tripResult.tendenciaAnaliticoEspontaneoTexto}}.</div>
              </div>
            </div>
          </div>

          <div class="test-results">
            <div class="test-results__title">TENDENCIAS: INNOVADOR - APEGO A NORMAS</div>
            <div class="grid-tests">
              <div class="score">
                <div class="score__label">
                  <div class="score__label-img" style="margin-bottom: 4px;">
                    <img src="@/assets/trip-results/trip-icon_innovador.svg" alt="">
                  </div>
                  <div class="score__label-text">INNOVADOR</div>
                </div>
                <input type="range" class="slider" :value="applyData.profile.tripResult.tendenciaInnovadorApegoNormasValor" disabled>
                <div class="score__label">
                  <div class="score__label-img">
                    <img src="@/assets/trip-results/trip-icon_apegoanormas.svg" alt="">
                  </div>
                  <div class="score__label-text">APEGADO A NORMAS</div>
                </div>
              </div>
              <div class="test-results__description">
                <div>{{applyData.profile.tripResult.tendenciaInnovadorApegoNormasTexto}}</div>
              </div>
            </div>
          </div>

          <div class="test-results">
            <div class="test-results__title">TENDENCIAS: PERSONAS - TAREAS</div>
            <div class="grid-tests">
              <div class="score">
                <div class="score__label">
                  <div class="score__label-img" style="margin-bottom: 6px;">
                    <img src="@/assets/trip-results/trip-icon_personas.svg" alt="">
                  </div>
                  <div class="score__label-text">ORIENTADO A PERSONAS</div>
                </div>
                <input type="range" class="slider" :value="applyData.profile.tripResult.tendenciaPersonasTareasValor" disabled>
                <div class="score__label">
                  <div class="score__label-img" style="margin-bottom: 6px;">
                    <img src="@/assets/trip-results/trip-icon_tareas.svg" alt="">
                  </div>
                  <div class="score__label-text">ORIENTADO A TAREAS</div>
                </div>
              </div>
              <div class="test-results__description">
                <div>{{applyData.profile.tripResult.tendenciaPersonasTareasTexto}}</div>
              </div>
            </div>
          </div>

          <div class="grid-tests-custom">
            <div class="test-results">
              <div class="test-results__title">HABILIDADES TÉCNICAS</div>
              <div class="trip-results__habilidad">               
                <div class="trip-results__habilidad-items">
                  <span><span class="material-icons">psychology</span> Prueba de atención y concentración</span>
                  <span :class="{'last': key == 'eficiencia' || key == 'velocidad'}"
                    v-for="(value,key) in applyData.profile.tripResult.atencionConcentracion" :key="key">
                    {{capitalizeWord(key)}} <strong :class="value">{{value}}</strong>
                  </span>
                </div>                
                <div class="trip-results__habilidad-items">
                  <span><i class="material-icons">calculate</i> Prueba de lógica</span>
                  <p>Flexibilidad cognitiva para aprender y adaptarse a situaciones cambiantes-complejas bajo presión.</p>
                  <span>Rendimiento <strong :class="applyData.profile.tripResult.logica.rendimiento">{{applyData.profile.tripResult.logica.rendimiento}}</strong></span>
                </div>                
              </div>
            </div>

            <div class="test-results">
              <div class="test-results__title">CARACTERÍSTICAS DE LA PERSONALIDAD</div>
              <div class="trip-results__personalidad">
                <div class="trip-results__personalidad-img">
                  <img src="@/assets/trip-results/trip-icon_caracteristicas-personalidad.svg">
                </div>
                <div class="trip-results__personalidad-items">
                  <span :class="{'first': index == 0 || index == 1, 'last': index == 5 || index == 6 || index == 7}" 
                  v-for="(item,index) in applyData.profile.tripResult.personalidades" :key="index">
                  {{item.personalidad}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span v-else>No hay resultados para mostrar</span>
      </div>
    </div>

    <!-- Genoma -->
    <div class="apply-detail-card game-results result" v-if="applyData.profile.genomaResults != null">
      <h3 class="genoma-results-collapsible" :class="!genomaResultsVisible ? 'genoma-results-collapsed' : ''">Test de habilidades
        <div v-if="genomaResultsVisible" @click="genomaResultsVisible = false">Ocultar resultados <span class="material-icons">expand_less</span></div>
        <div v-else @click="genomaResultsVisible = true">Mostrar resultados <span class="material-icons">expand_more</span></div>
      </h3>
      <div v-if="genomaResultsVisible">
        <div class="genoma-results" v-if="applyData.profile.genomaResults != null">
          <div class="test-results" v-if="applyData.profile.genomaResults.scores.find(x => ['Disposición al riesgo','Habilidad para reconocer emociones','Duración de la atención','Control de la impulsividad'].indexOf(x.name) >= 0)">
            <div class="test-results__title">RASGOS COGNITIVOS Y EMOCIONALES</div>
            <div class="test-results__description">
              <div>Los rasgos cognitivos y emocionales son características que describen la forma en que las personas procesan información para tener una comprensión del mundo que las rodea, así como la forma en que reconocen sus propias emociones y las de los demás para adaptarse a los entornos, respectivamente.</div> 
              </div>
            <div class="grid-tests grid-tests--50">

            <template v-for="(score, idx) in applyData.profile.genomaResults.scores" :key="idx">
              <template v-if="score.name == 'Disposición al riesgo' 
              || score.name == 'Habilidad para reconocer emociones'
              || score.name == 'Duración de la atención'
              || score.name == 'Control de la impulsividad'
              ">
                <div class="score">
                  <div class="score__title">
                    <span class="score__title-name">{{score.name}}</span>
                    <span class="score__title-score">{{score.score}}%</span>
                  </div>
                  <input type="range" :value="score.score" class="slider" disabled>
                  <div class="score__labels">
                    <span class="score__labels-lowerBound">{{score.lowerBound}}</span>
                    <span class="score__labels-upperBound">{{score.upperBound}}</span>
                  </div>
                </div>
              </template>
            </template>
            </div>
          </div>
          <div class="test-results" v-if="applyData.profile.genomaResults.scores.find(x => ['Razonamiento verbal','Razonamiento lógico','Razonamiento numérico'].indexOf(x.name) >= 0)">
            <div class="test-results__title">HABILIDADES DE RAZONAMIENTO</div>
            <div class="test-results__description">
              <div>Estas habilidades tienen que ver con tu capacidad general para aprender, resolver problemas, entender instrucciones y trabajar con información. Debido a que todos somos distintos, en general cada persona tiene una de estas tres áreas más desarrollada que las otras.</div>
            </div>
            <div class="grid-tests grid-tests--50">
              <template v-for="(score, idx) in applyData.profile.genomaResults.scores" :key="idx">
                <template v-if="score.name == 'Razonamiento verbal' 
                || score.name == 'Razonamiento lógico'
                || score.name == 'Razonamiento numérico'
                ">
                  <div class="score">
                    <div class="score__title">
                      <span class="score__title-name">{{score.name}}</span>
                      <span class="score__title-score">{{score.score}}%</span>
                    </div>
                    <input type="range" :value="score.score" class="slider" disabled>
                    <div class="score__labels">
                      <span class="score__labels-lowerBound">{{score.lowerBound}}</span>
                      <span class="score__labels-upperBound">{{score.upperBound}}</span>
                    </div>
                  </div>
                </template>
              </template>
            </div>
          </div>
          <div class="test-results" v-if="applyData.profile.genomaResults.scores.find(x => ['Apertura a la experienci',
            'Cordialidad','Emocionalidad','Escrupulosidad - Meticulosidad','Extraversión'].indexOf(x.name) >= 0)">
            <div class="test-results__title">PERFIL DE PERSONALIDAD</div>
            <div class="test-results__description">
              <div>La personalidad es el conjunto de características específicas que te diferencian de los demás en términos de tus pensamientos, sentimientos y comportamiento. Tu personalidad está definida por tu genética y por las experiencias que has tenido.</div>
            </div> 
            <div class="grid-tests grid-tests--50">
            <template v-for="(score, idx) in applyData.profile.genomaResults.scores" :key="idx">
              <template v-if="score.name == 'Apertura a la experiencia' 
              || score.name == 'Cordialidad'
              || score.name == 'Emocionalidad'
              || score.name == 'Escrupulosidad - Meticulosidad'
              || score.name == 'Extraversión'
              ">
                <div class="score">
                  <div class="score__title">
                    <span class="score__title-name">{{score.name}}</span>
                    <span class="score__title-score">{{score.score}}%</span>
                  </div>
                  <input type="range" :value="score.score" class="slider" disabled>
                  <div class="score__labels">
                    <span class="score__labels-lowerBound">{{score.lowerBound}}</span>
                    <span class="score__labels-upperBound">{{score.upperBound}}</span>
                  </div>
                </div>
              </template>
            </template>
            </div>
          </div>
        </div>
        <span v-else>No hay resultados para mostrar</span>
      </div>
    </div>
    <!-- Formación académica -->    
    <div class="apply-detail-card studies" v-if="applyData.profile.study.academics.length > 0">
      <h3>{{$t("apply.studyAcademicsInfo")}} <span class="material-icons">school</span></h3>
      <template v-if="applyData.profile.study.academics.length > 0">
        <div class="card-container">
          <div class="card card--study" v-for="academics in applyData.profile.study.academics" :key="academics.id">
              <div class="item">
                <span class="material-icons">
                school
                </span>
                <span class="item-data">{{academics.educationalInstitutionName == '' ? academics.educationalInstitutionOther : academics.educationalInstitutionName}}</span>
              </div>
              <div class="item">
                <span class="material-icons">
                biotech
                </span>
                <span class="item-data" v-if="academics.educationSpecialtyName != '' && academics.educationSpecialtyName != null">{{academics.educationSpecialtyName}}</span>
                <span class="item-data" v-else-if="academics.educationSpecialtyOther != '' && academics.educationSpecialtyOther != null">{{academics.educationSpecialtyOther}}</span>
                <span v-else>-</span>
              </div>
              <div class="item">
                <span class="material-icons">
                date_range
                </span>
                <span class="item-data">{{academics.fromYear}} - {{academics.toYear != null ? academics.toYear : $t("apply.current")}} | {{academics.Finished ? $t("apply.studyAcademics.statusFinished") : $t("apply.studyAcademics.statusInProgress")}}</span>
              </div>
          </div>
        </div>
      </template>
      <div v-if="applyData.profile.study.academics.length == 0">
          {{ $t("apply.studyAcademics.no-info") }}
      </div>
    </div>

    <!-- Experiencia laboral -->      
    <div class="apply-detail-card job" v-if="applyData.profile.jobs.length > 0">
      <h3>{{$t("apply.workInfo")}} <span class="material-icons">work</span></h3>
      <template v-if="applyData.profile.jobs.length > 0">
        <div class="card-container">
          <div class="card card--job" v-for="job in applyData.profile.jobs" :key="job.id">
            <div class="item">                     
              <span class="material-icons">
                badge
                </span>
              <span class="item-data">{{job.positionId != null ? job.positionName : job.positionOther}}</span>
            </div>
            <div class="item">                     
              <span class="material-icons">
                location_city
              </span>
              <span class="item-data">{{job.organizationId != null ? job.organizationName : job.organizationOther}}</span>
            </div>
            <div class="item">                     
              <span class="material-icons">
                date_range
              </span>
              <span class="item-data">{{formatDate(job.from)}} - {{formatDate(job.to)}}</span>
            </div>
          </div>
        </div>
      </template>
      <div v-if="applyData.profile.jobs.length == 0">
          {{ $t("apply.jobs.no-info") }}
      </div>
    </div>

    <ModalConfirmHired v-if="activeModalConfirmHired" :searchId="searchId" :applyId="applyId" @close="closeModalConfirmHired()" @confirm="backRoute()"/>
    <ModalConfirmNoInterviewed v-if="activeModalConfirmNoInterviewed" :searchId="searchId" :applyId="applyId" @close="closeModalConfirmNoInterviewed()" @confirm="backRoute()"/> 
    
    <div class="modal__overlay" v-if="modalContactData" @click.stop="modalContactData = false">
        <div class="modal__contact-data" @click-stop="''">
            <h3>¿Desea solicitar los datos de contacto del postulante?</h3>
            <div class="buttons">
                <button @click.stop="modalContactData = false" class="cancel">Cancelar</button>
                <button @click.stop="requestContactData()">Solicitar</button>
            </div>
        </div>
    </div>

    <div class="modal__overlay" v-if="modalMyBestVersionPicture" @click.stop="modalMyBestVersionPicture = false">
        <div class="modal__contact-data" @click-stop="''">
            <img :src="getImage(applyData.profile.aboutMe.photos[modalMyBestVersionPicturePhotoIndex].picture, true)" alt="">
            <span>{{applyData.profile.aboutMe.photos[modalMyBestVersionPicturePhotoIndex].description}}</span>
            <div class="buttons">
                <button @click.stop="modalMyBestVersionPicture = false" class="cancel">Cerrar</button>
            </div>
        </div>
    </div>
  </div>
  <div v-else class="loading">Cargando...</div>
</div>
</template> 
<script>
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import ModalConfirmHired from '@/components/Apply/ModalConfirmHired';
import ModalConfirmNoInterviewed from '@/components/Apply/ModalConfirmNoInterviewed';

export default {
    components:{
        ModalConfirmHired,
        ModalConfirmNoInterviewed,
    },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter(); 
    const { t } = useI18n();
    const loadedData = ref(false);
    const genomaResultsVisible = ref(false);
    const tripResultsVisible = ref(true);
    const forgeResultsVisible = ref(true);
    const showContactData = ref(false);
    const modalContactData = ref(false);
    const modalMyBestVersionPicture = ref(false);
    const modalMyBestVersionPicturePhotoIndex = ref(null);
    const hasVideoInterview = ref(false);
    const applyData = ref({
      profile:{
        personalInformation : {},
        jobs:[],
        study: {
            academics: [],
            languages:[]
        },
        games : []
      },
      status:''
    }); 
    const activeModalConfirmHired = ref(false)
    const activeModalConfirmNoInterviewed = ref(false)
    const aptitudeBestScores = ref('')
    const tripPersonalitiesBest = ref('')
    const searchId = ref(0)
    const applyId = ref(0)

    function getImage(path, isFromCDN) {
      if (isFromCDN) {
        return path ? `${process.env.VUE_APP_CDN_URL_USER}${path}` : null;
      } 
      return path ? `${process.env.VUE_APP_FORGE_MEDIA_URL}${path}` : null;
    }

    onMounted(async () => {
      try {
        searchId.value = route.params.searchId;
        applyId.value = route.params.applyId;
        const response = await store.dispatch('jobSearch_apply_id', {searchId: route.params.searchId, applyId: route.params.applyId});
        applyData.value = response;
        
        if(applyData.value.profile.aptitudeScore != null && Object.keys(applyData.value.profile.aptitudeScore).length > 0)
        {
          let scoreSorted = Object.entries(applyData.value.profile.aptitudeScore).sort((prev, next) => prev[1] - next[1]).reverse()
          aptitudeBestScores.value=`${t(`apply.testForge.${scoreSorted[0][0]}`)} y ${t(`apply.testForge.${scoreSorted[1][0]}`)}`
          console.log("scoreSorted",scoreSorted, scoreSorted[0][0], scoreSorted[1][0])
        }

        if(applyData.value.profile.tripResult != null && applyData.value.profile.tripResult.personalidades != null && applyData.value.profile.tripResult.personalidades.length > 0)
        {
          tripPersonalitiesBest.value = `${applyData.value.profile.tripResult.personalidades[0].personalidad} y ${applyData.value.profile.tripResult.personalidades[1].personalidad}`
        }

        hasVideoInterview.value = applyData.value.profile.games.length > 0 && 
          applyData.value.profile.games.find( g => g.code == 'GENOMAVIDEO').linkResult != '' && 
          applyData.value.profile.games.find( g => g.code == 'GENOMAVIDEO').linkResult != null && 
          applyData.value.profile.games.find( g => g.code == 'GENOMAVIDEO').showVideo;

        loadedData.value = true;
      } catch (error) {
        store.dispatch('notifications_create', { text: t(`notifications.generic`) });
      }
    });
    
    async function requestContactData(){
        try {
            await store.dispatch('jobSearch_apply_contactData', {searchId: route.params.searchId, applyId: route.params.applyId});
            applyData.value.profile.dataRequired = true;
            modalContactData.value = false;
            contactData();
        } catch (error) {
            store.dispatch('notifications_create', { text: t(`notifications.generic`) });
        }
    }
    function contactData(){
        if(applyData.value.profile.dataRequired == true){
            showContactData.value = true;
        }else{
            modalContactData.value = true;
        }
    }

    

    function formatDate(dateTime){
      if (dateTime == null) {
        return t(`apply.current`)
      }
      return moment(dateTime).format("YYYY");
    }
    
    function showModalConfirmNoInterviewed(){ activeModalConfirmNoInterviewed.value = true }
    function showModalConfirmHired(){ activeModalConfirmHired.value = true }
    function closeModalConfirmHired(){ activeModalConfirmHired.value = false }
    function closeModalConfirmNoInterviewed(){ activeModalConfirmNoInterviewed.value = false }
    function showModalMyBestVersionPicture(index) {
      modalMyBestVersionPicturePhotoIndex.value = index;
      modalMyBestVersionPicture.value = true;
    }
    function closeModalMyBestVersionPicture(){ modalMyBestVersionPicture.value = false }

    function backRoute () {
      if (window.history.length > 2) {
        router.go(-1)
      } else {
        router.push('/')
      }
    }

    function capitalizeWord(word){
      let first = word.charAt(0).toUpperCase();
      let remain = word.slice(1);
      return first+remain;
    }

    function youtube_parser(url){
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        if(match&&match[7].length==11)
          return `https://www.youtube.com/embed/${match[7]}`;
        else 
          return false;
    }

    function formatAutopercepcion(autopercepcion){
      return `Me considero una persona <span class="autopercepcion-ruky">${autopercepcion.replace('Me considero una persona ','')}</span>`
    }

    function openJobSearchApplies () {
      router.push(`/job-searches/${route.params.searchId}/applies`);
    }

    return {
      loadedData,
      applyData,
      backRoute,
      getImage,
      formatDate,
      showModalConfirmNoInterviewed,
      showModalConfirmHired,
      activeModalConfirmHired,
      activeModalConfirmNoInterviewed,
      closeModalConfirmHired,
      closeModalConfirmNoInterviewed,
      searchId,
      applyId,
      genomaResultsVisible,
      tripResultsVisible,
      forgeResultsVisible,
      capitalizeWord,
      youtube_parser,
      contactData,
      requestContactData,
      showContactData,
      modalContactData,
      modalMyBestVersionPicture,
      modalMyBestVersionPicturePhotoIndex,
      showModalMyBestVersionPicture,
      closeModalMyBestVersionPicture,
      aptitudeBestScores,
      formatAutopercepcion,
      tripPersonalitiesBest,
      openJobSearchApplies,
      hasVideoInterview
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vue-skeletor/dist/vue-skeletor.css';

/* VARS */
$mainColor: $blue;
$thinBorder: 1px solid #E0E0E0;
@mixin palanquin-500-mediumgray{
  font-family: $font-palanquin;
  font-weight: 500;
  color: $mediumgray;
}
@mixin palanquin-500-mainColor{
  font-family: $font-palanquin;
  font-weight: 500;
  color: $mainColor;
}
@mixin item{
  height: fit-content;
  min-width: 50%;
  margin: 8px 0;
  display: flex;
  // flex-flow: column nowrap;
  align-items: center;
  gap: 8px;

  &-title{
    font-weight: 600;
    font-size: 14px;
    color: $mediumgray;
  }
  &-data{
    @include palanquin-500-mediumgray;
    font-size: 16px;
  }
}
/*********************************/

.button--main-color {
  background: $mainColor;
  position: fixed;
  right: $margin-base;
  bottom: $margin-base;
  padding: $margin-milli $margin-mega;
  z-index: 13;
  margin: 0;
}

.apply-detail__container{
  width: 100%;
  height: 100%;
  background-color: #F2F2F2;
  padding: 16px;
  .backRoute{
    padding: 4px 0;
    i{
      font-size: 32px;
      line-height: 32px;
    }
  }
}

.loading{
  padding: 12px;
  font-size: 24px;
}

.apply-detail{
  width: 100%;
  padding: 24px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 16px;
  background: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  &__columns{
    display: flex;
    justify-content: center;
    gap: 24px;
    >div{
      width: 50%;
      max-width: 600px;
      display: flex;
      flex-flow: column nowrap;
      gap: 16px;
    }
  }
}

.header{
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  .status {
    color: $white;
    background: $mainColor;
    border-radius: 24px;
    margin: $margin-milli 0;
    padding: 4px 16px!important;
    font-size: 13px;
    width: fit-content;
    text-transform: uppercase;
    font-weight: bold;
  
    &--Completed {
      background: $lightgreen;
    }
  
    &--Pending {
      background: $red;
    }
  
    &--InProgress {
      background: $yellow;
    }
  } 
}

.section-container {
  width: 100%;
  height: fit-content;
  border: $thinBorder;
  border-radius: $margin-small;
  padding: $margin-kilo;

  h3 {
    display: flex;
    align-items: center;
    gap: $margin-milli;
    color: $mainColor!important;
    font-family: $font-palanquin;
    font-size: 18px;
    line-height: 18px;
  }
}

.personal-data {
  display: flex;
  flex-flow: row nowrap;
  &__photo {
    flex-shrink: 0;
    flex-grow: 0;
    width: 140px;
    height: 140px;
    background: $white;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    cursor: pointer;
  }
  &__column2{
    padding-left: 24px;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;
  }
  &__information {
    display: flex;
    flex-flow: row wrap;
    .item{
      @include item;
    }
  }
  .contact-data{
    display: flex;
    flex-flow: column nowrap;
    h3{
      gap: 4px;
      cursor: pointer;
    }
    &__drop-down{
      display: flex;
      flex-flow: row wrap;
      .item{
        @include item;
      }
    }
  }
}

.game-results{
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  .result{
    width: 100%;
    height: fit-content;
    border: $thinBorder;
    border-radius: 6px;
    button{
      width: 100%;
      padding: 18px 22px;
      display: flex;
      align-items: flex-start;
      gap: 6px;
      i{
        line-height: 18px;
        color: $mainColor;
      }
      span{
        @include palanquin-500-mainColor;
      }
    }
    >div{
      padding: 8px;
      padding-bottom: 16px;
      text-align: left;
      font-size: 16px;
    }
  }
}

.card-container {
  // display: flex;
  // flex-flow: column nowrap;
  // gap: 12px;
  
  .card {
    width: 100%;
    background: $white;
    padding: 16px 0;
    border-bottom: $thinBorder;
    border-radius: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // flex-flow: column nowrap;
    gap: 12px;

    &:last-child{
      border: none
    }
    .item{
      @include item;

      .material-icons {
        color: #087B9A;
        font-size: 28px;
      }
    }
  }
}

.my-best-version{
  display: flex;
  flex-flow: column nowrap;
  .card{
    padding: 0;
    border: none;
    border-bottom: $thinBorder;
    border-radius: 0;
    &:last-child{
      border: none;
    }
  }
  >div{
    padding: 16px 0;
    border-bottom: $thinBorder;
  }
  >div:last-child{
    border: none;
  }
  &__section-title{
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 8px;
    color: #087B9A;
    display: block;
  }

  &__whatMakesMeUnique{
    display: grid;
    grid-template-columns: 240px 1fr;
    align-items: center;

    .my-best-version__section-title {
      text-align:center;
    }

    .my-best-version__header {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 12px;

      span {
        color: #087B9A;
        margin-bottom: 0!important;

        &:first-child {
          font-size: 60px;
        }
      }
    }

    p {
      font-size: 16px;
      font-weight: 500;
      color: #202020;
      text-align: left;
      font-style: italic;
      background: #eeeeee;
      padding: 16px 32px;
      border-radius: 4px;
    }
  }

  &__interests{
    padding: 8px 0;
  }
  &__photos{
    padding: 8px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;

    &__photo {
      display: flex;
      flex-direction: column;
      img{
        cursor: pointer;
        object-fit: cover;
        aspect-ratio: 16 / 9;
        width: 100%;
        border: 2px solid #087b9a;
        border-radius: 12px;
        transition: transform .3s ease;

        &:hover {
          border: 4px solid #087b9a;
          transform: scale(1.05);
        }
      } 
      span {
        font-family: $font-opensans;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin: 8px 0px;
      }
    }
  }
  &__videos{
    padding: 8px 0;
    display: grid;
    flex-flow: column nowrap;
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
    
    span{
      @include palanquin-500-mediumgray;
      font-size: 14px;
    } 
    
    &__youtube {
      border-bottom: 1px solid #ccc;
      &:last-child {
        border-bottom: none;
      }
      span {
        font-family: $font-opensans;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        margin: 8px 0px;
      }
    }
    a{
      display: flex;
      align-items: center;
      gap: 4px;
      .material-icons{
        font-size: 16px;
        color: $mediumgray;
      }
    }
       
  }
  &__volunteerings,&__activities{
    padding: 8px 0;
  }
  &__technologicalTools,&__interests{
    padding: 8px 0;
    display: flex;
    flex-flow: row wrap;
    gap: 12px;
    .tag{
      padding: 8px 24px;
      background: #087b9a;
      border-radius: 24px;
      font-weight: 500;
      font-size: 14px;
      color: white!important;
      @include palanquin-500-mediumgray;
      text-transform: capitalize;
    }
  }
  &__externalCourses{
    padding: 8px 0;
  }
}
  
.detail-skeleton-container {
  .vue-skeletor {
    margin-bottom: 8px
  }
}

.test-forge{
  width: 100%;
  display: flex;
  &__skills{
        display: grid;
    flex-flow: column nowrap;
    gap: 16px 80px;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
  &__skill{
    display: grid;
    align-items: center;
    grid-template-columns: 240px 1fr;
    &__name{
      font-family: Palanquin Dark;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: $mediumgray;
    }
  }
  .stars__container{
    position: relative;
    width: 116px;
    height: 24px;
    .stars__not-painted{
      position: absolute;
      width: 100%;    
      display: flex;
      gap: 4px;
      color: #bdbdbd;
      z-index: 1;
    }
    .stars__painted{
      position: absolute;
      display: flex;
      gap: 4px;
      overflow-x: hidden;
      z-index: 2;
      color: #087B9A;
    }
    .stars__not-painted i, .stars__painted i {
      margin: 0 -2px;
    }
  }
}

/* Genoma & Trip*/

.grid-tests-custom {
  width: 100%;
  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr;

  .test-results {
    border-bottom: none!important;
    border-right: 1px solid rgba(130, 130, 130, 0.2);
  }
}

.test-results{
  width: 100%;
  // max-width: 700px;
  &__title{
    padding: 0 0 8px 0;
    margin: 0 0 8px 0;
    text-align: left;
    font-size: 16px;
    color: $mainColor;
    font-weight: 600;
    border-bottom: 1px solid rgb(130, 130, 130,.2);
  }
  &__description{
    padding: 0px 0 20px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    &--big {
      div {
        font-size: 16px!important;
        font-weight: 600!important;
      }
    }
    div{
      flex-grow: 1;
      flex-shrink: 1;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: #404040;
    }
  }

  .grid-tests {
display: grid;
    gap: 40px;
    grid-template-columns: .8fr 1fr;
    align-items: center;

    &--50 {
      gap: 16px 40px;
      grid-template-columns: repeat(2, 1fr);
      align-items: flex-start;
    }
  }
  .score{
    margin-bottom: 25px;
    display: flex;
    flex-flow: column nowrap;
    gap: 12px;
    input[type=range] {
      -webkit-appearance: none;
      width: 100%;
      height: 4px;
      border-radius: 5px;  
      background: rgb(27 127 204 / 23%);
      outline: none;
    }

    input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%; 
      background: $mainColor;
    }

    input[type=range]::-moz-range-thumb {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: $mainColor;
    }
    &__title{
      display: flex;
      justify-content: space-between;
      &-name{
        font-family: "Palanquin Dark", sans-serif;
        font-weight: 500;
        color: #087B9A;
        font-size: 13px;
        line-height: 13px;
        text-transform: uppercase;
      }
      &-score{
        font-family: "Palanquin Dark", sans-serif;
        font-weight: 500;
        color: #087B9A;
        font-size: 15px;
        line-height: 15px;
      }
    }
    &__labels{
      display: flex;
      justify-content: space-between;
      span{
        color: #333;
        font-size: 13px;
        font-family: "Palanquin Dark", sans-serif;
        font-weight: 100;
        font-size: 13px;
        line-height: 18px;
        text-transform: uppercase;
      }
      &-lowerBound{
        text-align: left;
      }
      &-upperBound{
        text-align: right;
      }
    }
  }
}

.genoma-results, .trip-results{
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.trip-results{
  .test-results{
    padding-bottom: 16px;
    border-bottom: 1px solid rgb(130, 130, 130,.2);
    &:last-child{
      border: none;
    }
    &__title{
      margin: 0;
      padding-bottom: 8px;
      text-align: left;
      border: none;
    }
    &__description{
      padding: 0;
    }
  }
  .score{
    width: 100%;
    margin: 0;
    padding-bottom: 8px;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    &__label{
      display: flex;
      flex-direction: column;
      align-items: center;
      &-img{
        width: 50px;
        height: 50px;
      }
      &-text{
        @include palanquin-500-mainColor;
        font-size: 13px;
        line-height: 13px;
        text-align: center;
      }
    }
    .slider{
      flex-grow: 1;
    }
  }
  &__habilidad {
    flex-direction: column!important;;
  }

  &__personalidad {
    align-items: center;
  }

  &__personalidad-items {
    flex-direction: row!important;
    flex-wrap: wrap!important;
    align-items: center;
    justify-content: center!important;
    width: 260px;
    padding-left: 40px;
    transform: scale(1.3);

    >span{
        font-size: 19px;
        line-height: 1.1;
        font-weight: 600;
        color: #189dc2;

        &:nth-child(odd) {
          color: #087B9A;
        }

        &:nth-child(2n) {
          color: #4caf50;
        }

        &:nth-child(3n) {
          color: #8e24aa;
        }

        &.first{
          font-size: 23px;
          font-weight: 700;
        }

        &.last{
          font-size: 15px;
          font-weight: 500;
        }
      }
  } 

  &__personalidad,&__habilidad{
    padding-top: 8px;
    height: calc(100% - 48px);
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;

    &-img{
      height: 170px;
      padding-bottom: 4px;

      img{
        object-fit: contain;
        height: 100%;
      }
    }

    &-items{
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      gap: 4px;

      > span{
        font-size: 17px;
        font-weight: 600;
        color: #404040;

        &:first-child {
          align-items: center;
          display: flex;
          gap: 4px;
        }

        strong {
          color: #8e24aa;

        }

        &.first{
          font-size: 22px;
          font-weight: 700;
        }

        &.last{
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }

  &__personalidad-items {
    span.last{
      font-size: 12px;
      font-weight: 500;
    }
  }

  &__habilidad{

    &-items{
      gap: 8px;
      justify-content: space-between;
      border-radius: 8px;
      padding: 16px;
      margin-right: 16px;
      background: #087b9a17;

      p{
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #404040;
        max-width: 60ch;
      }
    }
  }
}

.modal__contact-data{
  width: 600px;
  padding: 24px;
  display: flex;
  flex-flow: column nowrap;
  gap: 24px;
  background: #fff;
  border-radius: 16px;
  h3{
    text-align: center;
    font-size: 18px;
  }

  img {
    width: fit-content;
    border-radius: 8px;
    max-width: 100%;
  }
  .buttons{
    display: flex;
    justify-content: space-evenly;
    button{
      padding: 8px 16px;
      padding-bottom: 10px;
      border: 1px solid $blue;
      border-radius: 24px;
      font-family: $font-palanquin;
      font-size: 20px;
      line-height: 20px;
      color: $blue;
      &.cancel{
        color: $mediumgray;
        border: 1px solid $mediumgray;
      }
    }
  }  
}

.video-interview{
  h3{
    padding-bottom: 16px;
  }
  video {
    aspect-ratio: 16 / 9;
    background: black;
    width:100%;
    border-radius: 8px;
  }
  a{
    display: flex;
    align-items: center;
    gap: 4px;
    .material-icons{
      font-size: 18px;
      color: $mediumgray;
    }
    span{
      @include palanquin-500-mediumgray;
      font-size: 16px;
    } 
  }
}
/************************************************************** */
.video-responsive {
	aspect-ratio: 16 / 9;
  width: 100%;
  overflow: hidden;
	padding-top: 32px;
	position: relative;
  margin: 0 0px 12px;
  border-radius: 8px;
}
.video-responsive iframe, .video-responsive object, .video-responsive embed {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.apply-container {
  width: 1100px;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .apply-detail-card {
    width: 100%;
    padding: 32px;
    display: flex;
    flex-flow: column;
    // gap: 16px;
    background: $white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    overflow: hidden;
    &--header {
      padding: 0px;
      
      .actions {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
      }
    }

    h3 {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      justify-content: space-between;
      border-bottom: 1px solid #087b9a;
      padding-bottom: 16px;

      span {
        font-size: 32px;
        color: #087B9A;
      }
    }


    .header{
      @keyframes animatedBackground {
        from {
          background-size: cover;
          background-position: 0 top;
        }
        to {
          background-size: cover;
          background-position: -1100px top;
        }
      }

      &-bg {
        background: url(https://www.ruky.org/img/learning-bg.831eff8b.svg), linear-gradient(180deg, #80d8d1, #087B9A);
        background-size: cover;
        background-position: top;
        animation: animatedBackground 15s infinite linear;
        width: 100%;
        height: 175px;
      }
      &-main{
        width:100%;
        display: flex;
        flex-direction: row;
        margin-top: -190px;
        align-items: end;
        .personal-data__photo {
          margin: 40px 30px 0px 30px ;
          height: 200px;
          width: 200px;
          border: 4px solid #fff;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .header-main__data {
          margin-bottom: 75px;
          display: flex;
          flex-direction: column;
          color:#666;
          &__fullname{
            color: #fff;
            font-size: 32px;
            padding: 0;
            border: none;
          }
          &__age {
            font-size: 20px;
            color:#fff;
          }
        }
      }

      &-personal-data {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        gap: 16px;
        padding: 0px 32px 24px;
        margin-top: -16px;
        &--no-video-interview {
          grid-template-columns: 1fr;
          .contact-data {
            display: grid;
            grid-template-columns: 1fr 280px 240px;
            align-items: flex-start;
            gap: 26px;
            .header-personal-data__strength {
              white-space: normal!important;
            }
          }
        }

        .contact-data {
          width: 100%;
          margin-top: 40px;


          div {
            display: flex;
            align-items: center;
            padding: 6px 0px;
            i {
              margin-right: 12px;
            }
          }

          h3 {
            margin: 0px;
            display: flex;
            align-items: center;
            gap: 8px;
            color: #087B9A !important;
            font-family: "Palanquin Dark", sans-serif;
            font-size: 18px;
            line-height: 18px;
            padding-bottom: 16px;
          }
            .header-personal-data__strength {
              gap: 12px;
              margin-top: 8px;
              color: white;
              border-radius: 8px;
              padding: 16px!important;
              width: fit-content;
              background: #087B9A;
              margin-bottom: 16px;
              white-space: nowrap;

              div {
                padding: 0!important;
                gap: 4px;
                display: block!important;;
              }

              span {
                color: white;
              }
            }

          &__container {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            div {
              display: flex;
              flex-direction: column;
            }
          }

          div &--placeholder, div &--info{
            align-items: start;
            div {
              display: flex;
              flex-direction: row;
              span {
                filter: blur(5px);
                background: #ccc;
                height:20px;
              }
              &:nth-child(1) span {
                width: 200px;
              }
              &:nth-child(2) span {
                width: 100px;
              }
              &:nth-child(3) span {
                width: 120px;
              }
            }
          }

          
        }
        .video-interview {
          position: relative;

          h3 {
            margin: 0px;
            display: flex;
            align-items: center;
            gap: 8px;
            color: white;
            font-family: "Palanquin Dark", sans-serif;
            font-size: 18px;
            line-height: 18px;
            position: absolute;
            background: #087B9A !important;
            border-radius: 0 8px 0 8px;
            padding: 8px;
            width: auto;
            right: 0;

            span {
              color: white;
              font-size: 24px;
            }
          }
        }
      }
    }
  }
}

.modal-photo{
  background: #fff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 12px;
  padding: 8px 16px;
  text-align: center;
  width: fit-content;
  max-width: 100%;
  height: fit-content;
  margin-top: 16px;
  z-index: 17;
  position: relative;
  margin-bottom: 24px;
  &__close{
    text-align: right;
    i{
      cursor: pointer;
    }
  }
  img{
    object-fit: contain;
    width: 100%;
    max-width: 600px;
  }
}
.genoma-results-collapsible {
  &.genoma-results-collapsed 
  {
    border-bottom: none!important;
    margin: 0px!important;
    padding: 0px!important;
  }
  > div {
    display: flex;
    color: #087B9A;
    gap: 10px;
    font-size: 20px;
    cursor: pointer;
  }
}
</style>
<style>
.autopercepcion-ruky {
  font-weight: 800;
  color: #8e24aa;
  font-size: 16px;
}

</style>



