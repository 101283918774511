<template>
 <div class="modal__overlay" @click.stop="toggleModal()">
    <div class="modal" @click.stop="''">
      <span class="material-icons" @click.stop="toggleModal()">close</span>
      <h3>{{$t('users.userdelete.modaltitle')}}</h3> 
      <p>{{$t('users.userdelete.modaldescription')}}</p>
      <div class="modal__buttons">
        <button class="button--gray" @click.stop="toggleModal()">{{$t('users.userdelete.modalaction-cancel')}}</button>
        <button @click="deleteUser">{{$t('users.userdelete.modalaction-delete')}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default {
  props: {
    userId: Number
  },
  setup(props, {emit}) {
    const { t }= useI18n()
    const store = useStore()

    const submitted = ref(false);

    function toggleModal() {
      emit('close');
    }

    async function deleteUser() {
      submitted.value = true;
      //todo try send invite
      try {
          const request = await store.dispatch('users_delete' , props.userId);
          if (request.status === 200) {
            toggleModal() 
            store.dispatch('notifications_create', { text: t(`notifications.user-delete.deleted`) });   
            emit('confirm')
          }
        } catch (e) {
         store.dispatch('notifications_create', { text: t(`notifications.user-delete.generic-error`) });
        }
    }

    return {
      submitted,
      deleteUser,
      toggleModal
    }
  }
}
</script>
<style lang="scss" scoped>
  
  .modal__date {
    margin-bottom: $margin-mega;

    input {
      background: none;
      text-align: left;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 2px solid $litedarkgray;
      height: 32px;
      width: 100%;
      font-family: $font-opensans;
      color: $gray;
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 34px;
    }

    p {
      color: $litedarkgray;
      text-align: left;
      margin-bottom: $margin-milli;
    }
  }
</style>