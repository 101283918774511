<template>
  <div class="lx_pulse lx_pulse_blue"></div>
  <div class="login">
    <div class="container">
      <div class="login__container">
        <img src="@/assets/logo.svg" alt="" class="logo-ruky">
        <p>{{ $t("login.description") }}</p>
        <div class="login__grid">
          <SocialRegister />
          <input type="text" @input="onInputPostInvalidLogin" :placeholder="$t('login.input_email')" v-model="username" :class="{'input-invalid': (v$.username.$invalid && submitted) || invalidUserOrPasswordError}">
          <span class="input-message" v-if="v$.username.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.username.required.$message}}</span>
          <span class="input-message" v-if="v$.username.email.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.username.email.$message}}</span>
          
          
          <div class="password_container">
            <input :type="passwordType" @input="onInputPostInvalidLogin" :placeholder="$t('login.input_password')" v-model="password" :class="{'input-invalid': (v$.password.$invalid && submitted) || invalidUserOrPasswordError}">
            <i v-if="passwordType == 'password'" class="material-icons" @click.stop="passwordType = 'text'">visibility</i>
            <i v-else class="material-icons" @click.stop="passwordType = 'password'">visibility_off</i>
          </div>
          
          <span class="input-message" v-if="v$.password.required.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.password.required.$message}}</span>
          <router-link class="login__recover" to="/login/recoveryPassword">{{ $t("login.forgot-password") }}</router-link>
          <button @click="login" class="button button--blue button--register" :disabled="awaitRequest">{{ awaitRequest  ? $t("login.actionDisabled") : $t("login.action") }}</button>   
          <div class="menu-powered-by-forge">
            <a href="https://fondationforge.org/" target="_blank">Powered By Forge</a>
          </div>  
        </div>
      </div>
      <div class="login__image">
        <img src="@/assets/co-workers.svg" alt="">
      </div>
    </div>
  </div>
</template> 
<script>
import { useStore } from 'vuex';
import { onMounted, ref, onBeforeUnmount } from 'vue';
import persistentData from '@/plugins/persistentData';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import SocialRegister from '@/components/Common/SocialRegister.vue';
import useVuelidate from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';

export default {
  components:{
    SocialRegister
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const username = ref("");
    const password = ref("");
    const { t } = useI18n();
    let awaitRequest = ref(false);
    const v$ = useVuelidate();
    const submitted = ref(false);
    let invalidUserOrPasswordError = ref(false);
    const passwordType = ref('password');

    onMounted(async () => {
      await store.dispatch('manageUser_disabled');
    });

    function onKeydown (event) {
      if (event.key === 'Enter') {
        login();
      }
    }

    function onInputPostInvalidLogin () {
      if (invalidUserOrPasswordError.value) {
        invalidUserOrPasswordError.value = false;
      }
    }

    window.addEventListener('keydown', onKeydown);
    onBeforeUnmount(() => {
      window.removeEventListener('keydown', onKeydown);
    })

    async function login () {
      submitted.value = true;
      awaitRequest.value = true;
      if (!v$.value.username.$invalid && !v$.value.password.$invalid) {
        try {
          const request = await store.dispatch('security_authenticate', {username: username.value, password: password.value});
          if (request.status === 200) {
            persistentData.set('token', request.data.token);
            persistentData.set('tokenExpiration', request.data.tokenExpiration);
            persistentData.set('user', JSON.stringify(request.data));
            store.dispatch('manageUser_active');
            router.push('/organization-selection');
            awaitRequest.value = false;
            submitted.value = false;
          }
        } catch (error) {
          if (error.response.data == 'invalidUserOrPassword') {
            invalidUserOrPasswordError.value = true;
          }
          store.dispatch('notifications_create', { text: t(`notifications.${error.response.data}`) });
          awaitRequest.value = false;
          submitted.value = false;
        }
      } else {
        awaitRequest.value = false;
      }
    }

    return {
      username,
      password,
      login,
      onKeydown,
      awaitRequest,
      submitted,
      v$,
      invalidUserOrPasswordError,
      onInputPostInvalidLogin,
      passwordType
    }
  },
  validations () {
    return {
      username: { 
        required: helpers.withMessage('Este campo es requerido', required),
        email: helpers.withMessage('El formato del mail es invalido', email)
      },
      password: { 
        required: helpers.withMessage('Este campo es requerido', required)
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.password_container{
  margin-bottom: 16px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  .material-icons{
    position: absolute;
    top: 50%;
    right: 0;
    padding-right: 8px;
    transform: translateY(-50%);
    color: $blue;
    font-size: 28px;
    cursor: pointer;
  }
  input{
    margin: 0;
    flex-grow: 1;
    
  }
}
input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus{
  transition: background-color 5000s ease-in-out 0s;
  font-size: 16px !important;
}
</style>