{
  "home": {
    "title": "¡El trabajo de tus sueños te está esperando!",
    "login": "INGRESAR"
  },
  "login": {
    "title": "¡HOLA!",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    "input_email": "Mail ",
    "input_password": "Contraseña",
    "forgot-password": "Olvidé mi contraseña",
    "login-with":"Ingresar con:",
    "action": "INGRESAR"
  }
}