<template>
  <div class="notifications">
    <template v-for="(notification, idx) in listNotifications" :key="idx">
      <Notification :notification="notification"/>
    </template>
  </div>
</template>

<script>
import Notification from '@/components/Common/Notifications/Notification.vue';
import { computed } from '@vue/runtime-core';
import { useStore } from 'vuex';

export default {
  components: {
    Notification
  },
  setup() {
    const store = useStore();
    const listNotifications = computed(() => store.getters.notifications_list);

    return {
      listNotifications
    }
  }
};
</script>

<style lang="scss">
.notifications {
  position: fixed;
  bottom: 16px;
  left: 84px;
  padding: 0 $margin-base;
  z-index: 15000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
</style>
