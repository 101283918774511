<template>
  <div class="container main-container">
    <h1>Plataforma</h1>
  </div>
</template> 
<script>
export default {
  setup() {
   
    return {
    }
  }
}
</script>