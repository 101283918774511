import interceptor from '@/plugins/interceptor';
const pathController = '/OrganizationUser'

export default {
  state: () => ({
    
  }),
  mutations: { 
  },
  actions: {
  
    async users_delete(_,id){
      return await interceptor.authenticate.delete(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/${id}`);      
    },
    //async users_search(_,model){
    async users_search(){
      const ret = await interceptor.authenticate.get(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}`);
      return ret.data;
    },
    async users_edit(_,model){
      return await interceptor.authenticate.put(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}`,model);
    },
    async users_invite(_,model){
      return await interceptor.authenticate.post(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}`,model);
    },
    async users_deleteUserInvite(_,id){
      return await interceptor.authenticate.put(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/invitation/cancel/${id}`);
    },
    async users_resendUserInvite(_,id){
      return await interceptor.authenticate.post(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/invitation/resend/${id}`);
    },
  }
}