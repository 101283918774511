<template>
  <div>
    <div class="container__table">
      <h3><a @click="backRoute()"><i class="material-icons back">arrow_back</i></a>{{ $t("jobsearch.create.title") }}</h3>
      <div class="new-search">
        <p class="new-search__alert"><i class="material-icons">import_export</i> {{ $t("jobsearch.create.description") }}</p>
        <div class="new-search__table"  v-if="loadedData">
          <div class="new-search__table__card new-search__table__card--outline">
            <div :class="{ 'has-error': v$.jobSearchModel.title.$invalid && submitted }" >
              <h4>{{ $t("jobsearch.create.input_title") }}</h4>
              <input type="text" @input="assertMaxChars('title', titleCharLimit)" autocomplete="off" v-model="jobSearchModel.title" />
              <span class="textarea-charcount">{{titleCharLimit - jobSearchModel.title.length}}</span>
              <span class="input-message" v-if="v$.jobSearchModel.title.$invalid && submitted"><i class='material-icons'>error_outline</i> {{v$.jobSearchModel.title.containsCopia.$message}}</span>
            </div>
            <div :class="{ 'has-error': v$.jobSearchModel.endDate.$invalid && submitted }" >
              <label>{{ $t("jobsearch.create.input_endDate") }}</label>
              <input type="date" autocomplete="off" v-model="jobSearchModel.endDate" :min="minEndDate"/>
            </div>
            <div :class="{ 'has-error': v$.jobSearchModel.applyLimit.$invalid && submitted }" >
              <label>{{ $t("jobsearch.create.input_applyLimit") }}</label>
              <input type="number" autocomplete="off" v-model.number="jobSearchModel.applyLimit" />
            </div>
          </div>
          
          <div class="new-search__table__card">
            <h3>Detalles del puesto</h3>

            <div :class="{ 'has-error': v$.jobSearchModel.jobPositionId.$invalid && submitted }" >
              <label>{{ $t("jobsearch.create.input_jobPosition") }}</label>
              <Multiselect v-model="jobSearchModel.jobPositionId" :options="creationOptionsPage.jobPositions" 
                  :placeholder="$t('jobsearch.create.input_jobPosition')" trackBy="name" valueProp="id" label="name" 
                  :closeOnSelect="true" :searchable="true">
              </Multiselect>
            </div>

            <div v-if="jobSearchModel.jobPositionId === 0" :class="{ 'has-error': v$.jobSearchModel.positionOther.$invalid && submitted }">
              <label>{{ $t("jobsearch.create.input_jobPositionOther") }}</label>
              <input type="text" v-model="jobSearchModel.positionOther" />
            </div>

            <div>
              <label>{{ $t("jobsearch.create.input_jobSenorities") }}</label>
              <select v-model="jobSearchModel.jobSeniorityId">
                <option disabled selected :value="null"> {{ $t("jobsearch.create.input_jobSenorities") }} </option>
                <option v-for="ja in creationOptionsPage.jobSenorities" :key="ja.id" :value="ja.id" > {{ ja.name }} </option>
              </select>
            </div>

            <div>
              <label>{{ $t("jobsearch.create.input_gender") }}</label>
              <select v-model="jobSearchModel.genderId">
                <option disabled selected :value="null"> {{ $t("jobsearch.create.input_gender") }} </option>
                <option v-for="ja in creationOptionsPage.genders" :key="ja.id" :value="ja.id" > {{ ja.name }} </option>
              </select>
            </div>

            <div>
              <label>{{ $t("jobsearch.create.input_educationLevel") }}</label>
              <select v-model="jobSearchModel.minimumEducationLevel">
                <option disabled selected :value="0"> {{ $t("jobsearch.create.input_educationLevel") }} </option>
                <option v-for="ja in creationOptionsPage.educationLevels" :key="ja.key" :value="ja.key" > {{ $t(`jobsearch.create.educationLevels.${ja.value}`) }} </option>
              </select>
            </div>

            <div :class="{ 'has-error': v$.jobSearchModel.employmentContractTypeId.$invalid && submitted }" >
              <label>{{ $t("jobsearch.create.input_employmentContractType") }}</label>
              <select v-model="jobSearchModel.employmentContractTypeId">
                <option disabled selected value="">{{ $t("jobsearch.create.input_employmentContractType") }} </option>
                <option v-for="ja in creationOptionsPage.employmentContractTypes" :key="ja.id" :value="ja.id" >{{ ja.name }}</option>
              </select>
            </div>

            <div :class="{ 'has-error': v$.jobSearchModel.jobScheduleId.$invalid && submitted }" >
              <label>{{ $t("jobsearch.create.input_jobSchedule") }}</label>
              <select v-model="jobSearchModel.jobScheduleId">
                <option disabled selected value="">{{ $t("jobsearch.create.input_jobSchedule") }} </option>
                <option v-for="ja in creationOptionsPage.jobShcedules" :key="ja.id" :value="ja.id">{{ ja.name }}</option>
              </select>
            </div>

            <div class="new-search__textArea-description" :class="{ 'has-error': v$.jobSearchModel.description.$invalid && submitted }">
              <label>{{ $t("jobsearch.create.input_description") }}</label>
              <textarea v-model="jobSearchModel.description" @input="assertMaxChars('description', descriptionCharLimit)" :placeholder="$t('jobsearch.create.input_description')" autocomplete="off" ></textarea>
              <span class="textarea-charcount">{{descriptionCharLimit - jobSearchModel.description.length}}</span>
            </div>

            <!-- <div class="checkbox">
              <input type="checkbox" id="checkbox" v-model="jobSearchModel.isRemote" autocomplete="off" />
              <label for="checkbox">{{ $t("jobsearch.create.input_isremote") }}</label>
            </div> -->

            <div :class="{ 'has-error': v$.jobSearchModel.workPlace.$invalid && submitted }" >
              <label>{{ $t("jobsearch.create.input_workPlace") }}</label>
              <select v-model="jobSearchModel.workPlace">
                <option disabled selected value=null>{{ $t("jobsearch.create.input_workPlace") }} </option>
                <option v-for="ja in creationOptionsPage.workPlace" :key="ja.key" :value="ja.key">{{ $t(`jobsearch.create.workPlace.${ja.value}`) }}</option>
              </select>
            </div>

          </div>

          <div class="new-search__table__card">
            <h3>Ubicación</h3>
            <div :class="{ 'has-error': v$.jobSearchModel.countryId.$invalid && submitted }" >
              <label>{{ $t("jobsearch.create.input_country") }}</label>
              <select v-model="jobSearchModel.countryId" @change="onChangeCountry">
                <option disabled selected :value="null">{{ $t("jobsearch.create.input_country") }} </option>
                <option v-for="ja in creationOptionsPage.countries" :key="ja.id" :value="ja.id">{{ ja.name }}</option>
              </select>
            </div>

            <div :class="{ 'has-error': v$.jobSearchModel.stateId.$invalid && submitted }" >
              <label>{{ $t("jobsearch.create.input_state") }}</label>
              <select v-model="jobSearchModel.stateId" @change="onChangeState">
                <option disabled selected :value="null">{{ $t("jobsearch.create.input_state") }}</option>
                <option v-for="ja in statesOptions" :key="ja.id" :value="ja.id" >{{ ja.name }}</option>
              </select>
            </div>

            <div :class="{ 'has-error': v$.jobSearchModel.cityId.$invalid && submitted }" >
              <label>{{ $t("jobsearch.create.input_city") }}</label>
              <select v-model="jobSearchModel.cityId">
                <option disabled selected :value="null">{{ $t("jobsearch.create.input_city") }}</option>
                <option v-for="ja in citiesOptions" :key="ja.id" :value="ja.id" >{{ ja.name }}</option>
              </select>
            </div>

            <div :class="{ 'has-error': v$.jobSearchModel.jobAddress.$invalid && submitted }" >
              <label>{{ $t("jobsearch.create.input_jobaddress") }}</label>
              <input type="text" @input="assertMaxChars('jobAddress', jobAddressCharLimit)" autocomplete="off" v-model="jobSearchModel.jobAddress" />
              <span class="textarea-charcount">{{jobAddressCharLimit - jobSearchModel.jobAddress.length}}</span>
            </div>
          </div>

          <div class="new-search__table__card">
            <h3>Otros datos</h3>

            <div v-for="skill in creationOptionsPage.exclusiveSkills" :key="skill.type" class="exclusive-skill">
              <div :class="{ 'has-error': v$.jobSearchModel.exclusiveSkills.preferredPosition.$invalid && submitted }">
                <label v-if="skill.type=='preferredPosition'"  >Para este puesto preferimos perfiles</label>
              </div>
              <div :class="{ 'has-error': v$.jobSearchModel.exclusiveSkills.successProfiles.$invalid && submitted }">
                <label v-if="skill.type=='successProfiles'" :class="{ 'has-error': v$.jobSearchModel.exclusiveSkills.successProfiles.$invalid && submitted }">Creemos que pueden tener más éxito los perfiles</label>
              </div>
              <div :class="{ 'has-error': v$.jobSearchModel.exclusiveSkills.prioritizedProfiles.$invalid && submitted }">
                <label v-if="skill.type=='prioritizedProfiles'" :class="{ 'has-error': v$.jobSearchModel.exclusiveSkills.prioritizedProfiles.$invalid && submitted }">Nos gustaría priorizar los perfiles</label>
              </div>
                
              <div  class="exclusive-skill__options">
                <template v-for="option in skill.options" :key="option.id">
                  <div class="exclusive-skill__radio-container"> 
                    <input :id="option.id" type="radio" v-model="jobSearchModel.exclusiveSkills[skill.type]" :value="option.id">
                    <label :for="option.id">{{option.name}}</label>
                  </div>
                </template>
              </div>
            </div>
          
            <div :class="{ 'has-error': v$.jobSearchModel.optionalSkills.$invalid && submitted}">
              <label>{{ $t("jobsearch.detail.optionalSkills") }}</label>
              <Multiselect v-model="jobSearchModel.optionalSkills" :options="creationOptionsPage.optionalSkills" 
                :placeholder="$t('jobsearch.detail.optionalSkills')" trackBy="name" valueProp="id" label="name" 
                :searchable="true" mode="tags" :closeOnSelect="false">
              </Multiselect>
            </div>

            <div :class="{ 'has-error': v$.jobSearchModel.benefitOffers.$invalid && submitted}">
              <label>{{ $t("jobsearch.create.input_benefitOffers") }}</label>
              <Multiselect v-model="jobSearchModel.benefitOffers" :options="creationOptionsPage.employmentBenefits"
                :placeholder="$t('jobsearch.create.input_benefitOffers')" mode="tags" trackBy="name" valueProp="id" 
                label="name" :closeOnSelect="false" :searchable="true">
              </Multiselect>
            </div>

            <div class="new-search__textArea-description" :class="{ 'has-error': v$.jobSearchModel.otherBenefitOffers.$invalid && submitted }" >
              <label>{{ $t("jobsearch.create.input_otherBenefithsOffers") }}</label>
              <textarea v-model="jobSearchModel.otherBenefitOffers" @input="assertMaxChars('otherBenefitOffers', benefitCharLimit)" :placeholder="$t('jobsearch.create.input_otherBenefithsOffers')" autocomplete="off" ></textarea>
              <span class="textarea-charcount">{{benefitCharLimit - jobSearchModel.otherBenefitOffers.length}}</span>
            </div>
          </div>

          <div class="new-search__table__card">
            <h3>Salario</h3>

              <div :class="{'has-error': v$.jobSearchModel.salaryAround.$invalid && submitted}">
                <label>{{ $t("jobsearch.create.input_salaryAround") }}</label>
                <input type="text" autocomplete="off" oninput="this.value = this.value.replace(/[^0-9]/g, '');" onchange="this.value = (this.value == ''? '0':this.value)" v-model.number="jobSearchModel.salaryAround" />
                <span class="input_error">{{$t('jobsearch.create.input_salary_invalid')}}</span>
              </div>

              <div>
                <label>{{ $t("jobsearch.create.input_currency") }}</label>
                <select v-model="jobSearchModel.currencyCode">
                  <option disabled selected :value="null">{{ $t("jobsearch.create.input_currency") }}</option>
                  <option v-for="ja in creationOptionsPage.currencyCodes" :key="ja.code" :value="ja.code" > {{ ja.code }} ({{ ja.alias }})</option>
                </select>
              </div>
          </div>

        <div class="new-search__actions new-search__actions--fixed" :class="{ 'single-button': jobSearchModel.id == 0 }" >
          <div class="new-search__actions__group" v-if="jobSearchModel.id != 0">
            <a v-if="jobSearchModel.id != 0 && jobSearchModel.publicationStatus == 2" class="button button--blue-outline"
                @click="toggleModalUnpublishConfirm()">{{ $t("jobsearch.action.unpublish") }}</a> 
            <a v-if="jobSearchModel.id != 0 && jobSearchModel.publicationStatus != 4" class="button button--blue-outline"
                @click="toggleModalCloseConfirm()">{{ $t("jobsearch.action.close") }}
            </a>
          </div>
          <div class="new-search__actions__group">
            <button v-if="editMode" @click="modalConfirmDelete=true" class="button button--blue-outline">{{ $t("jobsearch.create.delete") }}</button>
            <div class="button-select">
              <div class="button-select__options" :class="{'block': buttonOptions}">
                <button @click="saveJobSearch(false)" :disabled="saving">{{ $t("jobsearch.create.save") }}</button>
                <button @click="saveJobSearch(true)" :disabled="saving">{{ $t("jobsearch.create.saveAndNew") }}</button>
              </div>
              <button class="button button--blue button-select__button" @click="buttonOptions = !buttonOptions" :disabled="saving">
                {{ $t("jobsearch.create.save") }}
                <i class='material-icons'>{{buttonOptions ? 'expand_less' : 'expand_more'}}</i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!loadedData" class="skeletor">
        <div v-for="i in 8" :key="i"><Skeletor width="100%" height="24"/></div>
      </div>
    </div>
    <ModalPublish v-if="activeModalPublish" @publish="jobSearchPublish" @closeModalPublish="toggleModalPublish" />
    <ModalConfirm v-if="activeModalUnpublishConfirm" :message="'¿Estas seguro que quieres despublicar?'" @confirm="jobSearchUnpublish" @closeModalConfirm="toggleModalUnpublishConfirm" />

    <ModalConfirm v-if="activeModalCloseConfirm" :message="'¿Estas seguro que quieres cerrar la busqueda?'" @confirm="jobSearchClose" @closeModalConfirm="toggleModalCloseConfirm" />
    <ModalConfirm v-if="activeModalBackConfirm" :message="'Si vuelves atras, los cambios realizados no serán guardados. Seguro que deseas volver?'" @confirm="forceBackRoute" @closeModalConfirm="toggleModalBackConfirm" />
    
    <div class="modal__overlay" v-if="modalConfirmDelete" @click.stop="modalConfirmDelete=false">
      <div class="modal modal-confirmDelete" @click.stop="''">
        <p>¿Está seguro que quiere borrar esta búsqueda?</p>
        <div class="buttons">
          <button class="button cancel" @click.stop="modalConfirmDelete=false">Cancelar</button>
          <button class="button" @click.stop="deleteJobSearch()">Aceptar</button>
        </div>
      </div>
    </div>

    <div class="modal__overlay" v-if="modalCantDelete" @click.stop="modalCantDelete=false">
      <div class="modal modal-cantDelete" @click.stop="''">
        <p>¡No es posible borrar una búsqueda con candidatos postulados!</p>
        <button class="button" @click.stop="modalCantDelete=false">Aceptar</button>
      </div>
    </div>
    
  </div>
  </div>
</template> 
<script>
  import { useStore } from 'vuex';
  import { computed, onMounted, ref } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import moment from 'moment';
  import useVuelidate  from '@vuelidate/core';
  import Multiselect from '@vueform/multiselect'
  import { required, minLength, minValue, requiredIf, helpers } from '@vuelidate/validators';
  import ModalPublish from '@/components/Job/ModalPublish';
  import ModalConfirm from '@/components/Job/ModalConfirm';
  import { useI18n } from 'vue-i18n';
  import { Skeletor } from 'vue-skeletor';

export default {
  components: {
    ModalPublish,
    ModalConfirm,
    Multiselect,
    Skeletor
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const submitted = ref(false);
    const v$ = useVuelidate();
    const { t } = useI18n();
    const loadedData = ref(false);
    const buttonOptions = ref(false);
    const user = computed(() => store.getters.manageUser_user);
    const modalConfirmDelete = ref(false);
    const saving = ref(false);
    const organization = computed(() => {
      const organizationId = store.getters.manageUser_organizationId
      return user.value.organizations.find(o => o.organizationId == organizationId);
    })
    const minEndDate = ref();
    minEndDate.value = moment().add(1, 'days').format("YYYY-MM-DD");
    const modalCantDelete = ref(false);

//JobSearch Model
    const jobSearchModel = ref({
      id : 0,
      publishDate: "2021-08-25T21:15:16.833Z",
      endDate: "",
      title: '',
      description: '',
      jobAddress: '',
      workPlace: null,
      otherBenefitOffers: '',
      ageFrom: 0,
      ageTo: 0,
      externalActivityUrl: '',
      latitude: 0,
      longitude: 0,
      publicationStatus: 0,
      jobAreaId: null,
      jobPositionId: null,
      positionOther: '',
      jobSeniorityId:1,
      employmentContractTypeId: null,
      jobScheduleId: null,
      countryId: null,
      stateId: null,
      cityId: null,
      minimumEducationLevel: 0,
      neededSkills: [],
      exclusiveSkills: {
        preferredPosition: null,
        successProfiles: null,
        prioritizedProfiles: null
      },
      optionalSkills: [],
      benefitOffers: [],
      applyLimit:1,
      genderId: null,
      salaryAround: 0,
      currencyCode: null,
    }); 
//_end_
    const defaultModel = ref({});
    defaultModel.value = JSON.parse(JSON.stringify(jobSearchModel.value));

//Clean JobSearch Model
    const cleanJobSearchModel = ()=>{
      jobSearchModel.value = {
        id : 0,
        publishDate: "2021-08-25T21:15:16.833Z",
        endDate: "",
        title: '',
        description: '',
        jobAddress: '',
        workPlace: null,
        otherBenefitOffers: '',
        ageFrom: 0,
        ageTo: 0,
        externalActivityUrl: '',
        latitude: 0,
        longitude: 0,
        publicationStatus: 0,
        jobAreaId: null,
        jobPositionId: null,
        positionOther: '',
        jobSeniorityId:1,
        employmentContractTypeId: null,
        jobScheduleId: null,
        countryId: null,
        stateId: null,
        cityId: null,
        minimumEducationLevel: 0,
        neededSkills: [],
        exclusiveSkills: {
          preferredPosition: null,
          successProfiles: null,
          prioritizedProfiles: null
        },
        optionalSkills: [],
        benefitOffers: [],
        applyLimit:1,
        genderId: null,
        salaryAround: 0,
        currencyCode: null
      }
    }; 

    //const editMode = typeof(route.params.id) != 'undefined' && route.params.id != null;
    let editMode = route.name == 'JOB_SEARCHES_EDIT';
    let cloneMode = route.name == 'JOB_SEARCHES_CLONE';
    
    // Validator
    const validateTitleInCloneMode = (title)  => {
      title = title.toLowerCase();
      const cloneTitle = `${t(`jobsearch.create.cloneTitle`)}`;
      return !(cloneMode && title.search(cloneTitle.toLowerCase()) > -1)      
    }
    
    const creationOptionsPage = ref({});
    const descriptionCharLimit = 4000;
    const benefitCharLimit = 500;
    const titleCharLimit = 280;
    const jobAddressCharLimit = 100;
    const employmentBenefitsOptions = ref([]);

    const statesOptions = ref([]);
    const citiesOptions = ref([]);

    async function onChangeCountry() {
      if (jobSearchModel.value.countryId) {
        jobSearchModel.value.currencyCode = creationOptionsPage.value.countries.find(country => country.id == jobSearchModel.value.countryId).codeCurrency;
        
        statesOptions.value = creationOptionsPage.value.states.filter(state => state.countryId == jobSearchModel.value.countryId);
      } else {
        statesOptions.value = [];
      }
      jobSearchModel.value.cityId = null;
      jobSearchModel.value.stateId = null;
    }

    async function onChangeState() {
      if (jobSearchModel.value.countryId) {
        citiesOptions.value = creationOptionsPage.value.cities.filter(city => city.stateId == jobSearchModel.value.stateId)
      } else {
        citiesOptions.value = [];
        jobSearchModel.value.cityId = null;
      }
    }

    onMounted(async () => {
      const creationOptions = await store.dispatch('organization_creationOptions');
      creationOptionsPage.value = creationOptions;
      creationOptionsPage.value.jobPositions.push({id: 0, name: `${t(`jobsearch.create.others`)}`});
      
      if(editMode || cloneMode){
        try {
          jobSearchModel.value = await store.dispatch('organization_jobSearch_id', {id: route.params.id});
        } catch (error) {
          store.dispatch('notifications_create', { text: t(`notifications.generic`) });
        }
      }
      else{
        jobSearchModel.value.genderId = creationOptionsPage.value.genders.find(gender => gender.name.toLowerCase() == t("apply.indistinctGender").toLowerCase()).id;
        jobSearchModel.value.countryId = organization.value.country.id
        jobSearchModel.value.currencyCode = creationOptionsPage.value.countries.find(country => country.id == jobSearchModel.value.countryId).codeCurrency;
      }
        defaultModel.value = JSON.parse(JSON.stringify(jobSearchModel.value));

      if(cloneMode)
      {
        //prepare cloned object
        jobSearchModel.value.id = 0;
        jobSearchModel.value.publishDate= null;
        jobSearchModel.value.publicationStatus = 0;
        jobSearchModel.value.title = `${jobSearchModel.value.title} - ${t(`jobsearch.create.cloneTitle`)}`;
      }
      statesOptions.value = creationOptionsPage.value.states.filter(state => state.countryId == jobSearchModel.value.countryId);
      citiesOptions.value = creationOptionsPage.value.cities.filter(city => city.stateId == jobSearchModel.value.stateId);
      loadedData.value = true;
    });


    async function saveJobSearch(newJobSearch) {
      submitted.value = true;
      if (!v$.value.jobSearchModel.$invalid) {
        try {
          saving.value = true;
          if(jobSearchModel.value.salaryAround == null) jobSearchModel.value.salaryAround = 0;        
          const request = await store.dispatch((editMode && !cloneMode ? 'organization_updateJobSearch' : 'organization_saveJobSearch'), jobSearchModel.value);
          if (request.status === 200) {
            store.dispatch('notifications_create', { text: t(`notifications.jobSearch.saved`) });
            if(newJobSearch){
              cleanJobSearchModel();
              submitted.value = false;
              editMode = false;
              cloneMode = false;
              /* window.scrollTo(0,0); */
              router.push('/job-searches/new');
            }else{
              forceBackRoute();
            }   
          }      
        } catch (error) {
          if (error.response.data == "EndDate cant be lower than today") {
            store.dispatch('notifications_create', { text: t(`notifications.invalidEndDate`) });
          } else {
            store.dispatch('notifications_create', { text: t(`notifications.generic`) });
          }
        }
        saving.value = false;
      }else {
        store.dispatch('notifications_create', { text: t(`notifications.jobSearch.formInvalid`) });
      }
    }

    async function deleteJobSearch() {
      try {
        const request = await store.dispatch('jobSearch_delete', {searchId: jobSearchModel.value.id});
        if (request.status == 200) {
          store.dispatch('notifications_create', { text: t(`notifications.deletedJobSearch`) });
          router.push('/job-searches/actives');
        }
      } catch (error) {
        if (error.response.data == "JobSearch has applies") {
          //store.dispatch('notifications_create', { text: t(`notifications.invalidDeleteJobSearch`) });
          modalCantDelete.value = true;
        } else {
          store.dispatch('notifications_create', { text: t(`notifications.generic`) });
        }
      }
      modalConfirmDelete.value = false;
    }

    async function saveAndPublishJobSearch() {
      submitted.value = true;
      if (!v$.value.jobSearchModel.$invalid) {
        try {
          if(jobSearchModel.value.salaryAround == null) jobSearchModel.value.salaryAround = 0;
          const request = await store.dispatch((editMode && !cloneMode ? 'organization_updateJobSearch' : 'organization_saveJobSearch'), jobSearchModel.value);
          if (request.status === 200) {
            const model = {
              id: jobSearchModel.value.id,
              publishDate : (new Date())
            };
            try {
              const requestPublish = await store.dispatch('organization_jobSearch_publish', model);
              if (requestPublish.status === 200) {
                jobSearchModel.value = await store.dispatch('organization_jobSearch_id', {id: route.params.id});
                  toggleModalPublish()
                  store.dispatch('notifications_create', { text: 'publicado' });
                  forceBackRoute();
              }
            } catch (e) {
              store.dispatch('notifications_create', { text: t(`notifications.generic`) });
            } 
          }
        } catch (error) {
          if (error.response.data == "EndDate cant be lower than today") {
            store.dispatch('notifications_create', { text: t(`notifications.invalidEndDate`) });
          } else {
            store.dispatch('notifications_create', { text: t(`notifications.generic`) });
          }
        }
      }else {
        store.dispatch('notifications_create', { text: t(`notifications.jobSearch.formInvalid`) });
      }
    }

//Modal shows
      const activeModalPublish = ref(false);
      function toggleModalPublish() {
        activeModalPublish.value = !activeModalPublish.value;
      }
      const activeModalUnpublishConfirm = ref(false);
      function toggleModalUnpublishConfirm() {
        activeModalUnpublishConfirm.value = !activeModalUnpublishConfirm.value;
      }
      
      const activeModalCloseConfirm = ref(false);
      function toggleModalCloseConfirm() {
        activeModalCloseConfirm.value = !activeModalCloseConfirm.value;
      }

      const activeModalBackConfirm = ref(false);
      function toggleModalBackConfirm() {
        activeModalBackConfirm.value = !activeModalBackConfirm.value;
      }     

//JobSearch status control
      async function jobSearchPublish (datePublish) {
        const model = {
          id: jobSearchModel.value.id,
          publishDate : datePublish.value
        };
        try {
          const request = await store.dispatch('organization_jobSearch_publish', model);
          if (request.status === 200) {
            jobSearchModel.value = await store.dispatch('organization_jobSearch_id', {id: route.params.id});
              toggleModalPublish()
              store.dispatch('notifications_create', { text: 'publicado' });
              forceBackRoute();
          }
        } catch (e) {
          store.dispatch('notifications_create', { text: t(`notifications.generic`) });
        }
      }

      async function jobSearchUnpublish () {
        const model = {
          id: jobSearchModel.value.id
        }
        try {
          const request = await store.dispatch('organization_jobSearch_unpublish', model);
          
          if (request.status === 200) {
              jobSearchModel.value = await store.dispatch('organization_jobSearch_id', {id: route.params.id});
              toggleModalUnpublishConfirm();
              router.push('/job-searches/actives');
          }
        } catch (e) {
          store.dispatch('notifications_create', { text: t(`notifications.generic`) });
        }

      }

      async function jobSearchClose () {
        const model = {
          id: jobSearchModel.value.id
        }
        try {
          const request = await store.dispatch('organization_jobSearch_close', model);
          if (request.status === 200) {
            jobSearchModel.value = await store.dispatch('organization_jobSearch_id', {id: route.params.id});
              toggleModalCloseConfirm()
              store.dispatch('notifications_create', { text: "Busqueda cerrada" });
              forceBackRoute();
          }
        } catch (e) {
          store.dispatch('notifications_create', { text: t(`notifications.generic`) });
        }

      }
//Navigation
      function forceBackRoute () {
        if (window.history.length > 2) {
          if(!editMode)
          {
            router.push('/job-searches/actives')
          }
          else
          {
            router.go(-1)
          }
        } else {
          router.push('/')
        }
      }

      function backRoute () {
        if(JSON.stringify(defaultModel.value) != JSON.stringify(jobSearchModel.value))
        {
          activeModalBackConfirm.value = true
        }
        else
        {
          forceBackRoute()
        }
      }

      function assertMaxChars (compareProperty, compareLimit) {
        if (jobSearchModel.value[compareProperty].length >= compareLimit) {
            jobSearchModel.value[compareProperty] = jobSearchModel.value[compareProperty].substring(0,compareLimit);
        }
      }
    
//Return
    return {
      minEndDate,
      descriptionCharLimit,
      assertMaxChars,
      statesOptions,
      citiesOptions,
      jobSearchModel,
      buttonOptions,
      backRoute,
      creationOptionsPage,
      employmentBenefitsOptions,
      editMode,
      saveJobSearch,
      jobSearchPublish,
      jobSearchUnpublish,
      jobSearchClose,
      v$,
      submitted,
      activeModalPublish,
      toggleModalPublish,
      activeModalUnpublishConfirm,
      toggleModalUnpublishConfirm,
      activeModalCloseConfirm,
      toggleModalCloseConfirm,
      forceBackRoute,
      activeModalBackConfirm,
      toggleModalBackConfirm,
      onChangeCountry,
      saveAndPublishJobSearch,
      onChangeState,
      validateTitleInCloneMode,
      loadedData,
      deleteJobSearch,
      benefitCharLimit,
      modalCantDelete,
      modalConfirmDelete,
      saving,
      titleCharLimit,
      jobAddressCharLimit
    }
  },

// Validations
  validations () {
    return {
      jobSearchModel : {
        endDate: { required },
        title: { 
          required, 
          containsCopia: helpers.withMessage('El título no puede contener la palabra "Copia"', this.validateTitleInCloneMode)
        },
        description: { required },
        jobAddress: { required },
        otherBenefitOffers: { },
        applyLimit: { required, minValueValue: minValue(1) },
        jobPositionId: { required },
        positionOther: {requiredIfRef: requiredIf(this.jobSearchModel.jobPositionId == 0)},
        employmentContractTypeId: { required },
        jobScheduleId: { required },
        workPlace: { required },
        countryId: { required },
        stateId: { required },
        cityId: { required },
        optionalSkills: { required, minLengthValue: minLength(1) },
        benefitOffers: { },
        salaryAround: {  },
        exclusiveSkills: { 
          preferredPosition: { required },
          successProfiles: { required },
          prioritizedProfiles: { required }
        }
      }
    }
  }
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
@import '~vue-skeletor/dist/vue-skeletor.css';

.container {
  padding: 0 $margin-base;

  @media (min-width: $break-sm) {
    padding: 0;
  }

  @media (min-width: $break-md) {
    padding: 0 $margin-base;
  }

  @media (min-width: $break-lg) {
    padding: 0;
  }
}

h3 {
  display: flex;
  align-items: center;

  a {
    display: inline-block;

    .back {
      font-weight: bold;
      color: $blue;
      font-size: 32px;
      padding: $margin-milli;
    }
  }
}

.button {
  // background-color: $blue;

  &-tomato {
    background-color: $red;
  }
}

.new-search {
  padding: 32px 16px;

  @media (min-width: $break-md) {
    padding: $margin-mega;
  }

  &__alert {
    // border: 1px solid tomato;
    border-radius: 8px;
    padding-left: $margin-kilo;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $margin-milli;
    text-align: left;
    color: tomato!important;

    i {
      position: relative!important;
      color: tomato!important;
      top: auto!important;
      right: auto!important;
      font-weight: normal!important;
    }
  }

  .margin-row {
    margin: $margin-base 0;

    @media (min-width: $break-md) {
      margin: $margin-kilo 0;
    }
  }

  p {
    text-shadow: none;
    font-family: $font-opensans;
    color: $gray;
    font-weight: 400;
    @include font-settings(base, base);
    margin-bottom: $margin-base;
  }

  .button--red {
    margin-right: 0;
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    gap: 24px;

    @media (min-width: $break-md) {
      flex-direction: row;
      justify-content: space-between;

      &.single-button {
        justify-content: center;
      }
    }

    &__group {
      display: flex;
      gap: $margin-kilo;
      border-radius: 8px;
      padding: 16px;
      //background: $lightestofgrays;
      flex-direction: column;

      @media (min-width: $break-sm) {
        flex-direction: row;
      }

      a, button {
        margin: 0;
        padding: 8px 32px;
      }

      &--red {
        background: $lightgreen;
      }

      &--black {
        background: $violet;
      }

      &__green {
        background: $darker;
        border: 3px solid $darker;
      }
    }

    &--fixed {
      @media (min-width: $break-md) {
        position: fixed;
        background: white;
        bottom: 0;
        left: 92px;
        right: 0px;
        padding: 0 32px;
        box-shadow: 4px 0px 4px rgba(0, 0, 0, .25);
        z-index: 10;
      }
    }
  }

  &__table {
    display: grid;
    grid-template-columns: 1fr;
    gap: $margin-kilo;
    margin: $margin-kilo 0;
    text-align: left;
    position: relative;

    // .two-columns {
      // grid-column-start: 1;
      // grid-column-end: 1;

      // @media (min-width: $break-md) {
      //   grid-column-start: 1;
      //   grid-column-end: 3;
      // }
    // }

    &__card {
      padding: $margin-milli;
      border: 1px solid #E0E0E0;
      border-radius: $margin-small;
      display: grid;
      grid-template-columns: 1fr;
      gap: $margin-kilo;

      &--outline {
        border: none;
      }

      @media (min-width: $break-md) {
        grid-template-columns: repeat(3, 1fr);
        gap: $margin-mega;
        padding: $margin-mega;
      }

      h3 {
        color: $blue;
        font-family: $font-palanquin;
        font-size: 21px;
        line-height: 29px;
        grid-column-start: 1;
        grid-column-end: 1;
        padding: 0;

        @media (min-width: $break-md) {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }
    }

    label {
      font-family: $font-opensans;
      color: $litedarkgray;
      padding-bottom: $margin-milli;
      @include font-settings(base, base);
      display: block;
    }
    
    h4 {
      color: $blue;
      font-family: $font-palanquin;
      font-size: 21px;
      line-height: 29px;
      margin: 0;
      padding: 0;
    }

    .checkbox {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: $margin-mega;
      font-family: $font-opensans;
      color: $gray;
      // margin-bottom: $margin-kilo;
      @include font-settings(milli, xmega);
      width: 100%;
      gap: $margin-milli;

      input {
        width: 16px;
        height: 16px;
        margin: 0;
      }

      label {
        padding-bottom: 0 !important;
        font-size: 14px !important;
      }
    }

    input {
      background: none;
      text-align: left;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 2px solid $gray;
      height: 40pxa;
      font-family: $font-opensans;
      color: $gray;
      // margin-bottom: $margin-kilo;
      @include font-settings(milli, xmega);
      width: 100%;

      &::placeholder {
        color: $graybg;
      }
    }

    textarea {
      background: none;
      text-align: left;
      border: 2px solid $gray;
      height: 40px;
      padding: $margin-milli;
      font-family: $font-opensans;
      color: $gray;
      @include font-settings(milli, base);
      width: 100%;

      &::placeholder {
        color: $graybg;
      }
    }

    .new-search__textArea-description {
      @media (min-width: $break-md) {
        grid-column-start: 1;
        grid-column-end: 4;
      }
      textarea {
        height: 200px;
        @media (min-width: $break-md) {
          height: 150px;
        }
      }
    }

    select {
      background: none;
      text-align: left;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 2px solid $gray;
      height: 40px;
      font-family: $font-opensans;
      color: $gray;
      @include font-settings(milli, xmega);
      width: 100%;

      option {
        color: $litedarkgray;
      }

      &::placeholder {
        color: $graybg;
      }
    }
  }
  .multiselect{
    border: none;
  }
  .exclusive-skill{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 16px;
    &__title{
      font-weight: 500;
    }
    &__options{
      display: flex;
      flex-flow: row nowrap;
    }
    &__radio-container{
      width: 50%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      input,label{
        margin: 0;
        padding: 0;
      }
      input{
        width: fit-content;
      }
    }
  }
}

.button-select {
  position: relative;

  &__options {
    right: 0px;
    bottom: 60px;
    position: absolute;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    background: white;
    color: black;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .25);
    border-radius: 8px;
    overflow: hidden;

    @media (min-width: $break-md) {
      min-width: 300px;
    }

    &.block {
      display: flex;
    }

    button {
      padding: 16px 32px!important;
      font-size: 16px;
      width: 100%;
      text-align: start;
      font-family: $font-opensans;
      &:hover {
        background: $lightestofgrays;
      }
    }
  }
  &__button {
    display: flex;
    gap: 8px;
    padding-right: 28px!important;
  }
}

.input_error {
  display:none;
  }
.has-error {
  label,
  input,
  textarea,
  select,
  span,
  h4 {
    color: tomato !important; 
    border-color: tomato !important;
  }
  .input_error {
    display: block;
    color: tomato;
    font-size: 12px;
    margin-top: -20px;
  }
  .input-message {
    display: flex;
    margin-top: 8px;
    gap: 4px;
  }
}

.textarea-charcount {
    display: block;
    text-align: right;
}

.skeletor{
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
}
.modal-cantDelete,.modal-confirmDelete{
  padding-bottom: 16px;
  p{
    padding-top: 16px;
    font-size: 18px;
    font-weight: 600;
  }
  .button{
    margin: 0;
    background: $blue;
  }
  .buttons{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    .cancel{
      background: #fff;
      color: $gray;
      border: 1px solid $gray;
    }
  }
}
</style>


