import { createRouter, createWebHistory } from 'vue-router';
import persistentData from '@/plugins/persistentData';
import JobSearchesNew from '@/views/JobSearches/New.vue';
import JobSearchesActives from '@/views/JobSearches/Actives.vue';
import JobSearchesApplies from '@/views/JobSearches/Applies.vue';
import JobSearchesApplyDetail from '@/views/JobSearches/ApplyDetail.vue';
import Login from '@/views/Login/Main.vue';
import RecoveryPassword from '@/views/Login/RecoveryPassword.vue';

import OrganizationSelection from '@/views/Login/OrganizationSelection.vue';
import Profile from '@/views/Profile/Main.vue';
import Platform from '@/views/Platform/Main.vue';
import Users from '@/views/Users/Main.vue';
import UserRegistration from '@/views/Users/UserRegistration.vue';

const guard = (to, from, next) => {
  if (persistentData.get('token') !== null) {
    next()
  } else {
    next('/login')
  }
};

const routes = [
  { path: '/', redirect: '/job-searches/actives', beforeEnter: guard },
  { path: '/login', component: Login, name: 'LOGIN'},
  { path: '/login/recoveryPassword', component: RecoveryPassword, name: 'RECOVERY_PASSWORD'},
  { path: '/organization-selection', component: OrganizationSelection, name: 'ORGANIZATION_SELECTION'},
  { path: '/users', component: Users, name: 'USERS'},
  { path: '/register/:inviteCode', component: UserRegistration, name: 'USER_REGISTRATION'},
  { path: '/profile', component: Profile, name: 'PROFILE' },
  { path: '/job-searches/new', component: JobSearchesNew, name: 'JOB_SEARCHES_NEW' },
  { path: '/job-searches/edit/:id', component: JobSearchesNew, name: 'JOB_SEARCHES_EDIT' },
  { path: '/job-searches/clone/:id', component: JobSearchesNew, name: 'JOB_SEARCHES_CLONE' },
  { path: '/job-searches/actives', component: JobSearchesActives, name: 'JOB_SEARCHES_ACTIVES', beforeEnter: guard },
  { path: '/job-searches/:id/applies', component: JobSearchesApplies, name: 'JOB_SEARCH_APPLIES' },
  { path: '/job-searches/:searchId/applies/:applyId', component: JobSearchesApplyDetail, name: 'JOB_SEARCH_APPLY_DETAIL' },
  { path: '/job-searches/history', component: JobSearchesActives, name: 'JOB_SEARCHES_HISTORY' },
  { path: '/platform', component: Platform, name: 'PLATFORM' },
  { path: '/:catchAll(.*)', redirect: '/' }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior () { window.scrollTo(0, 0) }
});


export default router;