<template>
  <div>
    <div class="container__table">
      <h3>Perfil Empresa</h3>
      <a @click="inviteUser()" class="button-float"><i class="material-icons">person_add</i></a>
        <nav class="nav-toggleMode">
          <div :class="{'nav-toggleMode--active': !companyInfoView}" @click="companyInfoView=false">
            <i class="material-icons">people_alt</i>
            <span>Gestión de usuarios</span> 
          </div>
          <div :class="{'nav-toggleMode--active': companyInfoView}" @click="companyInfoView=true">
            <i class="material-icons">maps_home_work</i>
            <span>Datos de la empresa</span>
          </div>
        </nav>
      <div class="new-search">
        <div class="table-container" v-if="!companyInfoView">
          <table>
            <thead>
              <tr>
                <th>{{ $t("users.firstname") }}</th>
                <th>{{ $t("users.lastname") }}</th>
                <th>{{ $t("users.email") }}</th>
                <th>{{ $t("users.status") }}</th>
                <th>{{ $t("users.actions") }}</th>
              </tr>
            </thead>
            <tbody v-if="loadedData">
              <tr v-for="usr in users" :key="usr.id">
                <td>{{ usr.firstName }}</td>
                <td>{{ usr.lastName }}</td>
                <td>{{ usr.email }}</td>
                <td>{{ $t(`users.statusCode.${usr.status}`) }}</td>
                <td class="table-container__actions">
                  <!-- <a v-if="usr.type == 1" @click="editUser(usr.objectId)"><i class="material-icons">edit</i></a> -->
                  <a v-if="usr.type == 'User' && user.id != usr.objectId" @click="openModalConfirmUserDelete(usr.objectId)"><i class="material-icons">delete</i></a>

                  <a v-if="usr.type == 'Invitation'" @click="openModalConfirmResendInvite(usr.objectId)"><i class="material-icons">forward_to_inbox</i></a>
                  <a v-if="usr.type == 'Invitation'" @click="openModalConfirmInviteDelete(usr.objectId)"><i class="material-icons">delete</i></a>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr v-for="index in 3" :key="index">
                  <td><Skeletor width="100%" height="24"/></td>
                  <td><Skeletor width="100%" height="24"/></td>
                  <td><Skeletor width="100%" height="24"/></td>
                  <td><Skeletor width="100%" height="24"/></td>
                  <td><Skeletor width="100%" height="24"/></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="company-info__container">
          <div class="company-info__row1">
            <div class="company-info__row1__edit">
              <button v-if="!infoEdit" class="company-info__row1__edit--btn-edit"  @click="infoEdit=true;">
                <i class="material-icons" >edit</i>
                <span>Editar información</span>
              </button>
              <button v-else class="company-info__row1__edit--btn-save"  @click="updateCompanyInfo();">
                <i class="material-icons">done</i>
                <span >Guardar cambios</span>
              </button>
            </div>
            <div class="company-info__row1__data">

              <div class="company-info__row1__data__col1">
                <div v-if="companyInfo.image == '' || companyInfo.image == null" class="no-logo">
                  <i class="material-icons">photo_camera</i>
                </div>
                <div v-else class="organization-logo">
                  <div class="organization-logo__img" :style="`background-image: url(${getImage(companyInfo.image, companyInfo.isPictureFromCDN)})`"></div>
                  <input type="file" @change="uploadNewLogo($event)" :class="{'input-file--visible':infoEdit}">
                </div>
              </div>

              <div class="company-info__row1__data__col2">
                <div :class="{'has-error': v$.companyInfo.name.$invalid && submitted}">
                  <label>Nombre</label>
                  <input type="text" v-model="companyInfo.name" :disabled="!infoEdit">
                </div>
                <div :class="{'has-error': v$.companyInfo.tributaryCode.$invalid && submitted}">
                  <label>{{ $t("organization.tributaryCode") }}</label>
                  <input type="text" v-model="companyInfo.tributaryCode" :disabled="!infoEdit">
                </div>
                <div class="company-info__row1__data__col2__check">
                  <input type="checkbox" v-model="companyInfo.franchise" :disabled="!infoEdit">
                  <label>Franquicia</label>
                </div>
              </div>
              <div class="company-info__row1__data__col3">
                <div :class="{'has-error': v$.companyInfo.businessName.$invalid && submitted}">
                  <label>Razón social</label>
                  <input type="text" v-model="companyInfo.businessName" :disabled="!infoEdit">
                </div>
                <div>
                  <label>Sitio web</label>
                  <input type="text" v-model="companyInfo.link" :disabled="!infoEdit">
                </div>              
              </div>
            </div>
          </div>
          <div class="company-info__row2">
            <h3>Ubicación</h3>
            <div class="company-info__row2__inputs">
              <div>
                <label>{{ $t("jobsearch.create.input_country") }}</label>
                <select v-model="companyInfo.countryId" @change="onChangeCountry" :disabled="!infoEdit">
                  <option v-for="country in locations.countries" :key="country.id" :value="country.id">{{ country.name }}</option>
                </select>
              </div>
              <div>
                <label>{{ $t("jobsearch.create.input_state") }}</label>
                <select v-model="companyInfo.stateId" :disabled="!infoEdit">
                  <option v-for="state in statesOptions" :key="state.id" :value="state.id">{{ state.name }}</option>
                </select>
              </div>
              <div :class="{'has-error': v$.companyInfo.address.$invalid && submitted}">
                <label>Dirección</label>
                <input type="text" v-model="companyInfo.address" :disabled="!infoEdit">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalSendInvite v-if="activeModalSendInvite" @close="closeModalInvite" @sended="reloadData"/>
    <ModalEdit v-if="activeModalEdit" @close="closeModalEdit"/>
    <ModalConfirmDelete v-if="activeModalConfirmDelete" :userId="userSelectedId" @close="closeModalConfirmDelete" @confirm="reloadData"/>
    <ModalConfirmResendInvite v-if="activeModalConfirmResendInvite" :inviteId="invitationSelectedId" @close="closeModalConfirmResendInvite" @confirm="reloadData"/>
    <ModalConfirmDeleteInvite v-if="activeModalConfirmInviteDelete" :inviteId="invitationSelectedId" @close="closeModalConfirmInviteDelete" @confirm="reloadData"/>

  </div>
</template> 
<script>
import { useStore } from 'vuex';
import { computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import storage from '@/plugins/azure-storage';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ModalSendInvite from '@/components/Users/ModalSendInvite';
import ModalEdit from '@/components/Users/ModalEdit';
import ModalConfirmDelete from '@/components/Users/ModalConfirmDelete';
import ModalConfirmResendInvite from '@/components/Users/ModalConfirmResendInvite';
import ModalConfirmDeleteInvite from '@/components/Users/ModalConfirmDeleteInvite';

import { Skeletor } from 'vue-skeletor';

export default {
  components: {
    ModalSendInvite,
    ModalEdit,
    ModalConfirmDelete,
    ModalConfirmResendInvite,
    ModalConfirmDeleteInvite,
    Skeletor
  },
  setup() {
    const store = useStore();
    const users = ref([]);
    //const router = useRouter()
    const activeModalSendInvite = ref(false);
    const activeModalEdit = ref(false);
    const activeModalConfirmDelete = ref(false);
    const activeModalConfirmInviteDelete = ref(false);
    const activeModalConfirmResendInvite = ref(false);
    const companyInfo = ref();
    const companyInfoView = ref(false);
    const locations = ref();
    const statesOptions = ref();
    const infoEdit = ref(false);
    const user = computed(() => store.getters.manageUser_user);
    const { t } = useI18n();
    const loadedData = ref(false)
    const v$ = useVuelidate();
    const submitted = ref(false);

    onMounted(async () => {
      let getLocations = await store.dispatch('organization_locations');
      locations.value = getLocations;
      await loadData()
      onChangeCountry()
    });

    async function loadData(){
      loadedData.value = false
      try {
        let usersData =  await store.dispatch('users_search', { filter: '', pageNumber: 0, rowsPerPage:300 });
        users.value = usersData;
        let companyData = await store.dispatch('organization_organization');
        companyInfo.value = companyData;
        loadedData.value = true;
      } catch (e) {
        store.dispatch('notifications_create', { text: t(`notifications.generic`) });
      }
    }

    function onChangeCountry() {
      if (companyInfo.value.countryId) {        
        statesOptions.value = locations.value.states.filter(state => state.countryId == companyInfo.value.countryId);
      } else {
        statesOptions.value = [];
      }
    }
    
    function getImage(path, isFromCDN) {
      if (isFromCDN) {
        return path ? `${process.env.VUE_APP_CDN_URL_USER}${path}` : null;
      } else {
        return path ? `${process.env.VUE_APP_FORGE_ORGANIZATION_MEDIA_URL}${path}` : null;
      }
    }

    async function updateCompanyInfo(){
      submitted.value = true;
      try {
        if (!v$.value.companyInfo.$invalid) {
          await store.dispatch('organization_updateOrganization',companyInfo.value);
          infoEdit.value = false;
          submitted.value = false;
        } else {
          store.dispatch('notifications_create', { text: t(`notifications.jobSearch.formInvalid`) });
        }
      } catch (error) {
        if(error == 'INVALID_ORGANIZATION_URL'){
          store.dispatch('notifications_create', {text: 'No es una URL válida'});
        }
      }
    }

    async function uploadNewLogo(e){
      let imagePath = await storage.updateFile(e.target.files[0]);
      await store.dispatch('organization_updateLogo',{image:imagePath})
      await store.dispatch('manageUser_changeLogo',{organizationId:companyInfo.value.id, img:imagePath})
      await store.dispatch('organization_setIsCDNPicture');
      companyInfo.value.image = imagePath;
      companyInfo.value.isPictureFromCDN = true;
      store.dispatch('notifications_create', { text: t(`notifications.updateLogo`) });
    }

    function closeModalInvite() { activeModalSendInvite.value = false }
    function inviteUser () { activeModalSendInvite.value = true }

    function closeModalEdit() { activeModalEdit.value = false }
    function editUser (userId) {
      activeModalEdit.value = true
      console.log(userId)
    }

    const userSelectedId = ref(0)
    function closeModalConfirmDelete() { activeModalConfirmDelete.value = false }
    async function openModalConfirmUserDelete (userId) { 
      userSelectedId.value = userId
      activeModalConfirmDelete.value = true
    }

    const invitationSelectedId = ref(0)
    function closeModalConfirmInviteDelete() { activeModalConfirmInviteDelete.value = false }
    function openModalConfirmInviteDelete(invitationId) { 
      invitationSelectedId.value = invitationId
      activeModalConfirmInviteDelete.value = !activeModalConfirmInviteDelete.value 
    }
    async function reloadData () { await loadData() }

    
    function closeModalConfirmResendInvite() { activeModalConfirmResendInvite.value = false }
    function openModalConfirmResendInvite(invitationId) { 
      invitationSelectedId.value = invitationId
      activeModalConfirmResendInvite.value = !activeModalConfirmResendInvite.value 
    }
    
    return {
      loadedData,
      user,
      users,
      activeModalSendInvite,
      closeModalInvite,
      activeModalEdit,
      closeModalEdit,
      companyInfo,
      companyInfoView,
      locations,
      onChangeCountry,
      statesOptions,
      infoEdit,
      updateCompanyInfo,
      uploadNewLogo,
      getImage,
      v$,
      submitted,
      inviteUser,
      editUser,

      userSelectedId,
      activeModalConfirmDelete,
      closeModalConfirmDelete,
      openModalConfirmUserDelete,

      invitationSelectedId,
      activeModalConfirmResendInvite,
      closeModalConfirmResendInvite,
      openModalConfirmResendInvite,

      activeModalConfirmInviteDelete,
      closeModalConfirmInviteDelete,
      openModalConfirmInviteDelete,

      reloadData
    }
  },
  validations () {
    return {
      companyInfo: {
        name: { required },
        tributaryCode: { required },
        businessName: { required },
        address : { required }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~vue-skeletor/dist/vue-skeletor.css';
.container {
  padding: 0 $margin-base;

  @media (min-width: $break-sm) {
    padding: 0;
  }

  @media (min-width: $break-md) {
    padding: 0 $margin-base;
  }

  @media (min-width: $break-lg) {
    padding: 0;
  }
}
@mixin button-edit{
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 10px;
  cursor: pointer;
  i{
    color: #fff;
    font-size: 20px;
  }
  span{
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }
}
.nav-toggleMode{
  margin-top: 30px;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  div{
    height: 65px;
    width: 221px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 5px;
    background-color: #E0E0E0;
    color: $blue;
    border-radius: 12px 12px 0 0;
    cursor: pointer;
    span{
      color: $blue;
      font-size: 16px;
      font-weight: 700;
      line-height: 23px;
      letter-spacing: 0em;
    }
  }
  &--active{
    background-color: #fff!important;
  }
}
.has-error {
  label,
  input,
  textarea,
  select,
  span,
  h4 {
    color: tomato !important;
    border-color: tomato !important;
  }
  .input_error {
    display: block;
    color: tomato;
    font-size: 12px;
    margin-top: -20px;
  }
  .input-message {
    display: flex;
    margin-top: 8px;
    gap: 4px;
  }
}
.new-search {
  margin: 0;
  margin-bottom: 20px;
  padding: 20px;
  width: fit-content;
  max-width: 100%;
  text-align: left;
  border-radius: 0 12px 12px 12px;
  box-shadow: 0px 4px 4px -2px rgb(0 0 0 / 25%);
  table {
    min-width: 830px;
  }

  tr {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr)) 90px;
    gap: $margin-milli;

    td {
      word-break: break-all;
    }
  }
  .company-info{
    &__container{
      display: inline-flex;
      flex-direction: column;
      gap: 18px;
      border: 1px solid #E0E0E0;
      border-radius: 10px;
    }
    &__row1{
      padding: 25px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      &__edit{
        display: flex;
        justify-content: flex-end;
        button{
          @include button-edit;
        }
        &--btn-edit{
          background-color: $blue;
        }
        &--btn-save{
          background-color: tomato;
        }
      }
      &__data{
        display: inline-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: wrap;
        gap: 40px;
        
        &__col1{
          display: flex;
          align-items: center;
          .no-logo{
            width: 150px;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #C4C4C4;
            border-radius: 100%;
            i{
              color: #F2F2F2;
              font-size: 40px;
            }
          }
          .organization-logo{
            position: relative;
            &__img {
              width: 150px;
              height: 150px;
              background: $white;
              border-radius: 50%;
              border: 1px solid $mediumgray;
              flex-shrink: 0;
              background-size: cover;
              background-position: center center;
            }
            input[type="file"] {
              visibility: hidden;
              width: 60px;
              height: 60px;
              background-color: $blue;
              border-radius: 50%;
              border: 3px solid #fff;
              flex-shrink: 0;
              background-size: cover;
              background-position: center center;
              cursor: pointer;
              position: absolute; 
              top: 45px;
              right: 45px;
    
              &::before {
                font-family: "Material Icons";
                content: "\e412";
                font-size: 34px;
                display: flex;
                color: #fff;
                align-items: center;
                justify-content: center;
                position: relative;
                height: 100%;
                width: 100%;
                border: none;
              }
              &::file-selector-button {
                opacity: 0;
              }
            }
            .input-file--visible{
              visibility: visible!important;
            }
          }
        }
        &__col2, &__col3{
          width: 250px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 20px;
          div{
            display: flex;
            flex-direction: column;
            gap: 2px;
            label{
              text-align: left;
              font-family: Open Sans;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              color: $mediumgray;
            }
            input[type="text"]{
              border: none;
              border-bottom: 2px solid $mediumgray;
              font-family: Palanquin Dark;
              font-weight: 500;
              font-size: 16px;
              color: $mediumgray;
            }
          }
          &__check{
            flex-direction: row!important;
            align-items: center;
          }
        }
      }
    }
    &__row2{
      padding: 25px;
      &__edit{
        display: flex;
        justify-content: flex-end;
        button{
          @include button-edit;
        }
        &--btn-edit{
          background-color: $blue;
        }
        &--btn-save{
          background-color: tomato;
        }
      }
      h3{
        padding: 0;
        text-align: left;
        font-size: 20px;
        line-height: 23px;
      }
      &__inputs{
        display: flex;
        gap: 40px;
        flex-wrap: wrap;
        div{
          width: 250px;
          display: flex;
          flex-direction: column;
          label{
            text-align: left;
            font-family: Open Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 23px;
            color: $mediumgray;
          }
          input, select{
            border: none;
            border-bottom: 2px solid $mediumgray;
            font-family: Palanquin Dark;
            font-weight: 500;
            font-size: 16px;
            color: $mediumgray;
          }
        }
      }
    }
  }
}
.table-container__actions {
  width: 100%;
}
</style>