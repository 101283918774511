<template>
 <div class="modal__overlay" @click.stop="toggleModal()">
    <div class="modal" @click.stop="''">
      <span class="material-icons" @click.stop="toggleModal()">close</span>
      <h3>Publicar</h3>
      <div class="modal__date">
        <p>Fecha de publicación</p>
        <input type="date" v-model="datePublish" :min="dateMin" placeholder="Fecha de publicación">
      </div>
      <div class="modal__buttons">
        <button class="button--gray" @click.stop="toggleModal()">cancelar</button>
        <button @click="publish">publicar</button>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  setup(_, {emit}) {
    const datePublish = ref();
    const dateMin = ref();
    const store = useStore();
    const { t } = useI18n();
    function toggleModal() {
      emit('closeModalPublish');
    }

    async function publish() {
      if(moment(datePublish.value).isSameOrAfter(moment().format("YYYY-MM-DD")))
      {
        emit('publish',datePublish)
      }
      else {
        store.dispatch('notifications_create', { text: t(`notifications.generic`) });
      }
    }

    datePublish.value = moment().format("YYYY-MM-DD")
    dateMin.value = moment().format("YYYY-MM-DD")
    return {
      datePublish,
      dateMin,
      toggleModal,
      publish
    }
  }
}
</script>
<style lang="scss" scoped>
  
  .modal__date {
    margin-bottom: $margin-mega;

    input {
      background: none;
      text-align: left;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 2px solid $litedarkgray;
      height: 32px;
      width: 100%;
      font-family: $font-opensans;
      color: $gray;
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 34px;
    }

    p {
      color: $litedarkgray;
      text-align: left;
      margin-bottom: $margin-milli;
    }
  }
</style>