import interceptor from '@/plugins/interceptor';
import moment from 'moment';
const pathController = '/JobSearch'

export default {
  state: () => ({

  }),
  mutations: {
  },
  actions: {
    async organization_creationOptions() {
      const ret = await interceptor.authenticate.get(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/jobSearchCreationOptions`);
      return ret.data;
    },
    async organization_saveJobSearch(_, model) {
      return await interceptor.authenticate.post(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}`, model);
    },
    async organization_updateJobSearch(_, model) {
      return await interceptor.authenticate.put(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}`, model);
    },
    /* async organization_jobSearch_search(_, model) {
      const ret = await interceptor.authenticate.get(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/search?pageNumber=${model.pageNumber}&rowsPerPage=${model.rowsPerPage}&orderBy=${model.orderBy}&sortBy=${model.sortBy}`);
      return ret.data;
    }, */
    async organization_jobSearch_searchFilters(_, model) {
      let url = `${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/search?pageNumber=${model.pageNumber}&rowsPerPage=${model.rowsPerPage}&orderBy=${model.orderBy}&sortBy=${model.sortBy}`;
      if (model.filter != '') url += `&filter=${model.filter}`;
      if (model.status != '') url += `&status=${model.status}`;
      if (model.publishDateFrom != '') url += `&publishDateFrom=${model.publishDateFrom}`;
      if (model.publishDateTo != '') url += `&publishDateTo=${model.publishDateTo}`;
      if (model.endDateFrom != '') url += `&endDateFrom=${model.endDateFrom}`;
      if (model.endDateTo != '') url += `&endDateTo=${model.endDateTo}`;
      const ret = await interceptor.authenticate.get(url);
      return ret.data;
    },
    async organization_jobSearchActives() {
      const ret = await interceptor.authenticate.get(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/jobSearchPublished`);
      return ret.data;
    },
    async organization_jobSearch_id(_, params) {
      const ret = await interceptor.authenticate.get(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/${params.id}`);
      if (ret.status == 200) {
        ret.data.endDate = moment(ret.data.endDate).format("YYYY-MM-DD")
      }
      return ret.data;
    },
    async organization_jobSearch_applies_id(_, params) {
      const ret = await interceptor.authenticate.get(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/${params.id}/applies`);
      return ret.data;
    },
    async organization_jobSearch_publish(_, params) {
      return await interceptor.authenticate.post(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/${params.id}/publish?date=${params.publishDate}`);
    },

    async organization_jobSearch_unpublish(_, params) {
      return await interceptor.authenticate.post(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/${params.id}/unpublish`);
    },

    async organization_jobSearch_close(_, params) {
      return await interceptor.authenticate.post(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/${params.id}/close`);
    },
    async jobSearch_apply_id(_, params) {
      const ret = await interceptor.authenticate.get(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/${params.searchId}/applies/${params.applyId}/getProfile`);
      return ret.data;
    },
    async jobSearch_apply_contactData(_, params) {
      const ret = await interceptor.authenticate.put(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/${params.searchId}/applies/${params.applyId}/getContactData`);
      return ret.data;
    },
    async jobSearch_applyHired(_, params) {
      return await interceptor.authenticate.put(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/${params.searchId}/applies/${params.applyId}/hired`);
    },
    async jobSearch_applyNoInterviewed(_, params) {
      return await interceptor.authenticate.put(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/${params.searchId}/applies/${params.applyId}/noInterviewed`);
    },
    async jobSearch_noInterviewed(_, params) {
      return await interceptor.authenticate.put(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/${params.searchId}/noInterviewed`, params.arr);
    },
    async jobSearch_delete(_, params) {
      return await interceptor.authenticate.delete(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/${params.searchId}`);
    },
    async jobSearch_getExcelReport(_,params){
      return await interceptor.authenticate.getFile(`${process.env.VUE_APP_API_ORGANIZATION_URL}${pathController}/${params.searchId}/reports`, params.fileName);
    }
  }
}