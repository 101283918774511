<template>
 <div class="modal__overlay" @click.stop="toggleModal()">
    <div class="modal" @click.stop="''">
      <span class="material-icons" @click.stop="toggleModal()">close</span>
      <h3>{{$t('users.invite.modaltitle')}}</h3>
      <div class="modal__date">
        <p>{{$t('users.invite.input_email')}}</p>
        <input type="email" autocomplete="off" v-model="email" :class="{'input-invalid': v$.email.$invalid && submitted}">
      </div>

      
      <div class="modal__buttons">
        <button class="button--gray" @click.stop="toggleModal()">{{$t('users.invite.modalaction-cancel')}}</button>
        <button @click="sendInvite">{{$t('users.invite.modalaction-send')}}</button>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { computed } from '@vue/runtime-core';
import { required, email } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';

export default {
  setup(_, {emit}) {
    const store = useStore();
    const email = ref("");
    const { t }= useI18n()
    const submitted = ref(false);
    const organizationIdStored = computed(() => store.getters.manageUser_organizationId);
    function toggleModal() {
      emit('close');
    }

    async function sendInvite() {
      submitted.value = true;
      try {
        const request = await store.dispatch('users_invite', [{email : email.value, organizationId: organizationIdStored.value, roleId : 1}]);
        if (request.status === 200) {
          toggleModal()
          store.dispatch('notifications_create', { text: t(`notifications.invite.sended`) });   
          emit('sended')
        }
      } catch (e) {
        store.dispatch('notifications_create', { text: t(`notifications.invite.generic-error`) });
      }
    }

    return {
      email,
      submitted,
      sendInvite,
      toggleModal,
      v$: useVuelidate()
    }
  },
  validations () {
    return {
      email: { required, email }
    }
  }
}
</script>
<style lang="scss" scoped>
  
  .modal__date {
    margin-bottom: $margin-mega;

    input {
      background: none;
      text-align: left;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 2px solid $litedarkgray;
      height: 32px;
      width: 100%;
      font-family: $font-opensans;
      color: $gray;
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 34px;
    }

    p {
      color: $litedarkgray;
      text-align: left;
      margin-bottom: $margin-milli;
    }
  }
</style>