<template>
   <div class="container main-container">
    <h1>Perfil</h1>
  </div>
</template> 
<script>

export default {
  setup() {
    

    return {
    }
  }
}
</script>