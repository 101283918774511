<template>
  <div class="notification">
    <div class="notification__text">
      <span v-html="notification.text"></span>
    </div>
    <a class="notification__close" @click="removeNotification">
      <i class="material-icons">icon_close</i>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    notification: Object
  },
  methods: {
    removeNotification() {
      this.$store.dispatch('notifications_delete', this.notification.guid);
    }
  },
  created() {
    if (!this.notification.withAction) {
      setTimeout(() => {
        this.removeNotification();
      }, 6000);
    }
  }
};
</script>

<style lang="scss">
.notification {
  display: flex;
  align-items: center;
  width: fit-content;
  max-width: 500px;
  background: $mediumgray;
  border-radius: 4px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  padding: $margin-base;
  padding-right: $margin-tera;
  margin: $margin-milli 0;
  position: relative;
  color: $darkgray;

  &__text {
    @include font-settings(milli, base);
    color: $white;
    font-weight: 300;

    span {
      margin-right: $margin-milli;
      color: $white;
      font-size: 18px;
    }
  }

  &__close {
    position: absolute;
    right: $margin-base;
    color: $white;
    i {
      line-height: 50px;
    }
  }
}
</style>
