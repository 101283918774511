import AzureStorage from "@/assets/libs/azure-storage.blob";

export default {
  service: null,
  serviceGet() {
    if(this.service == null) {
      this.service = AzureStorage.createBlobServiceWithSas(process.env.VUE_APP_CDN_BLOBURI, process.env.VUE_APP_CDN_SASTOKEN);
    }
    return this.service;
  },
  updateFile(file) {
    const blobService = this.serviceGet();
    const nameForBlob = this.generateUuid() //+ '.' +  file.name.split('.').pop();
    
    // const customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
    // blobService.singleBlobPutThresholdInBytes = customBlockSize;

    return new Promise((resolve, reject) => {
      // blobService.createBlockBlobFromBrowserFile(process.env.VUE_APP_CDN_CONTAINERNAME, nameForBlob, file, {blockSize : customBlockSize}, 
      blobService.createBlockBlobFromBrowserFile(process.env.VUE_APP_CDN_CONTAINERNAME, nameForBlob, file, 
        (error, result) => {
          if (error) {
            reject(error.message)
          } else {
            resolve(result.name)
          }
      });
    })
  },
  generateUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}