<template>
  <div class="grid">
    <Menu v-if="logged && organizationId != null && organizationId != 0" />
    <RouterView />
  </div>
  <Notifications />
</template>

<script>
import { computed, onMounted, watch } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import Menu from '@/components/Common/Menu.vue';
import Notifications from '@/components/Common/Notifications/Main.vue';
import persistentData from "@/plugins/persistentData";

export default {
  components: {
    Menu,
    Notifications
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const logged = computed(() => store.getters.manageUser_logged);
    const organizationId = computed(() => store.getters.manageUser_organizationId);
    
    
    const routesRegisterLogin = ['HOME', 'LOGIN', 'PROFILE', 'JOB_SEARCHES', 'PLATFORM'];

    const routeActive = ref('');
    
    

    onMounted(() => {

      const organizationId = persistentData.get('organizationId')
      routeActive.value = route.name;
      
      if(organizationId == null && routeActive.value != "USER_REGISTRATION" && routeActive.value != "LOGIN" && routeActive.value != "RECOVERY_PASSWORD")
      {
        store.dispatch('manageUser_disabled');
      }

      if(routeActive.value != "USER_REGISTRATION")
      {
        store.dispatch('manageUser_active');
      }
    });

    watch(() => route.name, (val) => { routeActive.value = val });



    return {
      logged,
      organizationId,
      routeActive,
      routesRegisterLogin
    }
  }
}
</script>

<style lang="scss">
  @import "@/styles/main.scss";

  .grid {
    display: grid;
    min-height: 100vh;
    width: 100%;
    background: $lightergray;
    grid-template-columns: auto 1fr;
    grid-template-areas: "login login"
    "menu principal";
  }
</style>