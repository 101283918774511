<template>
 <div class="modal__overlay" @click.stop="toggleModal()">
    <div class="modal" @click.stop="''">
      <span class="material-icons" @click.stop="toggleModal()">close</span>
      <h3 v-if="multiple">{{$t('apply.noInterviewed.modaltitleMultiple')}}</h3>
      <h3 v-else>{{$t('apply.noInterviewed.modaltitle')}}</h3>      
      <p v-if="multiple">
        {{$t('apply.noInterviewed.modaldescriptionMultiple')}}
      </p>
      <p v-else>
        {{$t('apply.noInterviewed.modaldescription')}}
      </p>             
      <div class="modal__buttons">
        <button class="button--gray" @click.stop="toggleModal()">{{$t('apply.noInterviewed.modalaction-cancel')}}</button>
        <button v-if="multiple" @click="confirmAll()">{{$t('apply.noInterviewed.modalaction-setNoInterviewed')}}</button>
        <button v-else @click="confirm">{{$t('apply.noInterviewed.modalaction-setNoInterviewed')}}</button>
      </div>
    </div>
  </div>
</template>
<script>
//import { inject } from 'vue'
import { ref } from '@vue/reactivity';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default {
  props: {
    searchId: String,
    applyId: Number,
    applies: Array,
    multipleRemove: Boolean,
  },
  setup(props, {emit}) {
    const { t }= useI18n()
    const store = useStore()
    const submitted = ref(false);
    const multiple = ref(props.multipleRemove);
    //const appliesToRemove = inject('appliesToRemove');
    
    function toggleModal() {
      emit('close');
    }

    async function confirm() {
      submitted.value = true;
      //todo try send invite
      try {
          const request = await store.dispatch('jobSearch_applyNoInterviewed' , {searchId : props.searchId, applyId : props.applyId});
          if (request.status === 200) {
            toggleModal()
            store.dispatch('notifications_create', { text: t(`notifications.apply-noInterviewed.noInterviewed`) });   
            emit('confirm')
          }
        } catch (e) {
         store.dispatch('notifications_create', { text: t(`notifications.apply-noInterviewed.generic-error`) });
        }
    }

    async function confirmAll() {
      submitted.value = true;
      try {
          const request = await store.dispatch('jobSearch_noInterviewed', {searchId: props.searchId, arr: props.applies});
          if (request.status === 200) {
            toggleModal()
            store.dispatch('notifications_create', { text: t(`notifications.apply-noInterviewed.noInterviewed`) });   
            emit('confirmAll')
          }
        } catch (e) {
         store.dispatch('notifications_create', { text: t(`notifications.apply-noInterviewed.generic-error`) });
        }
    }

    return {
      submitted,
      confirm,
      confirmAll,
      toggleModal,
      multiple
    }
  }
}
</script>
<style lang="scss" scoped>
  
  .modal__date {
    margin-bottom: $margin-mega;

    input {
      background: none;
      text-align: left;
      border-top: none;
      border-right: none;
      border-left: none;
      border-bottom: 2px solid $litedarkgray;
      height: 32px;
      width: 100%;
      font-family: $font-opensans;
      color: $gray;
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 34px;
    }

    p {
      color: $litedarkgray;
      text-align: left;
      margin-bottom: $margin-milli;
    }
  }
</style>