import interceptor from '@/plugins/interceptor';
const pathController = '/Security'

export default {
  state: () => ({
    
  }),
  mutations: { 
  },
  actions: {
    async security_authenticate(_, model) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/organization/authenticate`, model);
    },
    async security_authenticateGoogle(_, model) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/organization/authenticateGoogle`, model);
    },
    async security_refreshToken(_, token) {
      return await interceptor.authenticate.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/organization/refreshToken`, token);
    },
    async security_forgotPassword(_, model) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/organization/forgotPassword`, model);
    },
    async security_invitedata(_, code) {
      return await interceptor.anonymous.get(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/organization/invite/${code}`);
    },
    async security_registerByInvite(_, model) {
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/organization/invite/register`, model);
    },
    async security_beginPasswordRecovery(_, email){
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/organization/beginPasswordRecovery`, {email});
    },
    async security_endPasswordRecovery(_, model){
      return await interceptor.anonymous.post(`${process.env.VUE_APP_API_SECURITY_URL}${pathController}/organization/endPasswordRecovery`, model);
    }
  }
}